import { Alert, AlertColor, Snackbar } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";

export interface SnackbarType {
    setOpen: Dispatch<SetStateAction<boolean>>;
    open: boolean;
    message: string;
    type: AlertColor;
}

const SnackbarMessage = ({ setOpen, open, message, type }: SnackbarType) => {
    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setOpen(false);
    };
    return (
        <Snackbar
            open={open}
            autoHideDuration={5000}
            onClose={handleClose}
            sx={{
                position: "relative",
                display: "flex",
                // mx: "auto",
                justifyContent: "center",
                bgcolor: "black",
            }}
        >
            <Alert
                onClose={handleClose}
                severity={type}
                // sx={{ width: "100%" }}
                variant="filled"
                sx={{
                    position: "absolute",
                    mx: "auto",
                    width: "fit-content",
                    top: { xs: "-70px" },
                }}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export default SnackbarMessage;
