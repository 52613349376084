import {
    forwardRef,
    useEffect,
    ForwardRefExoticComponent,
    RefAttributes,
} from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
    Avatar,
    Chip,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from "@mui/material";

// project import
import { activeItem } from "../../../../../store/reducers/menu";

// types
import { LinkTarget, NavItemType } from "../../../../../types/menu";
import { RootStateProps } from "../../../../../types/root";

// ==============================|| NAVIGATION - LIST ITEM ||============================== //

interface Props {
    item: NavItemType;
    level: number;
}

const NavItem = ({ item, level }: Props) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const menu = useSelector((state: RootStateProps) => state.menu);
    const { drawerOpen, openItem } = menu;

    let itemTarget: LinkTarget = "_self";
    if (item.target) {
        itemTarget = "_blank";
    }

    let listItemProps: {
        component:
            | ForwardRefExoticComponent<RefAttributes<HTMLAnchorElement>>
            | string;
        href?: string;
        target?: LinkTarget;
    } = {
        // eslint-disable-next-line react/display-name, @typescript-eslint/no-unused-vars
        component: forwardRef((props, ref) => (
            <Link {...props} to={item.url!} target={itemTarget} />
        )),
    };
    if (item?.external) {
        listItemProps = { component: "a", href: item.url, target: itemTarget };
    }

    const Icon = item.icon!;
    const itemIcon = item.icon ? (
        <Icon style={{ fontSize: drawerOpen ? "1rem" : "1.25rem" }} />
    ) : (
        false
    );

    const isSelected = openItem.findIndex((id) => id === item.id) > -1;

    const { pathname } = useLocation();

    // active menu item on page load
    useEffect(() => {
        if (pathname && pathname.includes("product-details")) {
            if (item.url && item.url.includes("product-details")) {
                dispatch(activeItem({ openItem: [item.id] }));
            }
        }

        if (pathname && pathname.includes("kanban")) {
            if (item.url && item.url.includes("kanban")) {
                dispatch(activeItem({ openItem: [item.id] }));
            }
        }

        if (pathname === item.url) {
            dispatch(activeItem({ openItem: [item.id] }));
        }
        // eslint-disable-next-line
    }, [pathname]);

    const textColor = theme.palette.mode === "dark" ? "grey.400" : "#3A3541CC";
    const iconSelectedColor =
        theme.palette.mode === "dark" && drawerOpen
            ? "text.primary"
            : "primary.main";

    return (
        <ListItemButton
            {...listItemProps}
            disabled={item.disabled}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: drawerOpen ? `${level * 28}px` : 1.5,
                py: !drawerOpen && level === 1 ? 1.25 : 1,
                ...(drawerOpen && {
                    "&:hover": {
                        bgColor:
                            theme.palette.mode === "dark"
                                ? "divider"
                                : "primary.lighter",
                    },
                    "&.Mui-selected": {
                        bgColor:
                            theme.palette.mode === "dark"
                                ? "divider"
                                : "primary.lighter",
                        borderRight: `2px solid ${theme.palette.primary.main}`,
                        color: iconSelectedColor,
                        "&:hover": {
                            color: iconSelectedColor,
                            bgColor:
                                theme.palette.mode === "dark"
                                    ? "divider"
                                    : "primary.lighter",
                        },
                    },
                }),
                ...(!drawerOpen && {
                    "&:hover": {
                        bgColor: "transparent",
                    },
                    "&.Mui-selected": {
                        "&:hover": {
                            bgColor: "transparent",
                        },
                        bgColor: "transparent",
                    },
                }),
            }}
        >
            {itemIcon && (
                <ListItemIcon
                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: "center",
                            justifyContent: "center",
                            "&:hover": {
                                bgColor:
                                    theme.palette.mode === "dark"
                                        ? "secondary.light"
                                        : "secondary.lighter",
                            },
                        }),
                        ...(!drawerOpen &&
                            isSelected && {
                                bgColor:
                                    theme.palette.mode === "dark"
                                        ? "primary.900"
                                        : "primary.lighter",
                                "&:hover": {
                                    bgColor:
                                        theme.palette.mode === "dark"
                                            ? "primary.darker"
                                            : "primary.lighter",
                                },
                            }),
                    }}
                >
                    {itemIcon}
                </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && (
                <ListItemText
                    primary={
                        <Typography
                            variant="h6"
                            sx={{
                                color: isSelected
                                    ? iconSelectedColor
                                    : textColor,
                            }}
                        >
                            {item.title}
                        </Typography>
                    }
                />
            )}
            {(drawerOpen || (!drawerOpen && level !== 1)) && item.chip && (
                <Chip
                    color={item.chip.color}
                    variant={item.chip.variant}
                    size={item.chip.size}
                    label={item.chip.label}
                    avatar={
                        item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>
                    }
                />
            )}
        </ListItemButton>
    );
};

export default NavItem;
