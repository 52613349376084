import { useFormik } from "formik";
import useMessage from "../hooks/useMessage";
import { LoadingButton } from "@mui/lab";
import {
    Autocomplete,
    Chip,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
} from "@mui/material";
import { TextField } from "@mui/material";
import useData from "../hooks/useApi";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { GrFormClose } from "react-icons/gr";
import { regExValidations } from "../data/consts";

const CreateRegistryForm = ({ update = false }: { update?: boolean }) => {
    const { postData } = useData();
    const { showError, showSuccess } = useMessage();
    const navigate = useNavigate();
    const params = useParams();

    // State from registries page link
    const [inputValue, setInputValue] = useState<string>("");
    const [chips, setChips] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [id, setId] = useState<number | null>(null);
    const [updateLock, setUpdateLock] = useState<boolean>(true);
    const [tldError, setTldError] = useState<boolean>(false);
    const [initArray, setInitArray] = useState<string[]>([]);

    const formik = useFormik({
        initialValues: {
            registry: "",
            tlds: [],
        },
        validate: (values) => {
            const errors: any = {};
            if (values.registry === "" || values.registry === undefined) {
                errors.registry = "Required Field";
            }
            return errors;
        },
        async onSubmit(values) {
            setLoading(true);
            try {
                if (update) {
                    const response: any = await postData("/registry/update", {
                        registry: values.registry,
                        tlds: values.tlds,
                        id: id,
                    });
                    if (response && response?.success) {
                        showSuccess("Registry updated successfully");
                        navigate("/admin/registries");
                    } else if (
                        response &&
                        !response.success &&
                        response.message
                    ) {
                        showError(response.message);
                    } else {
                        throw Error("failed");
                    }
                } else {
                    const response: any = await postData("/registry/create", {
                        registry: values.registry,
                        tlds: values.tlds,
                    });
                    if (response && response?.success) {
                        showSuccess("Registry created successfully");
                        navigate("/admin/registries");
                    } else {
                        if (
                            response.message &&
                            /(UQ_34dc2caa831175f4c6fd3df2a68)/.test(
                                response.message,
                            )
                        ) {
                            showError("Duplicate Registry");
                        } else if (
                            response.message &&
                            /(ON CONFLICT DO UPDATE)/.test(response.message)
                        ) {
                            showError("Duplicate TLDs");
                        }
                    }
                }
            } catch (e: any) {
                if (e.response?.status === 500) {
                    showError("Internal server error");
                } else {
                    showError(
                        `Registry ${update ? "update" : "creation"} failed`,
                    );
                }
            }
            setLoading(false);
        },
    });

    const styles = {
        formControlStyle: {
            my: 1,
            minWidth: 100,
        },
        inputGroup: {
            my: { md: 0, xs: 1 },
            ml: { md: 5, xs: 0 },
        },
    };

    function handleKeyDown(event: any) {
        if (event.key === " " && inputValue.trim() !== "") {
            const subChips = inputValue.split(" ");
            if (subChips.length == 1) {
                if (regExValidations.tld.test(inputValue.trim())) {
                    setChips((chips) => [
                        ...chips,
                        inputValue.toLowerCase().trim(),
                    ]);
                    setInputValue("");
                }
            } else {
                const newChips: string[] = [];
                subChips.forEach((value) => {
                    const tld = value.toLowerCase().trim();
                    if (tld && regExValidations.tld.test(tld)) {
                        newChips.push(tld);
                    }
                });
                setChips((chips) => [...chips, ...newChips]);
                setInputValue("");
            }
        }
    }

    const handleRegistryChange = (e: any) => {
        e.target.value = e.target.value.toLowerCase().replace(/[^\w]+/g, "");
        formik.handleChange(e);
    };

    const getData = async () => {
        setLoading(true);
        try {
            if (params.id) {
                const data: any = await postData("/registry/getRegistryById", {
                    id: +params.id,
                });
                setId(+params.id);
                if (data.success && data.data) {
                    setChips(data?.data?.tlds ? data.data.tlds : []);
                    setInitArray(data?.data?.tlds ? data.data.tlds : []);
                    formik.setFieldValue("registry", data.data.registry);
                } else {
                    navigate("/admin/registries");
                }
            }
        } catch (error: any) {
            navigate("/admin/registries");
        }
        setLoading(false);
    };

    useEffect(() => {
        if (update) {
            getData();
        }
    }, [params]);

    useEffect(() => {
        setUpdateLock(chips === initArray);
        formik.setFieldValue("tlds", chips);
    }, [chips]);

    return (
        <form
            onSubmit={formik.handleSubmit}
            style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexGrow: 1,
            }}
        >
            <Grid container spacing={2}>
                {/* Second Column */}
                <Grid item xs={12} sm={12} md={12}>
                    <Stack>
                        <InputLabel>Registry</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <TextField
                                fullWidth
                                id="registry"
                                name="registry"
                                autoComplete="registry"
                                value={formik.values.registry}
                                onChange={handleRegistryChange}
                                disabled={update}
                                sx={{
                                    "&:disabled": {
                                        color: "#3A354180 !important",
                                    },
                                }}
                                // @ts-expect-error error
                                error={
                                    formik.errors.registry &&
                                    formik.touched.registry
                                }
                                inputProps={{
                                    style: {
                                        backgroundColor: update
                                            ? "#EEEEEE"
                                            : "unset",
                                        color: update ? "#3A354180" : "unset",
                                    },
                                }}
                            />
                            {formik.errors.registry && (
                                <FormHelperText sx={{ mx: 0 }} error>
                                    {" "}
                                    {formik.touched.registry &&
                                        formik.errors.registry}{" "}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    <Stack>
                        <InputLabel>TLDs</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <Autocomplete
                                options={[]}
                                sx={{
                                    "& .MuiInputBase-formControl": {
                                        alignItems: "baseline",
                                    },
                                }}
                                clearIcon={false}
                                multiple
                                freeSolo // Allows inputting text not in the options list
                                value={chips}
                                inputValue={inputValue}
                                onInputChange={(event, newInputValue) => {
                                    setTldError(
                                        newInputValue.trim() &&
                                            !regExValidations.tld.test(
                                                newInputValue.toLowerCase(),
                                            )
                                            ? true
                                            : false,
                                    );
                                    setInputValue(
                                        newInputValue.toLowerCase().trim(),
                                    );
                                }}
                                onKeyDown={handleKeyDown}
                                renderTags={(value) =>
                                    value.map((option, index) => (
                                        <Chip
                                            sx={{ m: 0.5, bgcolor: "#D4F1F9" }}
                                            deleteIcon={<GrFormClose />}
                                            key={index}
                                            label={option}
                                            onDelete={() => {
                                                setChips((chips) =>
                                                    chips.filter(
                                                        (chip) =>
                                                            chip !== option,
                                                    ),
                                                );
                                            }}
                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        // multiline
                                        // sx={{ height: 500 }}
                                        error={tldError ? true : false}
                                        // rows={10}
                                    />
                                )}
                            />
                            {tldError && (
                                <FormHelperText sx={{ mx: 0 }} error>
                                    Invalid TLD
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12} mt={3}>
                    <Stack direction="row" justifyContent="flex-end">
                        <LoadingButton
                            size="medium"
                            variant="contained"
                            type="submit"
                            style={{
                                width: "100px",
                                height: "40px",
                            }}
                            disabled={loading || (update && updateLock)}
                        >
                            {update ? "Update" : "Submit"}
                        </LoadingButton>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    );
};

export default CreateRegistryForm;
