import { Box, Typography, Stack } from "@mui/material";
import React, { Fragment } from "react";

const MainWrapper = ({
    children,
    title,
    element,
    topElement,
}: {
    children: React.ReactNode;
    element?: React.ReactNode;
    topElement?: React.ReactNode;
    title?: string;
}) => {
    return (
        <Box
            sx={{
                padding: "24px",
                bgcolor: "white",
            }}
        >
            {topElement && <Fragment>{topElement}</Fragment>}
            <Stack
                direction="row"
                sx={{ alignItems: "center", justifyContent: "space-between" }}
            >
                {title && (
                    <Typography
                        sx={{
                            fontSize: { sm: "22px", xs: "16px" },
                            fontWeight: 600,
                            color: "#3A3541CC",
                            paddingBottom: { sm: 3, xs: 2 },
                        }}
                    >
                        {title}
                    </Typography>
                )}
                {element && <Box>{element}</Box>}
            </Stack>
            {children}
        </Box>
    );
};

export default MainWrapper;
