import React, { useState } from "react";

// material-ui
import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";

// assets
import { EditOutlined, LogoutOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

interface Props {
    handleLogout: () => void;
}

const ProfileTab = ({ handleLogout }: Props) => {
    const navigate = useNavigate();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement>,
        index: number,
    ) => {
        setSelectedIndex(index);
        navigate("/reset-password");
    };

    return (
        <List
            component="nav"
            sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 32 } }}
        >
            <ListItemButton
                selected={selectedIndex === 0}
                onClick={(event: React.MouseEvent<HTMLDivElement>) =>
                    handleListItemClick(event, 0)
                }
            >
                <ListItemIcon>
                    <EditOutlined />
                </ListItemIcon>
                <ListItemText primary="Change Password" />
            </ListItemButton>

            <ListItemButton
                selected={selectedIndex === 1}
                onClick={handleLogout}
            >
                <ListItemIcon>
                    <LogoutOutlined />
                </ListItemIcon>
                <ListItemText primary="Logout" />
            </ListItemButton>
        </List>
    );
};

export default ProfileTab;
