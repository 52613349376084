import { Checkbox, Stack, Typography } from "@mui/material";
import React from "react";

const ProfileSectionCheck = ({
    label,
    onChange,
    checked,
    disabled,
}: {
    label: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    checked: boolean;
    disabled: boolean;
}) => {
    return (
        <Stack direction="row" alignItems="center" sx={{ mt: 2, mb: 1 }}>
            <Typography
                sx={{
                    fontSize: 15,
                    color: "#3A3541CC",
                    width: "300px",
                }}
            >
                {label}
            </Typography>

            <Checkbox
                checked={checked}
                onChange={onChange}
                sx={{
                    transform: "scale(0.85)",
                }}
                disabled={disabled}
            />
        </Stack>
    );
};

export default ProfileSectionCheck;
