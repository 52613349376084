import { Alert, Snackbar } from "@mui/material";
import React, { createContext, useState } from "react";
import { messageType, MessageContextType } from "../types/contextTypes";

export const MessageContext = createContext<MessageContextType>(
    {} as MessageContextType,
);

export const MessageProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [message, setMessage] = useState<messageType>({
        message: "",
        type: "info",
    });
    const [show, setShow] = useState<boolean>(false);

    const handleClose = (
        event: React.SyntheticEvent | Event,
        reason?: string,
    ) => {
        if (reason === "clickaway") {
            return;
        }
        setShow(false);
    };

    function showInfo(message: string) {
        setMessage({ message, type: "info" });
        setShow(true);
    }

    function showError(message: string) {
        setMessage({ message, type: "error" });
        setShow(true);
    }

    function showSuccess(message: string) {
        setMessage({ message, type: "success" });
        setShow(true);
    }

    return (
        <MessageContext.Provider value={{ showInfo, showError, showSuccess }}>
            <Snackbar
                open={show}
                autoHideDuration={2000}
                onClose={handleClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert
                    onClose={handleClose}
                    severity={message.type}
                    sx={{ width: "100%" }}
                    variant="filled"
                >
                    {message.message}
                </Alert>
            </Snackbar>
            {children}
        </MessageContext.Provider>
    );
};
