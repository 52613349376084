import MainWrapper from "../../components/wrappers/MainWrapper";
import MessagesTable from "../tables/MessagesTable";

const MessagesPage = () => {
    return (
        <MainWrapper title="Messages">
            <MessagesTable />
        </MainWrapper>
    );
};

export default MessagesPage;
