import { SetStateAction, useEffect, useState } from "react";
import ProfileFormSection from "../components/profile/ProfileFormSection";
import ProfileFormSectionDomains from "../components/profile/ProfileFormSectionDomains";
import { Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import ProfileSectionCheck from "../components/profile/ProfileSectionCheck";
import {
    ErrorSwitchType,
    InputSectionType,
    NameserverInputSectionType,
    FormDataTypeSubmit,
} from "../types/profileSettingsTypes";
import {
    inputs,
    validationSchema,
    domainsValidationSchema,
    inputErrors,
} from "../data/profileData";
import { Link, useNavigate, useParams } from "react-router-dom";
import useData from "../hooks/useApi";
import useMessage from "../hooks/useMessage";
import ConfirmationBox from "../components/common/AlertBoxes/ConfirmationBox";

const ProfileForm = ({
    setUsername,
    disabled,
}: {
    setUsername: React.Dispatch<SetStateAction<string>>;
    disabled: boolean;
}) => {
    const [adminContact, setAdminContact] = useState(false);
    const [technicalContact, setTechnicalContact] = useState(false);
    const [billingContact, setBillingContact] = useState(false);
    const params = useParams();
    const navigate = useNavigate();
    const { fetchData, postData } = useData();
    const { showError, showSuccess } = useMessage();
    const [deleteAlert, setDeleteAlert] = useState<boolean>(false);
    const [updateAlert, setUpdateAlert] = useState<boolean>(false);

    const [errorSwitch, setErrorSwitch] = useState<ErrorSwitchType>({
        ownerInfo: false,
        adminInfo: false,
        technicalInfo: false,
        billingInfo: false,
        nameservers: false,
    });
    const [submissionData, setSubmissionData] = useState<any>(null);

    const [ownerInfo, setOwnerInfo] = useState<InputSectionType>({
        ...inputs,
    });
    const [adminInfo, setAdminInfo] = useState<InputSectionType>({
        ...inputs,
    });
    const [technicalInfo, setTechnicalInfo] = useState<InputSectionType>({
        ...inputs,
    });
    const [billingInfo, setBillingInfo] = useState<InputSectionType>({
        ...inputs,
    });
    const [nameServerInfo, setNameServerInfo] =
        useState<NameserverInputSectionType>({
            nameserver1: "",
            nameserver2: "",
        });

    const [ownerErrors, setOwnerErrors] = useState<InputSectionType>(
        disabled
            ? {
                  ...inputs,
              }
            : { ...inputErrors },
    );
    const [adminErrors, setAdminErrors] = useState<InputSectionType>(
        disabled
            ? {
                  ...inputs,
              }
            : { ...inputErrors },
    );
    const [technicalErrors, setTechnicalErrors] = useState<InputSectionType>(
        disabled
            ? {
                  ...inputs,
              }
            : { ...inputErrors },
    );
    const [billingErrors, setBillingErrors] = useState<InputSectionType>(
        disabled
            ? {
                  ...inputs,
              }
            : { ...inputErrors },
    );
    const [nameServerErrors, setNameServerErrors] =
        useState<NameserverInputSectionType>({
            nameserver1: "",
            nameserver2: "",
        });

    function handleAdminContact(event: React.ChangeEvent<HTMLInputElement>) {
        setAdminContact(event.target.checked);
        console.log(event.target.checked);
        if (!event.target.checked) {
            setAdminInfo({ ...inputs });
            setAdminErrors({ ...inputErrors });
        } else {
            setAdminInfo(ownerInfo);
            setErrorSwitch((errorSwitch) => ({
                ...errorSwitch,
                adminInfo: false,
            }));
        }
    }

    function handleTechnicalContact(
        event: React.ChangeEvent<HTMLInputElement>,
    ) {
        setTechnicalContact(event.target.checked);
        if (!event.target.checked) {
            setTechnicalInfo({ ...inputs });
            setTechnicalErrors({ ...inputErrors });
        } else {
            setTechnicalInfo(ownerInfo);
            setErrorSwitch((errorSwitch) => ({
                ...errorSwitch,
                technicalInfo: false,
            }));
        }
    }

    function handleBillingContact(event: React.ChangeEvent<HTMLInputElement>) {
        setBillingContact(event.target.checked);
        if (!event.target.checked) {
            setBillingInfo({ ...inputs });
            setBillingErrors({ ...inputErrors });
        } else {
            setBillingInfo(ownerInfo);
            setErrorSwitch((errorSwitch) => ({
                ...errorSwitch,
                technicalInfo: false,
            }));
        }
    }

    async function getProfileData() {
        try {
            const response: any = await fetchData(
                `/users/getUserRegistrantProfile/${params.username}`,
            );
            if (!response.success) {
                throw new Error("Error fetching data");
            }
            if (response && response?.data) {
                setOwnerInfo(response.data.ownerInfo);
                if (response.data?.adminInfo) {
                    setAdminContact(
                        response.data.adminInfo?.sameAsOwner ? true : false,
                    );
                    setAdminInfo(response.data.adminInfo);
                }
                if (response.data?.billingContact) {
                    setBillingContact(
                        response.data.billingContact?.sameAsOwner
                            ? true
                            : false,
                    );
                    setBillingInfo(response.data.billingContact);
                }
                if (response.data?.technicalContact) {
                    setTechnicalContact(
                        response.data.technicalContact?.sameAsOwner
                            ? true
                            : false,
                    );
                    setTechnicalInfo(response.data.technicalContact);
                }
                if (response.data?.nameserverSettings) {
                    setNameServerInfo(response.data.nameserverSettings);
                }
            } else {
                setOwnerInfo({ ...inputs });
                setNameServerInfo({
                    nameserver1: "",
                    nameserver2: "",
                });
                setOwnerErrors({ ...inputErrors });
                setNameServerErrors({
                    nameserver1: "Required Field",
                    nameserver2: "Required Field",
                });
                setTechnicalContact(true);
                setBillingContact(true);
                setAdminContact(true);
            }
        } catch (e: any) {
            navigate("/admin/users");
        }
    }

    useEffect(() => {
        if (params && params?.username) {
            setUsername(params.username);
            getProfileData();
        } else {
            navigate("/users");
        }
    }, []);

    async function deleteProfileData(username: string) {
        try {
            if (username) {
                await postData("/users/deleteUserRegistrantProfile", {
                    username,
                });
                await getProfileData();
                showSuccess("Profile settings successfully deleted");
            } else {
                throw new Error("Username not found");
            }
        } catch (error) {
            showError("Profile deletion failed");
        }
        setErrorSwitch({
            ownerInfo: false,
            adminInfo: false,
            technicalInfo: false,
            billingInfo: false,
            nameservers: false,
        });
        setDeleteAlert(false);
    }

    async function submitForm() {
        const formData: FormDataTypeSubmit = {
            ownerInfo: ownerInfo,
            adminInfo: { ...adminInfo, sameAsOwner: adminContact },
            technicalContact: {
                ...technicalInfo,
                sameAsOwner: technicalContact,
            },
            billingContact: { ...billingInfo, sameAsOwner: billingContact },
            nameserverSettings: {
                nameserver1: nameServerInfo.nameserver1,
                nameserver2: nameServerInfo.nameserver2,
            },
        };
        setErrorSwitch((errorSwitch) => ({
            ...errorSwitch,
            ownerInfo: true,
            adminInfo: !adminContact ? true : false,
            technicalInfo: !technicalContact ? true : false,
            billingInfo: !billingContact ? true : false,
            nameservers: true,
        }));
        // console.log(adminContact, technicalContact, billingContact);
        try {
            await validationSchema.validate(ownerInfo);
            formData.ownerInfo = ownerInfo;
            await domainsValidationSchema.validate(nameServerInfo);
            formData.nameserverSettings = nameServerInfo;
            if (nameServerInfo.nameserver1 === nameServerInfo.nameserver2) {
                throw new Error("Duplicate entry");
            }
            if (!adminContact) {
                try {
                    validationSchema.validateSync(adminInfo);
                    formData.adminInfo = { ...adminInfo, sameAsOwner: false };
                } catch (error: any) {
                    return;
                }
            } else {
                formData.adminInfo = { ...ownerInfo, sameAsOwner: true };
            }

            if (!technicalContact) {
                try {
                    validationSchema.validateSync(technicalInfo);
                    formData.technicalContact = {
                        ...technicalInfo,
                        sameAsOwner: false,
                    };
                } catch (error: any) {
                    return;
                }
            } else {
                formData.technicalContact = { ...ownerInfo, sameAsOwner: true };
            }

            if (!billingContact) {
                try {
                    validationSchema.validateSync(billingInfo);
                    formData.billingContact = {
                        ...billingInfo,
                        sameAsOwner: false,
                    };
                } catch (error: any) {
                    return;
                }
            } else {
                formData.billingContact = { ...ownerInfo, sameAsOwner: true };
            }
            setSubmissionData(formData);
            setUpdateAlert(true);
        } catch (error: any) {
            return;
        }
    }

    async function submitAfterConfirming(username: string) {
        try {
            if (submissionData) {
                const result: any = await postData(
                    "/users/updateUserRegistrantProfile",
                    { data: submissionData, username },
                );
                if (result && result.success) {
                    showSuccess("Profile settings successfully updated");
                    navigate(`/profile/${params.username}`);
                } else {
                    throw new Error("update failed");
                }
            } else {
                throw new Error("update failed");
            }
        } catch (error) {
            showError("Profile update failed");
        }
        setUpdateAlert(false);
    }

    useEffect(() => {
        setErrorSwitch({
            ownerInfo: false,
            adminInfo: false,
            technicalInfo: false,
            billingInfo: false,
            nameservers: false,
        });
    }, [params]);

    return (
        <form
            style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexGrow: 1,
            }}
        >
            <ConfirmationBox
                open={deleteAlert}
                setOpen={setDeleteAlert}
                title="Confirm removing registrant profile information"
                subtitle="This action cannot be undone"
                triggerFunction={deleteProfileData}
                triggerValue={params.username}
            />
            <ConfirmationBox
                open={updateAlert}
                setOpen={setUpdateAlert}
                title="Confirm updating registrant profile information"
                subtitle="This action cannot be undone"
                triggerFunction={submitAfterConfirming}
                triggerValue={params.username}
            />
            <Stack>
                <ProfileFormSection
                    title="Owner Info"
                    data={ownerInfo}
                    setData={setOwnerInfo}
                    errors={ownerErrors}
                    setErrors={setOwnerErrors}
                    errorsSwitch={errorSwitch}
                    tag="ownerInfo"
                    disabled={disabled}
                />

                <ProfileSectionCheck
                    label="Admin Contact (Same as Owner)"
                    checked={adminContact}
                    onChange={handleAdminContact}
                    disabled={disabled}
                />
                {!adminContact && (
                    <ProfileFormSection
                        title="Admin Info"
                        data={adminInfo}
                        setData={setAdminInfo}
                        errors={adminErrors}
                        setErrors={setAdminErrors}
                        errorsSwitch={errorSwitch}
                        tag="adminInfo"
                        disabled={disabled}
                    />
                )}

                <ProfileSectionCheck
                    label="Technical Contact (Same as Owner)"
                    checked={technicalContact}
                    onChange={handleTechnicalContact}
                    disabled={disabled}
                />
                {!technicalContact && (
                    <ProfileFormSection
                        title="Technical Info"
                        data={technicalInfo}
                        setData={setTechnicalInfo}
                        errors={technicalErrors}
                        setErrors={setTechnicalErrors}
                        errorsSwitch={errorSwitch}
                        tag="technicalInfo"
                        disabled={disabled}
                    />
                )}

                <ProfileSectionCheck
                    label="Billing Contact (Same as Owner)"
                    checked={billingContact}
                    onChange={handleBillingContact}
                    disabled={disabled}
                />
                {!billingContact && (
                    <ProfileFormSection
                        title="Billing Info"
                        data={billingInfo}
                        setData={setBillingInfo}
                        errors={billingErrors}
                        setErrors={setBillingErrors}
                        errorsSwitch={errorSwitch}
                        tag="billingInfo"
                        disabled={disabled}
                    />
                )}

                <ProfileFormSectionDomains
                    title="Nameserver Settings"
                    data={nameServerInfo}
                    setData={setNameServerInfo}
                    setErrors={setNameServerErrors}
                    errors={nameServerErrors}
                    errorsSwitch={errorSwitch}
                    disabled={disabled}
                />

                {disabled ? (
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ mb: 4 }}
                    >
                        <LoadingButton
                            size="medium"
                            variant="outlined"
                            type="button"
                            style={{
                                height: "40px",
                                margin: "0 20px",
                                borderColor: "red",
                                color: "red",
                            }}
                            onClick={() => {
                                setDeleteAlert(true);
                            }}
                        >
                            Delete
                        </LoadingButton>
                        <Link to={`/profile/${params.username}/edit`}>
                            <LoadingButton
                                size="medium"
                                variant="contained"
                                type="button"
                                style={{
                                    height: "40px",
                                }}
                            >
                                Edit
                            </LoadingButton>
                        </Link>
                    </Stack>
                ) : (
                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{ mb: 4 }}
                    >
                        <LoadingButton
                            size="medium"
                            variant="contained"
                            type="button"
                            onClick={submitForm}
                            style={{
                                height: "40px",
                            }}
                        >
                            Update
                        </LoadingButton>
                    </Stack>
                )}
            </Stack>
        </form>
    );
};

export default ProfileForm;
