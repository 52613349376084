import React, {
    Dispatch,
    SetStateAction,
    useEffect,
    useMemo,
    useState,
} from "react";

import { Grid } from "@mui/material";

import ScrollX from "../../components/ScrollX";
import ReactTable from "./react-table/ReactTable";
import { balancesTableDataType } from "../../types/tableTypes";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import { cellCenterAlign, cellRightAlign, cellSeeMore } from "./CellFormatters";
import { useNavigate, useParams } from "react-router-dom";

const UserTransactionsTable = ({
    setUserBalance,
    setUsername,
}: {
    setUserBalance: Dispatch<SetStateAction<number>>;
    setUsername: Dispatch<SetStateAction<string>>;
}) => {
    const { postData } = useData();
    const { showError } = useMessage();
    const params = useParams();
    const navigate = useNavigate();
    const [data, setData] = useState<balancesTableDataType[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<{
        id: string;
        desc: boolean;
    }>({
        id: "sentAt",
        desc: true,
    });
    const [loading, setLoading] = useState<boolean>(false);

    interface RequestType {
        page: number;
        size: number;
        username: string;
        sort: {
            key: string;
            order: "ASC" | "DESC";
        };
    }

    const getData = async (isInit: boolean = false) => {
        if (params.username) {
            setLoading(true);
            const reqBody: RequestType = {
                page: isInit ? 1 : pageIndex + 1,
                size: pageSize,
                username: params.username,
                sort: {
                    key: "date",
                    order: "DESC",
                },
            };

            try {
                const response: any = await postData(
                    "/transaction/getAllUserTransactions",
                    reqBody,
                );
                if (response?.data) {
                    setData(response.data);
                } else {
                    setData([]);
                }
                setUserBalance(response.currentBalance);
                setUsername(response.username);
                setRowCount(response?.count ? response?.count : 0);
            } catch (error) {
                showError("Data Fetching Error");
            }
            setLoading(false);
        } else {
            navigate("/balances");
        }
    };

    useEffect(() => {
        getData();
    }, [pageIndex, pageSize, sortBy]);

    useEffect(() => {
        setPageIndex(0);
        getData();
    }, [rowCount]);

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                disableSortBy: true,
                Cell: cellCenterAlign,
            },
            {
                Header: "Message",
                accessor: "msg",
                disableSortBy: true,
                Cell: cellSeeMore,
                align: "left",
            },
            {
                Header: "Date",
                accessor: "created_at",
                disableSortBy: true,
                Cell: cellCenterAlign,
            },
            {
                Header: "Amount ($)",
                accessor: "difference",
                Cell: cellRightAlign,
                disableSortBy: true,
                align: "right",
            },
            {
                Header: "Balance ($)",
                accessor: "actual",
                disableSortBy: true,
                Cell: cellRightAlign,
                align: "right",
            },
        ],
        [],
    );

    return (
        <Grid container>
            {/* Table Section */}
            <Grid item xs={12}>
                <ScrollX>
                    <ReactTable
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        columns={columns}
                        data={data}
                        setPageIndexProp={setPageIndex}
                        setPageSizeProp={setPageSize}
                        setSortByProp={setSortBy}
                        loading={loading}
                        rowCount={rowCount}
                    />
                </ScrollX>
            </Grid>
        </Grid>
    );
};

export default UserTransactionsTable;
