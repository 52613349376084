import { Box, Menu, IconButton, MenuItem, Typography } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useState } from "react";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const UsersTableMenu = ({ value }: { value: number }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Box
            sx={{
                mt: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <IconButton
                aria-controls="popup-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                id="popup-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    "& .MuiList-root": {
                        py: 0,
                    },
                }}
            >
                <MenuItem>
                    <Link
                        to={`/profile/${value}`}
                        style={{
                            textDecoration: "none",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#3A354180",
                        }}
                    >
                        <BsFillPersonLinesFill
                            style={{
                                paddingRight: "2px",
                                marginRight: "8px",
                                color: "#1890FF",
                            }}
                        />
                        <Typography>View Profile</Typography>
                    </Link>
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default UsersTableMenu;
