const warnColor = "red";
const labelColor = "#3A3541CC";
const buttonColor = "#189FFF";

export const loginContainer = {
    display: "flex",
    flexDirection: { xs: "column", sm: "row" },
    width: "100%",
    height: "100vh",
    justifyContent: "space-around",
    alignItems: "center",
    backgroundPositionY: "bottom",
    backgroundRepeat: "repeat-x",
    backgroundSize: "cover",
};

export const rightLogo = {
    width: { sm: "150px", md: "300px", lg: "400px" },
    flex: 3,
    display: { xs: "none", sm: "flex" },
    justifyContent: "center",
};

export const formStyles = {
    wrapper: {
        height: "100%",
        width: { xs: "100%", sm: "auto" },
        bgcolor: { xs: "transparent", sm: "white" },
        display: "flex",
        justifyContent: "center",
        flex: { sm: 4, md: 3, lg: 2 },
        boxShadow: { xs: 0, sm: 4 },
    },
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        width: "100%",
        mx: { xs: 5, sm: 4, md: 8, lg: 12 },
        position: "relative",
    },
    responsiveLogoContainer: {
        width: "100px",
        height: "100px",
        display: { xs: "flex", sm: "none" },
        justifyContent: "center",
        alignItems: "center",
        mx: "auto",
    },
    formTitle: {
        fontSize: "24px",
        fontWeight: 700,
        textAlign: "center",
        color: labelColor,
    },
    formWarn: {
        fontSize: "13px",
        color: warnColor,
    },
    formLabel: {
        fontSize: "14px",
        color: labelColor,
        mb: 1,
    },
    formLink: {
        fontSize: "13px",
        color: labelColor,
        textAlign: "right",
    },
    formLinkBlue: {
        fontSize: "13px",
        textAlign: "center",
        color: "#1890FF",
        ml: "2px",
    },
    formButton: {
        width: "100%",
        bgcolor: buttonColor,
        color: "white",
        fontSize: "16px",
        fontWeight: 500,
        textAlign: "center",
        mt: 4,
        textTransform: "none",
        "&:hover": {
            color: "white",
            bgcolor: buttonColor,
        },
    },
};
