import {
    Box,
    Button,
    IconButton,
    InputAdornment,
    OutlinedInput,
    Typography,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useEffect, useState } from "react";
import largeLogo from "../assets/images/autobackorder_logo_login.svg";
import { formStyles } from "../pages/auth/LoginPageStyles";
import { Link, useLocation, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { messageType } from "../types/contextTypes";
import SnackbarMessage from "../components/SnackbarMessage";
import api from "../configs/apiConfig";

const ChangePasswordForm = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showConfPassword, setShowConfPassword] = useState<boolean>(false);
    const [showTempPassword, setShowTempPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<messageType>({
        message: "",
        type: "info",
    });
    const initialMessage = location.state?.message;
    const email = location.state?.email;
    const [open, setOpen] = useState<boolean>(false);
    const handleClickShowPassword = () => {
        setShowPassword((show) => !show);
    };
    const handleClickShowConfPassword = () => {
        setShowConfPassword((show) => !show);
    };
    const handleClickShowTempPassword = () => {
        setShowTempPassword((show) => !show);
    };
    // const navigate = useNavigate();

    const validationSchema = yup.object({
        temporaryPassword: yup
            .string()
            .required("Temporary password is required"),
        password: yup
            .string()
            .required("Password is required")
            .test(
                "password",
                "Your password should include minimum 6 characters including uppercase, lowercase, numerical and special character",
                function (value) {
                    return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/.test(
                        value as string,
                    );
                },
            ),
        confirmPassword: yup
            .string()
            .required("Confirm password is required")
            .test(
                "passwords-match",
                "Passwords did not match",
                function (value) {
                    return this.parent.password === value;
                },
            ),
    });

    function showMessage(type: "info" | "error", message: string) {
        setMessage({ message, type });
        setOpen(true);
    }

    const formik = useFormik({
        initialValues: {
            temporaryPassword: "",
            password: "",
            confirmPassword: "",
        },
        validationSchema,
        async onSubmit(values) {
            console.log(values);
            setLoading(true);
            try {
                await api.post("/changePasswd", {
                    token: values.temporaryPassword,
                    password: values.password,
                    email,
                });
                window.history.replaceState({}, document.title);
                navigate("/login", {
                    state: {
                        type: "passwdChange",
                        message: "Your password has changed successfully",
                    },
                });
            } catch (e: any) {
                if (e.response?.status === 401) {
                    showMessage("error", "Temporary password is wrong");
                } else if (e.response?.status === 500) {
                    showMessage("error", "Internal server error");
                } else {
                    showMessage("error", "Password change failed");
                }
            }
            setLoading(false);
        },
    });

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        if (!email) {
            navigate("/forgotPassword");
        }
        if (initialMessage) {
            timeout = setTimeout(() => {
                setMessage({ message: initialMessage, type: "success" });
                setOpen(true);
                navigate(".", {
                    state: {
                        message: "",
                        email,
                    },
                });
            }, 500);
        }
        return () => clearTimeout(timeout);
    }, []);

    return (
        <Box sx={formStyles.container}>
            <SnackbarMessage
                message={message.message}
                type={message.type}
                open={open}
                setOpen={setOpen}
            />
            <Box sx={formStyles.responsiveLogoContainer}>
                <img
                    src={largeLogo}
                    style={{
                        width: "inherit",
                        height: "inherit",
                        margin: "auto",
                    }}
                />
            </Box>
            <Box
                sx={{
                    py: 4,
                }}
            >
                <Typography sx={formStyles.formTitle}>
                    Account Recovery
                </Typography>
            </Box>
            <Box>
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <form
                        onSubmit={formik.handleSubmit}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        {/* Temporary Password Field */}
                        <Typography sx={formStyles.formLabel}>
                            Temporary Password
                        </Typography>
                        <OutlinedInput
                            error={
                                !!formik.errors.temporaryPassword &&
                                formik.touched.temporaryPassword
                            }
                            inputProps={{
                                style: {
                                    padding: "14px",
                                },
                            }}
                            sx={{ bgcolor: "white" }}
                            id="temporaryPassword"
                            name="temporaryPassword"
                            // placeholder="********"
                            type={showTempPassword ? "text" : "password"}
                            value={formik.values.temporaryPassword}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    "temporaryPassword",
                                    e.target.value.trim(),
                                );
                            }}
                            autoComplete={"temporary-password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle temporary password visibility"
                                        onClick={handleClickShowTempPassword}
                                        edge="end"
                                    >
                                        {showTempPassword ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />

                        {formik.errors.temporaryPassword &&
                            formik.touched.temporaryPassword && (
                                <Typography
                                    sx={{ ...formStyles.formWarn, mt: "1px" }}
                                >
                                    {formik.errors.temporaryPassword}
                                </Typography>
                            )}

                        {/* Password Field */}
                        <Typography
                            sx={{
                                ...formStyles.formLabel,
                                mt: 3,
                            }}
                        >
                            Password
                        </Typography>
                        <OutlinedInput
                            error={
                                !!formik.errors.password &&
                                formik.touched.password
                            }
                            inputProps={{
                                style: {
                                    padding: "14px",
                                },
                            }}
                            sx={{ bgcolor: "white" }}
                            id="password"
                            name="password"
                            // placeholder="********"
                            type={showPassword ? "text" : "password"}
                            value={formik.values.password}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    "password",
                                    e.target.value.trim(),
                                );
                            }}
                            autoComplete={"new-password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        edge="end"
                                    >
                                        {showPassword ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {formik.errors.password && formik.touched.password && (
                            <Typography
                                sx={{ ...formStyles.formWarn, mt: "1px" }}
                            >
                                {formik.errors.password}
                            </Typography>
                        )}

                        {/* Temporary Password Field */}
                        <Typography
                            sx={{
                                ...formStyles.formLabel,
                                mt: 3,
                            }}
                        >
                            Confirm Password
                        </Typography>
                        <OutlinedInput
                            error={
                                !!formik.errors.confirmPassword &&
                                formik.touched.confirmPassword
                            }
                            inputProps={{
                                style: {
                                    padding: "14px",
                                },
                            }}
                            sx={{ bgcolor: "white" }}
                            id="confirmPassword"
                            name="confirmPassword"
                            // placeholder="********"
                            type={showConfPassword ? "text" : "password"}
                            value={formik.values.confirmPassword}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    "confirmPassword",
                                    e.target.value.trim(),
                                );
                            }}
                            autoComplete={"new-password"}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle confirm password visibility"
                                        onClick={handleClickShowConfPassword}
                                        edge="end"
                                    >
                                        {showConfPassword ? (
                                            <VisibilityIcon />
                                        ) : (
                                            <VisibilityOffIcon />
                                        )}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {formik.errors.confirmPassword &&
                            formik.touched.confirmPassword && (
                                <Typography
                                    sx={{ ...formStyles.formWarn, mt: "1px" }}
                                >
                                    {formik.errors.confirmPassword}
                                </Typography>
                            )}
                        <Button
                            type="submit"
                            sx={formStyles.formButton}
                            disabled={
                                loading ||
                                formik.values.password === "" ||
                                formik.values.confirmPassword === "" ||
                                formik.values.temporaryPassword === ""
                            }
                        >
                            Proceed
                        </Button>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                mt: 1,
                            }}
                        >
                            <Typography sx={formStyles.formLink}>
                                Back to
                            </Typography>
                            <Link
                                to={"/login"}
                                style={{ textDecoration: "none" }}
                            >
                                <Typography sx={formStyles.formLinkBlue}>
                                    Sign In
                                </Typography>
                            </Link>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Box>
    );
};

export default ChangePasswordForm;
