import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import useMessage from "../hooks/useMessage";
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
} from "@mui/material";
import { TextField } from "@mui/material";
import useData from "../hooks/useApi";
import BlacklistResponsePopup from "../components/blacklist/BlacklistResponsePopup";
import { addDomainLimitBlacklist } from "../data/consts";
interface ResponsesType {
    domain: string;
    success: boolean;
    message: string;
}

const AddDomainToBlacklist = () => {
    const { postData } = useData();
    const { showError } = useMessage();
    const [popup, setPopup] = useState<boolean>(false);
    const [popupResponse, setPopupResponse] = useState<ResponsesType[]>([]);
    const [domainCount, setDomainCount] = useState<number>(0);

    const initialValues = {
        domains: "",
    };

    const handleDomainsChange = (e: any) => {
        e.target.value = e.target.value.replace(/\s/g, "");
        formik.handleChange(e);
    };

    const formik = useFormik({
        initialValues,
        validate: (values) => {
            const errors: any = {};
            if (!values.domains.trim()) {
                errors.domains = "Required Field";
            } else if (
                values.domains.split(",").filter((item) => item.trim() !== "")
                    .length > addDomainLimitBlacklist
            ) {
                errors.domains = "Domain Limit Reached";
            }
            return errors;
        },
        async onSubmit(values) {
            try {
                const convertedMsg: string[] = values.domains
                    .split(",")
                    .map((item) => item.trim())
                    .filter((item) => item !== "");
                console.log(convertedMsg);
                const response: any = await postData(
                    "/blacklistedDomains/addDomains",
                    {
                        domains: convertedMsg,
                    },
                );
                if (response && response?.success) {
                    console.log(response);
                    setPopupResponse(response.data.domainsStatus);
                    setPopup(true);
                    formik.values.domains = "";
                } else {
                    throw Error("failed");
                }
            } catch (e: any) {
                if (e.response?.status === 500) {
                    showError("Internal server error");
                } else {
                    showError("Failed to blacklist domains");
                }
            }
        },
    });

    const styles = {
        formControlStyle: {
            my: 1,
            minWidth: 100,
        },
        inputGroup: {
            my: { md: 0, xs: 1 },
            ml: { md: 5, xs: 0 },
        },
    };

    useEffect(() => {
        setDomainCount(
            formik.values.domains
                .split(",")
                .filter((item) => item.trim() !== "").length,
        );
    }, [formik.values.domains]);

    return (
        <form
            onSubmit={formik.handleSubmit}
            style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexGrow: 1,
            }}
        >
            <BlacklistResponsePopup
                open={popup}
                setOpen={setPopup}
                response={popupResponse}
            />
            <Grid container spacing={5}>
                <Grid item xs={12}>
                    <Stack>
                        <InputLabel>Domain Names</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <TextField
                                fullWidth
                                id="domains"
                                multiline
                                rows={10}
                                name="domains"
                                autoComplete="domains"
                                value={formik.values.domains}
                                onChange={handleDomainsChange}
                                // @ts-expect-error error
                                error={
                                    formik.errors.domains &&
                                    formik.touched.domains
                                }
                                placeholder="example.com, example.com, example.com"
                            />
                            {formik.errors.domains && (
                                <FormHelperText sx={{ mx: 0 }} error>
                                    {" "}
                                    {formik.touched.domains &&
                                        formik.errors.domains}{" "}
                                </FormHelperText>
                            )}
                        </FormControl>
                        <p
                            style={{
                                textAlign: "right",
                                margin: "0",
                                color:
                                    domainCount > addDomainLimitBlacklist
                                        ? "#ff4d4f"
                                        : "gray",
                                opacity: 0.3,
                            }}
                        >
                            {domainCount} / {addDomainLimitBlacklist}
                        </p>
                    </Stack>
                    <Grid item xs={12} mt={3} textAlign="right">
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{
                                width: "100px",
                                height: "40px",
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default AddDomainToBlacklist;
