import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import PauseIcon from "@mui/icons-material/Pause";
import { Dispatch, SetStateAction, useState } from "react";
import { AuctionType } from "../../enums/Status";
import { isLockedCheck } from "../../utils/helpers";

type PopUpMenuProps = {
    domainName: string;
    dropTime: number;
    whichPage: string;
    auctionType: string;
    handleBidAction: (action: string, nextState: boolean) => Promise<void>;
    setDeleteConfirmation: Dispatch<SetStateAction<boolean>>;
};

const AuctionPopUpMenu = (props: PopUpMenuProps) => {
    // const navigate = useNavigate();
    const { handleBidAction, setDeleteConfirmation } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleBidActionClick = async (action: string, nextState: boolean) => {
        await handleBidAction(action, nextState);
        setAnchorEl(null);
    };

    const handleBidDeleteClick = () => {
        setDeleteConfirmation(true);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            sx={{
                mt: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <IconButton
                aria-controls="popup-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                id="popup-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    "& .MuiList-root": {
                        py: 0.2,
                    },
                }}
            >
                {props.whichPage === "active" && (
                    <MenuItem
                        onClick={() => handleBidActionClick("Pause", true)}
                        sx={{ borderBottom: "1.5px solid #ccc" }}
                        disabled={
                            props.auctionType == AuctionType.POST ||
                            isLockedCheck(props.dropTime)
                        }
                    >
                        {" "}
                        {
                            <>
                                <PauseIcon
                                    fontSize="small"
                                    sx={{
                                        paddingRight: "2px",
                                        marginRight: "8px",
                                        color: "#1890FF",
                                    }}
                                />
                                <span>Pause</span>
                            </>
                        }
                    </MenuItem>
                )}
                <MenuItem
                    onClick={handleBidDeleteClick}
                    sx={{ borderBottom: "1.5px solid #ccc" }}
                    disabled={true}
                >
                    {" "}
                    <DeleteIcon
                        fontSize="small"
                        sx={{
                            paddingRight: "2px",
                            marginRight: "8px",
                            color: "#FF4D4F",
                        }}
                    />
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        window.location.href =
                            "https://www.expireddomains.net/login/";
                    }}
                    sx={{ borderBottom: "1.5px solid #ccc" }}
                >
                    {" "}
                    <EventAvailableIcon
                        fontSize="small"
                        sx={{
                            paddingRight: "2px",
                            marginRight: "8px",
                            color: "#1890FF",
                        }}
                    />
                    Check Drop Date
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default AuctionPopUpMenu;
