import MainWrapper from "../../components/wrappers/MainWrapper";
import EmailsTable from "../tables/EmailsTable";

const EmailsTablePage = () => {
    return (
        <MainWrapper title="Emails">
            <EmailsTable />
        </MainWrapper>
    );
};

export default EmailsTablePage;
