import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useMessage from "../hooks/useMessage";
import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
} from "@mui/material";
import { TextField } from "@mui/material";
import useData from "../hooks/useApi";
import { useNavigate } from "react-router-dom";
import { regExValidations } from "../data/consts";
import TypeDropDown from "../components/filters/DropDown";
import { emailTemplates, emailTemplateType } from "../utils/emailTemplates";
import { LoadingButton } from "@mui/lab";
import { Registrars } from "../enums/Registrars";

export type CreateEmailProps = {
    user?: string | undefined | null;
    domain?: string | undefined | null;
};

const CreateEmailForm = (createEmailProps: CreateEmailProps) => {
    const isTemplateEnabled =
        !!createEmailProps.domain && !!createEmailProps.user;
    const { postData } = useData();
    const { showError, showSuccess } = useMessage();
    const navigate = useNavigate();

    const initialValues = {
        email: "",
        template: "",
        subject: "",
        message: "",
    };

    const validationSchemaNew = {
        email: yup
            .string()
            .required("Required Field")
            .test("email", "Invalid Email", function (value) {
                return regExValidations.email.test(value as string);
            }),
        ...(isTemplateEnabled && {
            template: yup.string().required("Required Field"),
        }),
        subject: yup.string().trim().required("Required Field"),
        message: yup.string().trim().required("Required Field"),
    };

    const validationSchema = yup.object(validationSchemaNew);

    const [availableTemplates, setAvailableTemplates] = useState<string[]>([]);
    const [allTemplates, setAllTemplates] = useState<string[]>([]);
    const [selectedTemplate, setSelectedTemplate] = useState<string | null>("");
    const [availableEmails, setAvailableEmails] = useState<string[]>([]);
    const [allEmails, setAllEmails] = useState<string[]>([]);
    const [selectedEmail, setSelectedEmail] = useState<string | null>(null);
    const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

    interface RequestType {
        page: number;
        size: number;
        filterAnd: [];
    }

    const loadAllUserEmails = async () => {
        const userData: RequestType = {
            page: 1,
            size: 1000,
            filterAnd: [],
        };
        if (isTemplateEnabled) {
            // @ts-expect-error error
            userData.filterAnd.push({
                key: "username",
                operation: "LIKE",
                value: `%${createEmailProps.user}%`,
            });
        }

        try {
            const response: any = await postData(
                "/users/getUsersData",
                userData,
            );
            if (response?.data) {
                const emails: string[] = response.data.map(
                    (row: any) => row.email,
                );
                setAllEmails(emails);
                setAvailableEmails(emails);
                if (isTemplateEnabled) {
                    setSelectedEmail(emails[0]);
                    setEmail(emails[0]);
                }
            } else {
                setAllEmails([]);
            }
        } catch (error) {
            showError("Data Fetching Error");
        }
    };
    const loadTemplates = async () => {
        const registrars = Object.values(Registrars);
        const templates: string[] = registrars.map(
            (registrar) =>
                "AutoBackorder: Successfully caught domain notification - " +
                registrar,
        );
        setAvailableTemplates(templates);
        setAllTemplates(templates);
        const selectedTemplate: emailTemplateType | undefined =
            emailTemplates.find(
                (template) => template.templateName === templates[0],
            );
        if (selectedTemplate) {
            setSelectedTemplate(templates[0]);
            formik.setFieldValue("template", templates[0]);
            formik.setFieldValue("subject", templates[0]);
            if (createEmailProps.domain != null) {
                formik.setFieldValue(
                    "message",
                    selectedTemplate.message.replace(
                        "DOMAINNAME",
                        createEmailProps.domain,
                    ),
                );
            }
        }
    };

    const filterTemplates = async (partialTemplateName: string) => {
        setAvailableTemplates(
            allTemplates.filter((str) =>
                str.toLowerCase().startsWith(partialTemplateName.toLowerCase()),
            ),
        );
    };

    const filterEmails = async (partialEmail: string) => {
        setAvailableEmails(
            allEmails.filter((str) =>
                str.toLowerCase().startsWith(partialEmail.toLowerCase()),
            ),
        );
    };

    const setEmail = (value: any) => {
        formik.setFieldValue("email", value);
        setSelectedEmail(value);
    };

    const handleTemplateChange = (templateName: string) => {
        const selectedTemplate: emailTemplateType | undefined =
            emailTemplates.find(
                (template) => template.templateName === templateName,
            );
        if (selectedTemplate) {
            setSelectedTemplate(templateName);
            formik.setFieldValue("template", templateName);
            formik.setFieldValue("subject", templateName);
            if (createEmailProps.domain != null) {
                formik.setFieldValue(
                    "message",
                    selectedTemplate.message.replace(
                        "DOMAINNAME",
                        createEmailProps.domain,
                    ),
                );
            }
        }
    };

    useEffect(() => {
        if (isTemplateEnabled) {
            loadTemplates();
        }
        loadAllUserEmails();
    }, [isTemplateEnabled]);

    const formik = useFormik({
        initialValues,
        validationSchema,
        async onSubmit(values) {
            setIsSubmitLoading(true);
            try {
                // New message creation
                const response: any = await postData("/mail/sendMail", {
                    receiver: selectedEmail,
                    subject: values.subject,
                    message: values.message,
                });
                setIsSubmitLoading(false);
                if (response && response?.success) {
                    showSuccess("Email sent successfully");
                    if (!isTemplateEnabled) {
                        navigate("/admin/emails");
                    } else {
                        navigate("/allBids");
                    }
                } else {
                    throw Error("failed");
                }
            } catch (e: any) {
                setIsSubmitLoading(false);
                console.log(e);
                if (e.response?.request?.status === 400) {
                    showError("Receiver not exists");
                } else if (e.response?.request?.status === 500) {
                    showError("Email sending failed");
                } else {
                    showError("Email sending failed");
                }
            }
        },
    });

    const styles = {
        formControlStyle: {
            my: 1,
            minWidth: 100,
            width: "100%",
        },
        inputGroup: {
            my: { md: 0, xs: 1 },
            ml: { md: 5, xs: 0 },
        },
    };

    return (
        <form
            onSubmit={formik.handleSubmit}
            style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexGrow: 1,
            }}
        >
            <Grid container spacing={1}>
                <Grid
                    item
                    xs={12}
                    display={isTemplateEnabled ? "block" : "none"}
                >
                    <InputLabel>Template</InputLabel>
                    <FormControl sx={styles.formControlStyle}>
                        <TypeDropDown
                            availableValues={availableTemplates}
                            selectedValue={selectedTemplate}
                            onSelect={handleTemplateChange}
                            onInput={filterTemplates}
                            // @ts-expect-error error
                            error={
                                formik.errors.template &&
                                formik.touched.template
                            }
                        />
                        {formik.errors.template && (
                            <FormHelperText sx={{ mx: 0 }} error>
                                {" "}
                                {formik.touched.template &&
                                    formik.errors.template}{" "}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel>Email</InputLabel>
                    <FormControl sx={styles.formControlStyle}>
                        <TypeDropDown
                            availableValues={availableEmails}
                            selectedValue={selectedEmail}
                            onSelect={setEmail}
                            onInput={filterEmails}
                            // @ts-expect-error error
                            error={formik.errors.email && formik.touched.email}
                        />
                        {formik.errors.email && (
                            <FormHelperText sx={{ mx: 0 }} error>
                                {" "}
                                {formik.touched.email &&
                                    formik.errors.email}{" "}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <InputLabel>Subject</InputLabel>
                    <FormControl sx={styles.formControlStyle}>
                        <TextField
                            fullWidth
                            id="subject"
                            name="subject"
                            autoComplete="subject"
                            value={formik.values.subject}
                            onChange={formik.handleChange}
                            // @ts-expect-error error
                            error={
                                formik.errors.subject && formik.touched.subject
                            }
                        />
                        {formik.errors.subject && (
                            <FormHelperText sx={{ mx: 0 }} error>
                                {" "}
                                {formik.touched.subject &&
                                    formik.errors.subject}{" "}
                            </FormHelperText>
                        )}
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <InputLabel>Message</InputLabel>
                    <FormControl sx={styles.formControlStyle}>
                        <TextField
                            fullWidth
                            id="message"
                            multiline
                            rows={10}
                            name="message"
                            autoComplete="message"
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            // @ts-expect-error error
                            error={
                                formik.errors.message && formik.touched.message
                            }
                        />
                        {formik.errors.message && (
                            <FormHelperText sx={{ mx: 0 }} error>
                                {" "}
                                {formik.touched.message &&
                                    formik.errors.message}{" "}
                            </FormHelperText>
                        )}
                    </FormControl>
                    <Grid item xs={12} mt={3}>
                        <Stack direction="row" justifyContent="flex-end">
                            <LoadingButton
                                size="medium"
                                variant="contained"
                                type="submit"
                                style={{
                                    width: "100px",
                                    height: "40px",
                                }}
                                loading={isSubmitLoading}
                            >
                                Submit
                            </LoadingButton>
                        </Stack>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default CreateEmailForm;
