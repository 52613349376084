import React from "react";
import { Checkbox, Grid, InputLabel, TextField } from "@mui/material";
import { epochSecondsToDateConverter } from "../../utils/helpers";

export type AdminInfo = {
    username: string;
    domainName: string;
    tld: string;
    dropDate: number;
    status: string;
    isPaused: boolean;
    isLocked: boolean;
};

const AdminSetup = (adminInfo: AdminInfo) => {
    return (
        <Grid container mb={2} spacing={3} pb={3}>
            <Grid item md={4} xs={12}>
                <InputLabel>Username</InputLabel>
                <TextField
                    disabled
                    id="outlined-disabled"
                    fullWidth={true}
                    inputProps={{
                        style: {
                            backgroundColor: "#EEEEEE",
                            color: "#3A354180",
                        },
                    }}
                    value={adminInfo.username}
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <InputLabel>Domain name</InputLabel>
                <TextField
                    disabled={true}
                    fullWidth={true}
                    inputProps={{
                        style: {
                            backgroundColor: "#EEEEEE",
                            color: "#3A354180",
                        },
                    }}
                    value={adminInfo.domainName}
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <InputLabel>TLD</InputLabel>
                <TextField
                    disabled={true}
                    fullWidth={true}
                    inputProps={{
                        style: {
                            backgroundColor: "#EEEEEE",
                            color: "#3A354180",
                        },
                    }}
                    value={adminInfo.tld}
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <InputLabel>Drop date</InputLabel>
                <TextField
                    disabled={true}
                    fullWidth={true}
                    inputProps={{
                        style: {
                            backgroundColor: "#EEEEEE",
                            color: "#3A354180",
                        },
                    }}
                    value={epochSecondsToDateConverter(adminInfo.dropDate)}
                />
            </Grid>
            <Grid item md={4} xs={12}>
                <InputLabel>Progress status</InputLabel>
                <TextField
                    disabled={true}
                    fullWidth={true}
                    inputProps={{
                        style: {
                            backgroundColor: "#EEEEEE",
                            color: "#3A354180",
                        },
                    }}
                    value={adminInfo.status}
                />
            </Grid>
            <Grid container item xs={12} display={"flex"} spacing={2}>
                <Grid item display={"flex"} alignItems={"center"}>
                    <Checkbox disabled checked={adminInfo.isPaused} />
                    <InputLabel>Paused</InputLabel>
                </Grid>
                <Grid item display={"flex"} alignItems={"center"}>
                    <Checkbox disabled checked={adminInfo.isLocked} />
                    <InputLabel>Locked</InputLabel>
                </Grid>
            </Grid>
        </Grid>
    );
};
export default AdminSetup;
