import React, { useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useMessage from "../hooks/useMessage";
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
    OutlinedInput,
    InputAdornment,
} from "@mui/material";
import { TextField } from "@mui/material";
import useData from "../hooks/useApi";
import { useNavigate } from "react-router-dom";
import { numbersWithTwoDecimals } from "../utils/helpers";
import ConfirmationBox from "../components/common/AlertBoxes/ConfirmationBox";

const AddTransactionsForm = ({
    username,
}: {
    username: string | undefined;
}) => {
    const { postData } = useData();
    const { showError, showSuccess } = useMessage();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [alert, setAlert] = useState<boolean>(false);
    const openAlertBox = () => {
        setAlert(true);
    };

    const [description, setDescription] = useState<string>("");
    const [amount, setAmount] = useState<number>();

    const initialValues = {
        description: "",
        amount: "",
    };

    const validationSchema = yup.object().shape({
        description: yup.string().required("Required Field"),
        amount: yup
            .number()
            .typeError("Amount must be a number")
            .required("Required Field"),
    });

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema,
        async onSubmit(values) {
            if (formik.isValid && Object.keys(formik.errors).length === 0) {
                setAmount(Number(values.amount));
                setDescription(values.description);
                openAlertBox();
            }
        },
    });

    async function confirmTransaction(values: {
        amount: any;
        description: any;
    }) {
        setAlert(false);
        setLoading(true);
        try {
            const response: any = await postData(
                "/transaction/manualTransaction",
                {
                    amount: Number(values.amount),
                    receiver: username,
                    message: values.description,
                },
            );

            if (response && response?.success) {
                showSuccess("Transaction completed successfully.");
                navigate(`/balances/${username}`);
            } else {
                throw Error("failed");
            }
        } catch (e: any) {
            showError("Transaction Failed");
        }
        setLoading(false);
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2} sx={{ pt: 1 }}>
                <ConfirmationBox
                    title={`Do you want to confirm adding a transaction of ${amount}USD to ${username} ?`}
                    subtitle="This action cannot be undone"
                    open={alert}
                    setOpen={setAlert}
                    triggerFunction={confirmTransaction}
                    triggerValue={{ amount: amount, description: description }}
                />

                {/* Username */}
                <Grid item sm={6} xs={12}>
                    <Stack>
                        <InputLabel>Username</InputLabel>
                        <FormControl>
                            <TextField
                                id="username"
                                name="username"
                                fullWidth
                                disabled={true}
                                sx={{
                                    "&:disabled": {
                                        color: "#3A354180 !important",
                                    },
                                    marginTop: "10px",
                                }}
                                value={username}
                                inputProps={{
                                    style: {
                                        backgroundColor: "#EEEEEE",
                                        color: "#3A354180",
                                    },
                                }}
                            />
                        </FormControl>
                    </Stack>
                </Grid>

                {/* Amount */}
                <Grid item sm={6} xs={12}>
                    <Stack>
                        <InputLabel>Amount</InputLabel>
                        <FormControl>
                            <Stack direction="row">
                                <OutlinedInput
                                    id="amount"
                                    name="amount"
                                    fullWidth
                                    type={"text"}
                                    placeholder="0.00"
                                    endAdornment={
                                        <InputAdornment position="start">
                                            $
                                        </InputAdornment>
                                    }
                                    value={formik.values.amount}
                                    onChange={(e) => {
                                        const value = numbersWithTwoDecimals(
                                            e.target.value,
                                        );
                                        formik.setFieldValue("amount", value);
                                    }}
                                    sx={{
                                        marginTop: "10px",
                                    }}
                                    // @ts-expect-error error
                                    error={
                                        formik.touched.amount &&
                                        formik.errors.amount
                                    }
                                />
                            </Stack>

                            {formik.errors.amount && (
                                <FormHelperText error sx={{ mx: 0 }}>
                                    {" "}
                                    {formik.touched.amount &&
                                        formik.errors.amount}{" "}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                </Grid>

                {/* Description */}
                <Grid item sm={12} xs={12}>
                    <Stack>
                        <InputLabel>Description</InputLabel>
                        <FormControl>
                            <Stack direction="row">
                                <OutlinedInput
                                    id="description"
                                    name="description"
                                    fullWidth
                                    type={"text"}
                                    value={formik.values.description}
                                    onChange={formik.handleChange}
                                    sx={{
                                        marginTop: "10px",
                                    }}
                                    // @ts-expect-error error
                                    error={
                                        formik.errors.description &&
                                        formik.touched.description
                                    }
                                />
                            </Stack>

                            {formik.errors.description && (
                                <FormHelperText error sx={{ mx: 0 }}>
                                    {" "}
                                    {formik.touched.description &&
                                        formik.errors.description}{" "}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Stack>
                </Grid>

                {/* Buttons */}
                <Grid item xs={12} display={"flex"} justifyContent="flex-end">
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={loading}
                        style={{
                            width: "100px",
                            height: "40px",
                        }}
                    >
                        {"Submit"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default AddTransactionsForm;
