import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

// project import
import useAuth from "../../hooks/useAuth";

// types
import { GuardProps } from "../../types/auth";

// ==============================|| AUTH GUARD ||============================== //

const AuthGuard = ({ children }: GuardProps) => {
    const { isLogged } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isLogged) {
            navigate("login", { replace: true });
        }
    }, [isLogged, navigate]);

    return children;
};

export default AuthGuard;
