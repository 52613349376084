import { Box, Button, TextField, Typography } from "@mui/material";
import { useState } from "react";
import largeLogo from "../assets/images/autobackorder_logo_login.svg";
import { formStyles } from "../pages/auth/LoginPageStyles";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { messageType } from "../types/contextTypes";
import SnackbarMessage from "../components/SnackbarMessage";
import api from "../configs/apiConfig";

const ForgotPasswordForm = () => {
    const navigate = useNavigate();
    const [message, setMessage] = useState<messageType>({
        message: "",
        type: "info",
    });
    const [open, setOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const validationSchema = yup.object({
        email: yup
            .string()
            .required("Email is required")
            .email("Invalid email"),
    });

    function showMessage(type: "info" | "error", message: string) {
        setMessage({ message, type });
        setOpen(true);
    }

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema,
        async onSubmit(values) {
            console.log(values);
            setLoading(true);
            try {
                await api.post("/getTempPasswd", {
                    email: values.email,
                });
                navigate("/changePassword", {
                    state: {
                        email: values.email,
                        message: "We sent you the temporary password via email",
                    },
                });
            } catch (e: any) {
                if (e.response?.status === 404) {
                    showMessage("error", "Invalid email");
                } else if (e.response?.status === 401) {
                    showMessage("error", "User not authorized");
                } else if (
                    e.response?.status === 500 ||
                    e.response?.status === 503
                ) {
                    showMessage("error", "Internal server error");
                } else {
                    showMessage("error", "Password change failed");
                }
            }
            setLoading(false);
        },
    });

    return (
        <Box sx={formStyles.container}>
            <SnackbarMessage
                message={message.message}
                type={message.type}
                open={open}
                setOpen={setOpen}
            />
            <Box sx={formStyles.responsiveLogoContainer}>
                <img
                    src={largeLogo}
                    style={{
                        width: "inherit",
                        height: "inherit",
                        margin: "auto",
                    }}
                />
            </Box>
            <Box
                sx={{
                    py: 4,
                }}
            >
                <Typography sx={formStyles.formTitle}>
                    Account Recovery
                </Typography>
            </Box>
            <Box>
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <form
                        onSubmit={formik.handleSubmit}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Typography sx={formStyles.formLabel}>Email</Typography>
                        <TextField
                            error={
                                !!formik.errors.email && formik.touched.email
                            }
                            id="email"
                            name="email"
                            // placeholder="johndoe@example.com"
                            inputProps={{
                                style: {
                                    padding: "14px",
                                    fontSize: "14px",
                                    backgroundColor: "white",
                                },
                            }}
                            value={formik.values.email}
                            onChange={(e) => {
                                formik.setFieldValue(
                                    "email",
                                    e.target.value.trim(),
                                );
                            }}
                        />
                        {formik.errors.email && formik.touched.email && (
                            <Typography
                                sx={{ ...formStyles.formWarn, mt: "1px" }}
                            >
                                {formik.errors.email}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            sx={formStyles.formButton}
                            disabled={
                                !/^[\w-.]+@([\w-]+\.)+[\w-]{2,6}$/g.test(
                                    formik.values.email,
                                ) || loading
                            }
                        >
                            Proceed
                        </Button>
                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "center",
                                mt: 1,
                            }}
                        >
                            <Typography sx={formStyles.formLink}>
                                Back to
                            </Typography>
                            <Link
                                to={"/login"}
                                style={{ textDecoration: "none" }}
                            >
                                <Typography sx={formStyles.formLinkBlue}>
                                    Sign In
                                </Typography>
                            </Link>
                        </Box>
                    </form>
                </Box>
            </Box>
        </Box>
    );
};

export default ForgotPasswordForm;
