import { CircularProgress } from "@mui/material";

type spinnerProps = {
    loading: boolean;
    size: number;
    sx: any;
};

function SpinnerLoader(props: spinnerProps) {
    return props.loading ? (
        <CircularProgress size={props.size} color="inherit" sx={props.sx} />
    ) : null;
}

export default SpinnerLoader;
