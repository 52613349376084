import {
    Box,
    InputLabel,
    FormControl,
    Grid,
    TextField,
    Stack,
    Button,
    useMediaQuery,
} from "@mui/material";

import { DateTime } from "luxon";
import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useMemo, useState, useRef } from "react";
import RangePicker from "../../components/filters/RangePicker";
import MultipleSelect from "../../components/filters/MulipleSelect";
import ActionReactTable from "./react-table/ActionReactTable";
import useMessage from "../../hooks/useMessage";
import { auctionTableType } from "../../types/tableTypes";
import {
    cellAuctionTypeStatus,
    cellCenterAlign,
    cellDateAndTimeNormal,
    cellDomainStatus,
    cellPreviewBalance,
} from "./CellFormatters";
import useData from "../../hooks/useApi";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SelectDropDown from "../../components/popups/SelectDropDown";
import { CSVLink } from "react-csv";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArchiveIcon from "@mui/icons-material/Archive";
import PauseIcon from "@mui/icons-material/Pause";
import DeleteIcon from "@mui/icons-material/Delete";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ConfirmationBox from "../../components/common/AlertBoxes/ConfirmationBox";
import { Link } from "react-router-dom";
import AuctionTypeStatus from "../../components/common/AuctionTypeStatus";
import { AuctionType } from "../../enums/Status";
import { AuctionStatusEnum } from "../../enums/AuctionStatus";
import { DomainStatusEnum } from "../../enums/DomainStatus";
import AuctionActionResponse from "../../components/auction/AuctionActionResponse";
import {
    auctionArchiveColMapping,
    auctionColMapping,
    stringKeyDict,
} from "../../types/exportTypes";

type AllAuctionsTableProps = {
    pageType: "active" | "archived";
};
type RequestType = {
    query: {
        page: number;
        size: number;
        filterAnd?: any[];
        filterOr?: any[];
        sort: {
            key: string;
            order: "ASC" | "DESC";
        };
    };
    archived: boolean;
};
interface AuctionActionRequestType {
    auctionIds: number[];
    action: string;
    value: boolean;
}

const auctionNormalStatus = {
    Locked: AuctionStatusEnum.LOCKED,
    Ongoing: AuctionStatusEnum.ONGOING,
    "Payment Pending": AuctionStatusEnum.PAYMENT_PENDING,
};
const auctionArchiveStatus = {
    Archived: "Archived",
    Cancelled: AuctionStatusEnum.CANCELLED,
    Closed: AuctionStatusEnum.CLOSED,
    Default: AuctionStatusEnum.DEFAULT,
};
const domainStatus = {
    Success: DomainStatusEnum.SUCCESS,
    "Auto Renewed": DomainStatusEnum.AUTO_RENEWED,
    Cancelled: DomainStatusEnum.CANCELLED,
    "Not Found": DomainStatusEnum.DOMAIN_NOT_FOUND,
    Failure: DomainStatusEnum.FAILURE,
    "Not Processed": DomainStatusEnum.NOT_PROCESSED,
    Skipped: DomainStatusEnum.SKIPPED,
};

const AllAuctionsTable = (props: AllAuctionsTableProps) => {
    const { showError, showSuccess, showInfo } = useMessage();
    const { postData } = useData();
    const isXs = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

    const columnMap =
        props.pageType === "active"
            ? auctionColMapping
            : auctionArchiveColMapping;

    // auction action popup status states
    const [response, setResponse] = useState<string[]>([]);
    const [openModel, setOpenModel] = useState<boolean>(false);

    // Pagination and data handlers
    const [data, setData] = useState<auctionTableType[]>([]);
    const [exportData, setExportData] = useState<stringKeyDict[]>([]);

    const [pageSize, setPageSize] = useState<number>(10);
    const [rowCount, setRowCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [sortBy, setSortBy] = useState<{
        id: string;
        desc: boolean;
    }>({
        // eslint-disable-next-line quotes
        id: 'auction"."auctionId',
        desc: true,
    });

    const csvLinkRef = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null);

    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const [selectedRows, setSelectedRows] = useState<boolean[]>([]);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    //? Filters & searches
    const [domainSearch, setDomainSearch] = useState<string>("");
    const [statusFilter, setStatusFilter] = useState<string[]>([]);
    const [domainFilter, setDomainFilter] = useState<string[]>([]);
    const [typeFilter, setTypeFilter] = useState<string[]>([]);
    const [startDate, setStartDate] = useState(
        DateTime.now()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .minus({ days: 7 })
            .toMillis(),
    );
    const [endDate, setEndDate] = useState(
        DateTime.now()
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            .plus({ days: 100 })
            .toMillis(),
    );

    const [resetSwitch, setResetSwitch] = useState<boolean>(false);

    //? function to handle the search button. Without this, while paginating, it applies the current selected sorts and filters every time.
    const searchButtonHandler = () => {
        setPageIndex(0);
        searchValues.current = {
            endDate,
            startDate,
            domainSearch,
            statusFilter: statusFilter.map((value: any) =>
                props.pageType === "active"
                    ? // @ts-expect-error no-error
                      auctionNormalStatus[value]
                    : // @ts-expect-error no-error
                      auctionArchiveStatus[value],
            ),
            domainFilter: domainFilter.map(
                (value: any) =>
                    // @ts-expect-error no-error
                    domainStatus[value],
            ),
            typeFilter,
        };
        if (props.pageType === "archived") {
            searchValues.current = {
                ...searchValues.current,
            };
        }
        console.log(searchValues.current);
        loadAuctionData(false);
    };

    // ? This useRef is controlling the filters against pagination.
    const searchValues = useRef({
        startDate,
        endDate,
        statusFilter,
        domainSearch,
        typeFilter,
        domainFilter,
    });

    const handleOnDateChange = (value: any) => {
        setStartDate(value[0]);
        setEndDate(value[1]);
    };

    //? get the selected rows (from checkboxes)
    const processSelectedArray = () => {
        const auctions: number[] = [];

        for (let i = 0; i < data.length; i++) {
            if (selectedRows[i]) {
                auctions.push(data[i].auctionId);
            }
        }
        return auctions;
    };

    //? Handle the actions -> Pause, Archive, ...
    const auctionAction = async (action: string, nextState: boolean) => {
        try {
            if (!selectedRows.every((value) => value === false)) {
                const bidActionData: AuctionActionRequestType = {
                    auctionIds: processSelectedArray(),
                    action,
                    value: nextState,
                };

                const bidActionResponse: any = await postData(
                    "/auction/performBulkAction",
                    bidActionData,
                );

                if (bidActionResponse) {
                    if (bidActionResponse.failed.length == 0) {
                        showSuccess("Action Performed Successfully");
                    } else {
                        setResponse(
                            bidActionResponse.failed.map(
                                (value: number) =>
                                    data.find((row) => row.auctionId == value)
                                        ?.domainName,
                            ),
                        );
                        setOpenModel(true);
                    }
                } else {
                    showError("Actions failed");
                }
                loadAuctionData(false);
            }
        } catch {
            console.log("Action Failed");
        }
    };

    // ! This functionality is disabled right now
    const auctionDelete = async (auctions: boolean[]) => {
        try {
            console.log(auctions);
        } catch {
            console.log("Auction Delete Failed !");
        }
    };

    const loadAuctionData = async (toExport: boolean) => {
        if (!toExport) {
            setLoading(true);
        }
        const sortMapping: { [key: string]: string } = {
            auctionId: "auction.auctionId",
            domainName: "domain.domainName",
            auctionType: "auction.auctionType",
            auctionStart: "auction.auctionStart",
            auctionEnd: "auction.auctionEnd",
            auctionStatus: "auction.auctionStatus",
            domainStatus: "auction.domainStatus",
            winningPrice: "auction.winningPrice",
        };

        const auctionsRequest: RequestType = {
            query: {
                page: pageIndex + 1,
                size: toExport ? rowCount : pageSize,
                filterAnd: [],
                filterOr: [],
                sort: {
                    key: sortMapping[sortBy.id],
                    order: sortBy.desc ? "DESC" : "ASC",
                },
            },
            archived: false,
        };
        if (auctionsRequest.query.filterAnd && auctionsRequest.query.filterOr) {
            //? Auction status filter
            if (props.pageType == "active") {
                auctionsRequest.archived = false;
                if (searchValues.current.statusFilter.length > 0) {
                    auctionsRequest.query.filterAnd.push({
                        // eslint-disable-next-line quotes
                        key: 'auction"."auctionStatus',
                        operation: "IN",
                        value: searchValues.current.statusFilter,
                    });
                } else {
                    auctionsRequest.query.filterAnd.push({
                        // eslint-disable-next-line quotes
                        key: 'auction"."auctionStatus',
                        operation: "IN",
                        value: Object.values(auctionNormalStatus),
                    });
                }
            } else {
                auctionsRequest.archived = true;
                if (searchValues.current.statusFilter.length > 0) {
                    if (
                        searchValues.current.statusFilter.includes("Archived")
                    ) {
                        if (searchValues.current.statusFilter.length == 1) {
                            auctionsRequest.query.filterOr.push({
                                // eslint-disable-next-line quotes
                                key: 'auction"."isArchived',
                                operation: "=",
                                value: true,
                            });
                        } else {
                            auctionsRequest.query.filterOr.push({
                                // eslint-disable-next-line quotes
                                key: 'auction"."isArchived',
                                operation: "=",
                                value: true,
                            });
                            auctionsRequest.query.filterOr.push({
                                // eslint-disable-next-line quotes
                                key: 'auction"."auctionStatus',
                                operation: "IN",
                                value: searchValues.current.statusFilter.filter(
                                    (a) => a != "Archived",
                                ),
                            });
                        }
                    } else {
                        auctionsRequest.query.filterAnd.push({
                            // eslint-disable-next-line quotes
                            key: 'auction"."auctionStatus',
                            operation: "IN",
                            value: searchValues.current.statusFilter,
                        });
                        auctionsRequest.query.filterAnd.push({
                            // eslint-disable-next-line quotes
                            key: 'auction"."isArchived',
                            operation: "=",
                            value: false,
                        });
                    }
                } else {
                    auctionsRequest.query.filterOr.push({
                        // eslint-disable-next-line quotes
                        key: 'auction"."auctionStatus',
                        operation: "IN",
                        value: [
                            AuctionStatusEnum.CANCELLED,
                            AuctionStatusEnum.CLOSED,
                            AuctionStatusEnum.DEFAULT,
                        ],
                    });
                    auctionsRequest.query.filterOr.push({
                        // eslint-disable-next-line quotes
                        key: 'auction"."isArchived',
                        operation: "=",
                        value: true,
                    });
                }
            }

            //? Auction type filter
            if (searchValues.current.typeFilter.length > 0) {
                const typeFilterValues = [];
                if (searchValues.current.typeFilter.includes("Pre Auction")) {
                    typeFilterValues.push("Pre auction");
                }
                if (searchValues.current.typeFilter.includes("Post Auction")) {
                    typeFilterValues.push("Post auction");
                }
                auctionsRequest.query.filterAnd.push({
                    // eslint-disable-next-line quotes
                    key: 'auction"."auctionType',
                    operation: "IN",
                    value: typeFilterValues,
                });
            }

            //? Domain status filter
            if (searchValues.current.domainFilter.length > 0) {
                auctionsRequest.query.filterAnd.push({
                    // eslint-disable-next-line quotes
                    key: 'auction"."domainStatus',
                    operation: "IN",
                    value: searchValues.current.domainFilter,
                });
            }

            //? Domain search
            if (searchValues.current.domainSearch !== "") {
                const domainsList: string[] = searchValues.current.domainSearch
                    .split(",")
                    .map((str) => str.trim());
                auctionsRequest.query.filterAnd.push({
                    key: "domainName",
                    operation: "IN",
                    value: domainsList,
                });
            }

            // //? Date filters
            if (searchValues.current.startDate) {
                auctionsRequest.query.filterAnd.push({
                    // eslint-disable-next-line quotes
                    key: 'auction"."auctionStart',
                    operation: ">",
                    value: Math.floor(searchValues.current.startDate / 1000),
                });
            }

            // //? Date filters
            if (searchValues.current.endDate) {
                auctionsRequest.query.filterAnd.push({
                    // eslint-disable-next-line quotes
                    key: 'auction"."auctionStart',
                    operation: "<",
                    value: Math.ceil(searchValues.current.endDate / 1000),
                });
            }
        }

        if (auctionsRequest.query.filterAnd?.length == 0) {
            delete auctionsRequest.query.filterAnd;
        }
        if (auctionsRequest.query.filterOr?.length == 0) {
            delete auctionsRequest.query.filterOr;
        }

        try {
            const response: any = await postData(
                "/auction/getAll",
                auctionsRequest,
            );

            switch (toExport) {
                case true:
                    if (response?.data) {
                        const filteredData = response.data.map((item: any) => {
                            const filteredItem: any = {};
                            selectedColumns.forEach((col) => {
                                filteredItem[columnMap[col]] = item[col];
                            });
                            return filteredItem;
                        });
                        setExportData(filteredData);
                    } else {
                        setExportData([]);
                    }
                    break;
                case false:
                    if (response?.data) {
                        setData(response.data);
                        setSelectedRows(
                            new Array(response.data.length).fill(false),
                        );
                    } else {
                        setData([]);
                    }
                    setRowCount(response?.count ? response.count : 0);
                    break;
            }
        } catch (error) {
            console.log(error);
            showError("Data Fetching Error");
        }
        if (!toExport) {
            setLoading(false);
        }
    };

    function stringifySelectedRows(): string {
        const selectedBids: any = [];

        for (let i = 0; i < data.length; i++) {
            if (selectedRows[i]) {
                selectedBids.push(data[i]);
            }
        }
        return JSON.stringify(selectedBids, null, 2);
    }

    // ? Table cell formatters
    function cellLink({ value, row }: any) {
        if (value) {
            return (
                <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    <Link
                        style={{
                            textDecoration: "none",
                            color: "#1890FF",
                            textAlign: "center",
                        }}
                        to={`/perAuctionView/${row.original.auctionId}`}
                    >
                        {value}
                    </Link>
                </Box>
            );
        } else {
            return <></>;
        }
    }

    function cellAuctionType({ value, row }: any) {
        if (value) {
            return (
                <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                >
                    {value}
                    <AuctionTypeStatus
                        status={
                            row.original.auctionType == "Pre auction"
                                ? AuctionType.PRE
                                : AuctionType.POST
                        }
                    />
                </Box>
            );
        } else {
            return <></>;
        }
    }

    async function handleCopy() {
        const stringToCopy = stringifySelectedRows();
        try {
            await navigator.clipboard.writeText(stringToCopy);
            showSuccess("Copied to clipboard!");
        } catch (err) {
            showError("Failed to copy!");
        }
    }

    const dataExport = async () => {
        if (data.length === 0) {
            showInfo("No Data Available to Export");
        } else if (selectedColumns.length === 0) {
            showInfo("Select at least 1 Column to Export");
        } else {
            await loadAuctionData(true);
        }
    };

    // ? reset all the filters and load data
    const resetView = async () => {
        setLoading(true);
        setStartDate(
            DateTime.now()
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .minus({ days: 7 })
                .toMillis(),
        );
        setEndDate(
            DateTime.now()
                .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
                .plus({ days: 100 })
                .toMillis(),
        );
        setDomainSearch("");
        setStatusFilter([]);
        setDomainFilter([]);
        setTypeFilter([]);
        setPageIndex(0);
        setSelectedRows(new Array(data.length).fill(false));
        searchValues.current = {
            endDate: DateTime.now()
                .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
                .plus({ days: 100 })
                .toMillis(),
            startDate: DateTime.now()
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .minus({ days: 7 })
                .toMillis(),
            domainSearch: "",
            statusFilter: [],
            typeFilter: [],
            domainFilter: [],
        };
        setResetSwitch(!resetSwitch);
        setLoading(false);
    };

    // Use Effects

    useEffect(() => {
        if (exportData.length > 0) {
            csvLinkRef?.current?.link.click();
        }
    }, [exportData]);

    useEffect(() => {
        if (!selectedRows.every((value) => value === false)) {
            setIsSelected(true);
        } else if (!selectedRows.every((value) => value === true)) {
            setIsSelected(false);
        } else {
            setIsSelected(false);
        }
    }, [selectedRows]);

    useEffect(() => {
        setPageIndex(0);
        setSelectedRows([]);
        setStatusFilter([]);
        setResetSwitch((resetSwitch) => !resetSwitch);
    }, [pageSize, props.pageType]);

    useEffect(() => {
        loadAuctionData(false);
    }, [sortBy, pageIndex, resetSwitch]);

    const columns = useMemo(() => {
        let headCols = [
            {
                Header: "Auction ID",
                accessor: "auctionId",
                Cell: cellLink,
                disableSortBy: false,
            },
            {
                Header: "Domain Name",
                accessor: "domainName",
                Cell: cellAuctionType,
                disableSortBy: false,
            },
        ];
        if (props.pageType === "archived") {
            headCols = [
                ...headCols,
                {
                    Header: "Domain Status",
                    accessor: "domainStatus",
                    Cell: cellDomainStatus,
                    disableSortBy: true,
                },
            ];
        }
        headCols = [
            ...headCols,
            {
                Header: "Auction Start Time",
                accessor: "auctionStart",
                Cell: cellDateAndTimeNormal,
                disableSortBy: false,
            },
            {
                Header: "Auction End Time",
                accessor: "auctionEnd",
                Cell: cellDateAndTimeNormal,
                disableSortBy: false,
            },
            {
                Header: "Auction Status",
                accessor: "auctionStatus",
                Cell: cellAuctionTypeStatus,
                disableSortBy: true,
            },
        ];
        if (props.pageType === "archived") {
            headCols = [
                ...headCols,
                {
                    Header: "Winning Price ($)",
                    accessor: "winningPrice",
                    Cell: cellPreviewBalance,
                    disableSortBy: false,
                },
                {
                    Header: "Winners",
                    accessor: "numberOfWinners",
                    Cell: cellCenterAlign,
                    disableSortBy: true,
                },
            ];
        }
        return headCols;
    }, [props.pageType]);

    return (
        <Grid container>
            {/* Delete confirmation box */}
            <ConfirmationBox
                title={`Confirm removing selected bids from ${
                    props.pageType == "active" ? "All Bids" : "Archive"
                }`}
                subtitle="This action cannot be undone"
                open={openDelete}
                setOpen={setOpenDelete}
                triggerFunction={auctionDelete}
                triggerValue={selectedRows}
            />

            {/* Bulk action failed response popup */}
            <AuctionActionResponse
                open={openModel}
                setOpen={setOpenModel}
                data={response}
            />
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: "flex",
                        paddingTop: 4,
                        paddingBottom: 1,
                    }}
                >
                    <RangePicker
                        startDate={startDate}
                        endDate={endDate}
                        handleOnDateChange={handleOnDateChange}
                        noFutureDates={false}
                        startDateLabel={"Auction Start"}
                        endDateLabel={"Auction Start"}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        my: 1,
                        flexGrow: 1,
                        flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                        },
                        justifyContent: { xs: "flex-start" },
                        width: { xs: "100%", sm: "100%", md: "100%" },
                    }}
                >
                    <TextField
                        fullWidth
                        id="message"
                        placeholder="Domains"
                        value={domainSearch}
                        multiline
                        rows={3}
                        name="message"
                        autoComplete="message"
                        sx={{
                            width: {
                                xs: "100%",
                                sm: "100%",
                                md: "34.5%",
                            },
                            paddingRight: { xs: 0, sm: 0, md: 0.2 },
                        }}
                        onKeyDown={(event) => {
                            if (event.key === " ") {
                                event.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            setDomainSearch(e.target.value.toLowerCase());
                        }}
                    />
                    <Stack
                        sx={{
                            width: {
                                xs: "100%",
                                sm: "100%",
                                md: "500px",
                            },
                            gap: {
                                md: 1.2,
                                sm: 0,
                            },
                            ml: { xs: 0, sm: 0, md: 1 },
                            flexDirection: { xs: "column", md: "row" },
                        }}
                    >
                        {props.pageType === "archived" && (
                            <FormControl
                                // disabled={}
                                sx={{
                                    width: {
                                        xs: "100%",
                                        md: "100%",
                                    },
                                    my: { xs: 1, sm: 1, md: 0 },
                                }}
                            >
                                <InputLabel
                                    id="demo-simple-select-label"
                                    sx={{
                                        color: "#bbb",
                                        overflow: "visible",
                                    }}
                                >
                                    Domain Status
                                </InputLabel>
                                <MultipleSelect
                                    levels={Object.keys(domainStatus)}
                                    fieldValue={domainFilter}
                                    onSelect={setDomainFilter}
                                />
                            </FormControl>
                        )}
                        <FormControl
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "100%",
                                },
                                my: { xs: 1, sm: 1, md: 0 },
                            }}
                        >
                            <InputLabel
                                id="demo-simple-select-label"
                                sx={{ color: "#bbb", overflow: "visible" }}
                            >
                                Auction Status
                            </InputLabel>
                            <MultipleSelect
                                levels={
                                    props.pageType === "active"
                                        ? Object.keys(auctionNormalStatus)
                                        : Object.keys(auctionArchiveStatus)
                                }
                                fieldValue={statusFilter}
                                onSelect={setStatusFilter}
                            />
                        </FormControl>
                        <FormControl
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "100%",
                                },
                                my: { xs: 1, sm: 1, md: 0 },
                            }}
                        >
                            <InputLabel
                                id="demo-simple-select-label"
                                sx={{ color: "#bbb", overflow: "visible" }}
                            >
                                Auction Type
                            </InputLabel>
                            <MultipleSelect
                                levels={["Pre Auction", "Post Auction"]}
                                fieldValue={typeFilter}
                                onSelect={setTypeFilter}
                            />
                        </FormControl>
                    </Stack>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        mb: {
                            xs: 1,
                            sm: 3,
                            md: 3,
                        },
                        flexGrow: 1,
                        flexDirection: "row",
                        justifyContent: { xs: "flex-end" },
                        width: {
                            xs: "100%",
                            sm: "100%",
                            md: "100%",
                        },
                    }}
                >
                    <Button
                        variant="outlined"
                        color="error"
                        sx={{
                            marginRight: 1,
                            height: "35px",
                            "&:hover": {
                                color: "#ff4d4f",
                                borderColor: "#ff4d4f",
                            },
                            my: { xs: 1, sm: 1, md: 0 },
                            width: { xs: "95%", sm: "8%", md: "8%" },
                        }}
                        onClick={() => {
                            resetView();
                        }}
                    >
                        Reset
                    </Button>
                    <Button
                        variant="contained"
                        endIcon={<SearchIcon />}
                        sx={{
                            mx: 0,
                            height: "35px",
                            my: { xs: 1, sm: 1, md: 0 },
                            width: { xs: "95%", sm: "95%", md: "12%" },
                        }}
                        onClick={searchButtonHandler}
                    >
                        Search
                    </Button>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        my: 1,
                        flexGrow: 1,
                        flexDirection: {
                            xs: "row",
                            sm: "row",
                            md: "row",
                        },
                        justifyContent: { xs: "flex-start" },
                        width: {
                            xs: "100%",
                            sm: "100%",
                            md: "100%",
                        },
                        paddingRight: "0px",
                    }}
                >
                    {props.pageType && (
                        <SelectDropDown
                            onChange={setSelectedColumns}
                            selectedType="auction"
                            pageType={props.pageType}
                        />
                    )}
                    <CSVLink
                        data={exportData}
                        className="exportButton"
                        filename="BidData.csv"
                        ref={csvLinkRef}
                    ></CSVLink>
                    <Button
                        variant="contained"
                        endIcon={isXs ? undefined : <FileDownloadIcon />}
                        sx={{
                            height: "35px",
                            my: { xs: 1, sm: 1, md: 0 },
                            width: "100px",
                            marginLeft: "5px",
                            marginRight: "max(calc(100% - 265px), 5px)",
                        }}
                        onClick={() => {
                            dataExport();
                        }}
                    >
                        Export
                    </Button>
                    <Box
                        sx={{
                            marginRight: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 0,
                            cursor: isSelected ? "pointer" : "auto",
                            width: "30px",
                            my: { xs: 1, sm: 1, md: 0 },
                            height: "35px",
                            border: isSelected
                                ? "1px solid #1890ff"
                                : "1px solid #d9d9d9",
                        }}
                        borderRadius={1}
                        onClick={() => handleCopy()}
                    >
                        <ContentCopyIcon
                            color={isSelected ? "primary" : "disabled"}
                            sx={{ transform: "scale(0.7)" }}
                        />
                    </Box>
                    <Box
                        sx={{
                            marginRight: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 0,
                            cursor: isSelected ? "pointer" : "auto",
                            width: "30px",
                            my: { xs: 1, sm: 1, md: 0 },
                            height: "35px",
                            border: isSelected
                                ? "1px solid #1890ff"
                                : "1px solid #d9d9d9",
                        }}
                        borderRadius={1}
                        onClick={() =>
                            auctionAction(
                                "Archive",
                                props.pageType === "active" ? true : false,
                            )
                        }
                    >
                        {props.pageType === "active" ? (
                            <ArchiveIcon
                                color={isSelected ? "primary" : "disabled"}
                                sx={{ transform: "scale(0.7)" }}
                            />
                        ) : (
                            <UnarchiveIcon
                                color={isSelected ? "primary" : "disabled"}
                                sx={{ transform: "scale(0.7)" }}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 0,
                            cursor: isSelected ? "pointer" : "auto",
                            width: "30px",
                            my: { xs: 1, sm: 1, md: 0 },
                            height: "35px",
                            border: isSelected
                                ? "1px solid #1890ff"
                                : "1px solid #d9d9d9",
                        }}
                        borderRadius={1}
                        onClick={() => {
                            if (props.pageType === "active") {
                                auctionAction("Pause", true);
                            } else {
                                // setOpenDelete(true);
                            }
                        }}
                    >
                        {props.pageType === "active" ? (
                            <PauseIcon
                                color={isSelected ? "primary" : "disabled"}
                                sx={{ transform: "scale(0.7)" }}
                            />
                        ) : (
                            <DeleteIcon
                                color={"disabled"}
                                sx={{ transform: "scale(0.7)" }}
                            />
                        )}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} style={{ overflowX: "auto" }}>
                <div style={{ width: "100%" }}>
                    <ActionReactTable
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        columns={columns}
                        data={data}
                        setPageIndexProp={setPageIndex}
                        setPageSizeProp={setPageSize}
                        setSortByProp={setSortBy}
                        loading={loading}
                        rowCount={rowCount}
                        selectedRows={selectedRows}
                        setSelectedRowsProp={setSelectedRows}
                        whichPage={props.pageType}
                        handleBidAction={auctionAction}
                        setDeleteConfirmation={setOpenDelete}
                        pageType="auction"
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default AllAuctionsTable;
