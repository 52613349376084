import MainWrapper from "../../components/wrappers/MainWrapper";
import CreateTldForm from "../../forms/CreateTldForm";

const CreateTldPage = () => {
    return (
        <MainWrapper title="Create TLD">
            <CreateTldForm />
        </MainWrapper>
    );
};

export default CreateTldPage;
