import BasicTable from "../../pages/tables/react-table/BasicTable";
import MainCard from "../MainCard";
import ScrollX from "../ScrollX";
import { useMemo } from "react";
import StatusChip from "../common/StatusChip";
import { ChipStatus } from "../../enums/Status";
import { Stack, Box } from "@mui/material";

const BlacklistPopupTable = ({
    data,
    striped,
    title,
}: {
    data: any;
    striped?: boolean;
    title?: string;
}) => {
    const columns = useMemo(
        () => [
            {
                Header: "Domain",
                accessor: "domain",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: ({ value }: any) => (
                    <Box sx={{ textAlign: "center" }}>{value}</Box>
                ),
            },

            {
                Header: "Status",
                accessor: "status",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: ({ value }: any) => (
                    <Stack direction="row" sx={{ justifyContent: "center" }}>
                        {value === "Success" ? (
                            <StatusChip status={ChipStatus.SUCCESS} />
                        ) : (
                            <StatusChip status={ChipStatus.DANGER} />
                        )}
                    </Stack>
                ),
            },
            {
                Header: "Message",
                accessor: "message",
            },
        ],
        [],
    );

    return (
        <MainCard content={false} title={title}>
            <ScrollX>
                <BasicTable columns={columns} data={data} striped={striped} />
            </ScrollX>
        </MainCard>
    );
};

export default BlacklistPopupTable;
