import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
    ErrorSwitchType,
    InputSectionType,
} from "../../types/profileSettingsTypes";
import { Dispatch, SetStateAction } from "react";
import {
    getValue,
    onlyAlpha,
    onlyNumbers,
    trimSpaces,
} from "../../utils/helpers";
import { emailRegex, zipRegex } from "../../data/profileData";

const styles = {
    formControlStyle: {
        my: 1,
        minWidth: 100,
    },
    inputGroup: {
        my: { md: 0, xs: 1 },
        ml: { md: 5, xs: 0 },
    },
};

const ProfileFormSection = ({
    title,
    disabled = false,
    data,
    setData,
    errors,
    setErrors,
    errorsSwitch,
    tag,
}: {
    title: string;
    disabled?: boolean;
    data: InputSectionType;
    setData: Dispatch<SetStateAction<InputSectionType>>;
    errors: InputSectionType;
    setErrors: Dispatch<SetStateAction<InputSectionType>>;
    errorsSwitch: ErrorSwitchType;
    tag: "ownerInfo" | "adminInfo" | "technicalInfo" | "billingInfo";
}) => {
    const ErrorMessage = ({ children }: { children: string }) =>
        errorsSwitch[tag] ? (
            <FormHelperText sx={{ mx: 0 }} error>
                {children}
            </FormHelperText>
        ) : null;
    return (
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography
                sx={{
                    fontSize: { sm: "18px", xs: "12px" },
                    fontWeight: 600,
                    color: "#3A3541CC",
                    mr: 1,
                    mb: 2,
                }}
            >
                {title}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <Stack>
                        <InputLabel>Name</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <TextField
                                fullWidth
                                id="name"
                                name="name"
                                disabled={disabled}
                                onChange={(e) => {
                                    const value = getValue(
                                        onlyAlpha(e.target.value),
                                    );
                                    setData((data) => ({
                                        ...data,
                                        name: value,
                                    }));
                                    if (value === "")
                                        setErrors((errors) => ({
                                            ...errors,
                                            name: "Required Field",
                                        }));
                                    else {
                                        setErrors((errors) => ({
                                            ...errors,
                                            name: "",
                                        }));
                                    }
                                }}
                                error={
                                    errorsSwitch[tag] && errors.name
                                        ? true
                                        : false
                                }
                                value={data.name}
                            />
                            <ErrorMessage>{errors.name}</ErrorMessage>
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Stack>
                        <InputLabel>Company</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <TextField
                                fullWidth
                                id="company"
                                name="company"
                                disabled={disabled}
                                onChange={(e) => {
                                    const value = getValue(e.target.value);
                                    setData((data) => ({
                                        ...data,
                                        company: value,
                                    }));
                                    if (value === "")
                                        setErrors((errors) => ({
                                            ...errors,
                                            company: "Required Field",
                                        }));
                                    else
                                        setErrors((errors) => ({
                                            ...errors,
                                            company: "",
                                        }));
                                }}
                                value={data.company}
                                error={
                                    errorsSwitch[tag] && errors.company
                                        ? true
                                        : false
                                }
                            />
                            <ErrorMessage>{errors.company}</ErrorMessage>
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Stack>
                        <InputLabel>Street</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <TextField
                                fullWidth
                                id="street"
                                name="street"
                                disabled={disabled}
                                onChange={(e) => {
                                    const value = getValue(e.target.value);
                                    setData((data) => ({
                                        ...data,
                                        street: value,
                                    }));
                                    if (value === "")
                                        setErrors((errors) => ({
                                            ...errors,
                                            street: "Required Field",
                                        }));
                                    else
                                        setErrors((errors) => ({
                                            ...errors,
                                            street: "",
                                        }));
                                }}
                                value={data.street}
                                error={
                                    errorsSwitch[tag] && errors.street
                                        ? true
                                        : false
                                }
                            />
                            <ErrorMessage>{errors.street}</ErrorMessage>
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Stack>
                        <InputLabel>City</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <TextField
                                fullWidth
                                id="city"
                                name="city"
                                disabled={disabled}
                                onChange={(e) => {
                                    const value = getValue(e.target.value);
                                    setData((data) => ({
                                        ...data,
                                        city: value,
                                    }));
                                    if (value === "")
                                        setErrors((errors) => ({
                                            ...errors,
                                            city: "Required Field",
                                        }));
                                    else
                                        setErrors((errors) => ({
                                            ...errors,
                                            city: "",
                                        }));
                                }}
                                value={data.city}
                                error={
                                    errorsSwitch[tag] && errors.city
                                        ? true
                                        : false
                                }
                            />
                            <ErrorMessage>{errors.city}</ErrorMessage>
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Stack>
                        <InputLabel>State</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <TextField
                                fullWidth
                                id="state"
                                name="state"
                                disabled={disabled}
                                onChange={(e) => {
                                    const value = getValue(e.target.value);
                                    setData((data) => ({
                                        ...data,
                                        state: value,
                                    }));
                                    if (value === "")
                                        setErrors((errors) => ({
                                            ...errors,
                                            state: "Required Field",
                                        }));
                                    else
                                        setErrors((errors) => ({
                                            ...errors,
                                            state: "",
                                        }));
                                }}
                                value={data.state}
                                error={
                                    errorsSwitch[tag] && errors.state
                                        ? true
                                        : false
                                }
                            />
                            <ErrorMessage>{errors.state}</ErrorMessage>
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Stack>
                        <InputLabel>Country</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <TextField
                                fullWidth
                                id="country"
                                name="country"
                                disabled={disabled}
                                onChange={(e) => {
                                    const value = getValue(
                                        onlyAlpha(e.target.value),
                                    );
                                    setData((data) => ({
                                        ...data,
                                        country: value,
                                    }));
                                    if (value === "")
                                        setErrors((errors) => ({
                                            ...errors,
                                            country: "Required Field",
                                        }));
                                    else
                                        setErrors((errors) => ({
                                            ...errors,
                                            country: "",
                                        }));
                                }}
                                value={data.country}
                                error={
                                    errorsSwitch[tag] && errors.country
                                        ? true
                                        : false
                                }
                            />
                            <ErrorMessage>{errors.country}</ErrorMessage>
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Stack>
                        <InputLabel>ZIP</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <TextField
                                fullWidth
                                id="zip"
                                name="zip"
                                disabled={disabled}
                                onChange={(e) => {
                                    const value = trimSpaces(
                                        onlyNumbers(getValue(e.target.value)),
                                    );
                                    setData((data) => ({
                                        ...data,
                                        zip: value,
                                    }));
                                    if (value === "")
                                        setErrors((errors) => ({
                                            ...errors,
                                            zip: "Required Field",
                                        }));
                                    else {
                                        if (!zipRegex.test(value))
                                            setErrors((errors) => ({
                                                ...errors,
                                                zip: "Invalid Zip",
                                            }));
                                        else
                                            setErrors((errors) => ({
                                                ...errors,
                                                zip: "",
                                            }));
                                    }
                                }}
                                value={data.zip}
                                error={
                                    errorsSwitch[tag] && errors.zip
                                        ? true
                                        : false
                                }
                            />
                            <ErrorMessage>{errors.zip}</ErrorMessage>
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item md={4} sm={6} xs={12}>
                    <Stack>
                        <InputLabel>Phone</InputLabel>
                        <PhoneInput
                            disabled={disabled}
                            value={data.phone}
                            numberInputProps={{
                                style: {
                                    padding: "11.5px",
                                    borderRadius: "4px",
                                    borderColor:
                                        errorsSwitch[tag] && errors.phone
                                            ? "#FF4D4F"
                                            : "#00000040",
                                    borderWidth: "1px",
                                    backgroundColor: "transparent",
                                    fontFamily: "Public Sans",
                                    color: "#3A3541CC",
                                    fontSize: "14px",
                                },
                                class:
                                    errorsSwitch[tag] && errors.phone
                                        ? "PhoneInputInput phoneInputError"
                                        : "PhoneInputInput",
                            }}
                            style={{ marginTop: "9px" }}
                            onChange={(e: any) => {
                                const value = e;
                                try {
                                    setData((data) => ({
                                        ...data,
                                        phone: value,
                                    }));
                                    if (value === "")
                                        setErrors((errors) => ({
                                            ...errors,
                                            phone: "Required Field",
                                        }));
                                    else {
                                        if (!isValidPhoneNumber(value))
                                            setErrors((errors) => ({
                                                ...errors,
                                                phone: "Invalid Phone",
                                            }));
                                        else
                                            setErrors((errors) => ({
                                                ...errors,
                                                phone: "",
                                            }));
                                    }
                                } catch (error) {
                                    setData((data) => ({
                                        ...data,
                                        phone: "",
                                    }));
                                    setErrors((errors) => ({
                                        ...errors,
                                        phone: "Required Field",
                                    }));
                                }
                            }}
                        />
                        <ErrorMessage>{errors.phone}</ErrorMessage>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Stack>
                        <InputLabel>Email</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <TextField
                                fullWidth
                                id="email"
                                name="email"
                                disabled={disabled}
                                onChange={(e) => {
                                    const value = trimSpaces(
                                        getValue(e.target.value),
                                    );
                                    setData((data) => ({
                                        ...data,
                                        email: value,
                                    }));
                                    if (value === "")
                                        setErrors((errors) => ({
                                            ...errors,
                                            email: "Required Field",
                                        }));
                                    else {
                                        if (!emailRegex.test(value))
                                            setErrors((errors) => ({
                                                ...errors,
                                                email: "Invalid Email",
                                            }));
                                        else
                                            setErrors((errors) => ({
                                                ...errors,
                                                email: "",
                                            }));
                                    }
                                }}
                                error={
                                    errorsSwitch[tag] && errors.email
                                        ? true
                                        : false
                                }
                                value={data.email}
                            />
                            <ErrorMessage>{errors.email}</ErrorMessage>
                        </FormControl>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProfileFormSection;
