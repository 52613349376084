import { useEffect, useMemo, useState } from "react";

import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";

import ScrollX from "../../components/ScrollX";
import ReactTable from "./react-table/ReactTable";
import { usersTableDataType } from "../../types/tableTypes";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import { Link } from "react-router-dom";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoCloseCircleOutline } from "react-icons/io5";
import { DateTime } from "luxon";
import { UserRole } from "../../enums/Auth";
import UsersTableMenu from "../../components/popups/UsersTableMenu";

interface RequestType {
    page: number;
    size: number;
    filterAnd: any[];
    sort: {
        key: string;
        order: "ASC" | "DESC";
    };
}

function cellStatusChip({ value }: any) {
    if (!value)
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <IoIosCheckmarkCircleOutline
                    style={{ color: "#3BB800", fontSize: "18px" }}
                />
            </Box>
        );
    else
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <IoCloseCircleOutline
                    style={{ color: "#FF4D4F", fontSize: "18px" }}
                />
            </Box>
        );
}

function cellTrustedChip({ value }: any) {
    if (value)
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <IoIosCheckmarkCircleOutline
                    style={{ color: "#3BB800", fontSize: "18px" }}
                />
            </Box>
        );
    else
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <IoCloseCircleOutline
                    style={{ color: "#FF4D4F", fontSize: "18px" }}
                />
            </Box>
        );
}

function cellRoleIcon({ value }: any) {
    if (value === UserRole.ADMIN)
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <IoIosCheckmarkCircleOutline
                    style={{ color: "#3BB800", fontSize: "18px" }}
                />
            </Box>
        );
    else
        return (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
                <IoCloseCircleOutline
                    style={{ color: "#FF4D4F", fontSize: "18px" }}
                />
            </Box>
        );
}

function cellActionMenu({ value }: any) {
    return <UsersTableMenu value={value} />;
}

function cellLink({ value }: any) {
    if (value) {
        return (
            <Link
                style={{ textDecoration: "none", color: "#1890FF" }}
                to={`/admin/users/${value.trim()}`}
            >
                {value}
            </Link>
        );
    } else {
        return <></>;
    }
}

function cellDateAndTime({ value }: any) {
    if (!value) return <></>;
    else {
        const dateTimeFromEpoch = DateTime.fromSeconds(value);
        return <>{dateTimeFromEpoch.toFormat("yyyy-MM-dd HH:mm:ss")}</>;
    }
}

const UsersTable = () => {
    const { postData } = useData();
    const { showError } = useMessage();
    const [data, setData] = useState<usersTableDataType[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<{
        id: string;
        desc: boolean;
    }>({
        id: "created",
        desc: true,
    });

    // Filters & searches
    const [username, setUsername] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [status, setStatus] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);
    const [shouldReload, setShouldReload] = useState<number>(Date.now());

    const fetchData = async () => {
        try {
            setLoading(true);

            const userData: RequestType = {
                page: pageIndex + 1,
                size: pageSize,
                filterAnd: [],
                sort: {
                    key: sortBy.id,
                    order: sortBy.desc ? "DESC" : "ASC",
                },
            };

            if (username !== "") {
                userData.filterAnd.push({
                    key: "username",
                    operation: "LIKE",
                    value: `%${username}%`,
                });
            }

            if (email !== "") {
                userData.filterAnd.push({
                    key: "email",
                    operation: "LIKE",
                    value: `%${email}%`,
                });
            }

            if (status !== "") {
                userData.filterAnd.push({
                    key: "isDisabled",
                    operation: "=",
                    value: status === "true" ? true : false,
                });
            }

            const response: any = await postData(
                "/users/getUsersData",
                userData,
            );

            if (response?.data) {
                setData(
                    response.data.map((item: usersTableDataType) => ({
                        ...item,
                        action: item.username,
                    })),
                );
            } else {
                setData([]);
            }
            setRowCount(response?.count ? response?.count : 0);
        } catch (error: any) {
            console.log("User Data Retrieval failed with error", error);
            showError("Data Fetching Error");
        } finally {
            setLoading(false);
        }
    };

    const resetPageConfig = async () => {
        setPageIndex(0);
        setPageSize(10);
    };

    useEffect(() => {
        setPageIndex(0);
        setShouldReload(Date.now());
    }, [pageSize]);

    useEffect(() => {
        fetchData();
    }, [pageIndex, shouldReload]);

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                disableSortBy: true,
            },
            {
                Header: "Username",
                accessor: "username",
                Cell: cellLink,
                disableSortBy: true,
            },
            {
                Header: "Email",
                accessor: "email",
                disableSortBy: true,
            },
            {
                Header: "Status",
                accessor: "isDisabled",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: cellStatusChip,
                disableSortBy: true,
            },
            {
                Header: "Created",
                accessor: "created",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: cellDateAndTime,
            },
            {
                Header: "Modified",
                accessor: "modified",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: cellDateAndTime,
            },
            {
                Header: "Last Login",
                accessor: "lastLoggedIn",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: cellDateAndTime,
            },
            {
                Header: "Trusted",
                accessor: "isTrusted",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: cellTrustedChip,
                className: "cell-middle",
                disableSortBy: true,
            },
            {
                Header: "Admin",
                accessor: "role",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: cellRoleIcon,
                disableSortBy: true,
            },
            {
                Header: "Action",
                accessor: "action",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: cellActionMenu,
                disableSortBy: true,
            },
        ],
        [],
    );

    return (
        <Grid container>
            {/* Filter Section */}
            <Box
                sx={{
                    mb: 4,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column-reverse", sm: "row" },
                    flexGrow: 1,
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        my: 1,
                        flexGrow: 1,
                        justifyContent: { xs: "flex-end", sm: "flex-end" },
                        flexDirection: { xs: "column-reverse", sm: "row" },
                        alignItems: "center",
                        width: "100%",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            my: 1,
                            pr: { xs: 0, sm: 1 },
                            flexGrow: 1,
                            flexDirection: { xs: "column", sm: "row" },
                            width: "100%",
                        }}
                    >
                        <TextField
                            placeholder="Username"
                            sx={{ mr: { xs: 0, sm: 1 }, my: { xs: 1, sm: 0 } }}
                            value={username}
                            inputProps={{
                                style: {
                                    padding: "10px 8px",
                                },
                            }}
                            onKeyDown={(e) => {
                                if (e.code == "Enter") {
                                    resetPageConfig();
                                    setShouldReload(Date.now());
                                }
                            }}
                            onChange={(e) => {
                                setUsername(e.target.value.trim());
                            }}
                        />
                        <TextField
                            placeholder="Email"
                            sx={{
                                mx: { xs: 0, sm: 1 },
                            }}
                            value={email}
                            onKeyDown={(e) => {
                                if (e.code == "Enter") {
                                    resetPageConfig();
                                    setShouldReload(Date.now());
                                }
                            }}
                            onChange={(e) => {
                                setEmail(e.target.value.toLowerCase().trim());
                            }}
                            inputProps={{
                                style: {
                                    padding: "10px 8px",
                                },
                            }}
                        />
                        <FormControl
                            sx={{
                                width: { xs: "100%", sm: "200px" },
                                my: { xs: 1, sm: 0 },
                                mx: { xs: 0, sm: 1 },
                            }}
                        >
                            <InputLabel
                                id="demo-simple-select-label"
                                sx={{ color: "#bbb" }}
                            >
                                Status
                            </InputLabel>
                            <Select
                                variant="outlined"
                                value={status}
                                onChange={(e) => {
                                    setStatus(e.target.value);
                                }}
                                onKeyDown={(e) => {
                                    if (e.code == "Enter") {
                                        e.stopPropagation();
                                    }
                                }}
                                sx={{
                                    width: { xs: "100%", sm: "180px" },
                                    height: "40px",
                                }}
                                SelectDisplayProps={{
                                    onKeyDown: (e: any) => {
                                        if (e.code == "Enter") {
                                            resetPageConfig();
                                            setShouldReload(Date.now());
                                        }
                                    },
                                }}
                                label="Status"
                                autoWidth
                                placeholder="Status"
                            >
                                <MenuItem
                                    value="false"
                                    sx={{
                                        width: { xs: "100%", sm: "180px" },
                                    }}
                                >
                                    Active
                                </MenuItem>
                                <MenuItem
                                    value="true"
                                    sx={{
                                        width: { xs: "100%", sm: "150px" },
                                        pr: 16,
                                    }}
                                >
                                    Inactive
                                </MenuItem>
                            </Select>
                        </FormControl>
                        <Button
                            variant="outlined"
                            color="error"
                            sx={{
                                mx: { xs: 0, sm: 1 },
                                height: "40px",
                                "&:hover": {
                                    color: "#ff4d4f",
                                    borderColor: "#ff4d4f",
                                },
                            }}
                            onClick={() => {
                                setUsername("");
                                setEmail("");
                                setStatus("");
                                setSortBy({
                                    id: "created",
                                    desc: true,
                                });
                                resetPageConfig();
                                setShouldReload(Date.now());
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            variant="contained"
                            endIcon={<SearchIcon />}
                            sx={{
                                mx: { xs: 0, sm: 1 },
                                height: "40px",
                                my: { xs: 1, sm: 0 },
                            }}
                            onClick={() => {
                                resetPageConfig();
                                setShouldReload(Date.now());
                            }}
                            onKeyDown={(e) => {
                                if (e.code == "Enter") {
                                    resetPageConfig();
                                    setShouldReload(Date.now());
                                }
                            }}
                        >
                            Search
                        </Button>
                    </Box>
                    <Stack sx={{ width: { xs: "100%", sm: "auto" } }}>
                        <Link
                            to={"/admin/users/create"}
                            style={{
                                display: "flex",
                                textDecoration: "none",
                                width: "100%",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                variant="contained"
                                endIcon={<AddIcon />}
                                sx={{
                                    ml: { sm: 1, xs: 0 },
                                    height: "40px",
                                    whiteSpace: "nowrap",
                                    alignSelf: "flex-end",
                                    width: { xs: "100%", sm: "auto" },
                                }}
                            >
                                Add User
                            </Button>
                        </Link>
                    </Stack>
                </Box>
            </Box>

            {/* Table Section */}
            <Grid item xs={12}>
                <ScrollX>
                    <ReactTable
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        columns={columns}
                        data={data}
                        setPageIndexProp={setPageIndex}
                        setPageSizeProp={setPageSize}
                        setSortByProp={setSortBy}
                        loading={loading}
                        rowCount={rowCount}
                    />
                </ScrollX>
            </Grid>
        </Grid>
    );
};

export default UsersTable;
