// ==============================|| OVERRIDES - BUTTON ||============================== //

export default function ButtonGroup() {
    return {
        MuiButtonGroup: {
            defaultProps: {
                disableRipple: true,
            },
        },
    };
}
