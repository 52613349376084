export const bidColumnMapping: { [key: string]: string } = {
    Domain: "Domain",
    Username: "Username",
    Registry: "Registry",
    Priority: "Priority",
    Bid: "Bid ($)",
    Status: "Status",
    dropTime: "Drop Time",
    auctionEnd: "Auction End",
    Bidders: "Bidders",
};

export const auctionColMapping: { [key: string]: string } = {
    auctionId: "Auction ID",
    domainName: "Domain Name",
    auctionType: "Auction Type",
    auctionStart: "Auction Start Time",
    auctionEnd: "Auction End Time",
    auctionStatus: "Auction Status",
};

export const auctionArchiveColMapping: { [key: string]: string } = {
    ...auctionColMapping,
    winningPrice: "Winning Price ($)",
    numberOfWinners: "Winners",
    domainStatus: "Domain Status",
};

export type stringKeyDict = { [key: string]: string };
