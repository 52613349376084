import {
    Box,
    Button,
    InputLabel,
    FormControl,
    Grid,
    TextField,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useMemo, useState } from "react";
import ScrollX from "../../components/ScrollX";
import MultipleSelect from "../../components/filters/MulipleSelect";
import ReactTable from "./react-table/ReactTable";
import { Link } from "react-router-dom";
import useMessage from "../../hooks/useMessage";
import { tldTableDataType } from "../../types/tableTypes";
import {
    cellBoolean,
    cellUpdateTld,
    cellDeleteIcon,
    cellCenterAlign,
    cellPreviewBalance,
} from "./CellFormatters";
import useData from "../../hooks/useApi";
import ConfirmationBox from "../../components/common/AlertBoxes/ConfirmationBox";
import sortArray from "../../utils/inPageSorting";

const TldTable = () => {
    const { showSuccess, showError } = useMessage();
    const { fetchData, postData } = useData();

    const [registries, setRegistries] = useState<string[]>([]);
    const [data, setData] = useState<tldTableDataType[]>([]);
    const [pageData, setPageData] = useState<tldTableDataType[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [rowCount, setRowCount] = useState<number>(0);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [sortBy, setSortBy] = useState<{
        id: string;
        desc: boolean;
    }>({
        id: "name",
        desc: true,
    });

    const [alert, setAlert] = useState<boolean>(false);
    const [tldId, setTldId] = useState<number | null>(null);
    const [tldName, setTldName] = useState<string | null>(null);

    const [loading, setLoading] = useState<boolean>(false);

    // Filters & searches
    const [nameFilter, setNameFilter] = useState<string>("");
    const [registryFilter, setRegistryFilter] = useState<string[]>([]);
    const [calculableFilter, setCalculableFilter] = useState<string[]>([]);

    const adjustPageData = () => {
        const cnd1 = (obj: tldTableDataType) => {
            if (nameFilter.length === 0) {
                return true;
            } else {
                return obj.name.startsWith(nameFilter);
            }
        };
        const cnd2 = (obj: tldTableDataType) => {
            if (registryFilter.length === 0) {
                return true;
            } else {
                return registryFilter.includes(obj.registry);
            }
        };
        const cnd3 = (obj: tldTableDataType) => {
            if (calculableFilter.length === 0) {
                return true;
            } else {
                if (obj.calculable) {
                    return calculableFilter.includes("Yes");
                } else {
                    return calculableFilter.includes("No");
                }
            }
        };

        const filteredObjects = data.filter(
            (obj) => cnd1(obj) && cnd2(obj) && cnd3(obj),
        );

        setRowCount(filteredObjects ? filteredObjects.length : 0);

        const isNumber = ["successFee", "registrarPrice[0].price"].includes(
            sortBy.id,
        );

        const sortedData = sortArray(
            filteredObjects,
            sortBy.id,
            sortBy.desc,
            isNumber,
        );

        const startFromIndex: number = pageIndex * pageSize;
        setPageData(
            sortedData.slice(startFromIndex, startFromIndex + pageSize),
        );
    };

    const loadRegistries = async () => {
        try {
            const response: any = await fetchData("/registry");
            if (response?.data) {
                setRegistries(
                    Array.from(
                        response.data.map(
                            (singleRegistry: any) => singleRegistry.registry,
                        ),
                    ),
                );
            } else {
                setRegistries([]);
            }
        } catch (error) {
            setRegistries([]);
        }
    };

    const loadTldData = async () => {
        setLoading(true);
        try {
            const response: any = await fetchData("/tld");
            if (response?.data) {
                setData(response.data);
            } else {
                setData([]);
            }
            setRowCount(response?.data ? response.data.length : 0);
        } catch (error) {
            showError("Data Fetching Error");
        }
        setLoading(false);
    };

    async function deleteTLD() {
        setAlert(false);
        try {
            const payload = { tlds: [tldName] };
            const response: any = await postData("/tld/deleteBulk", payload);
            if (response.success && response.data.failed.length === 0) {
                loadTldData();
                showSuccess(`'${tldName}' removed from TLDs`);
            } else {
                showError("Failed to remove the TLD");
            }
        } catch (error) {
            console.log(error);
            showError("Failed to remove the TLD");
        }
    }

    const openAlertBox = (tldId: string) => {
        setTldId(parseInt(tldId));
        setAlert(true);
    };

    useEffect(() => {
        const tldData = pageData.find((obj) => obj.id === tldId);
        if (tldData != undefined) {
            setTldName(tldData?.name);
        }
    }, [tldId]);

    useEffect(() => {
        loadRegistries();
        loadTldData();
    }, []);

    useEffect(() => {
        setPageIndex(0);
        adjustPageData();
    }, [data, nameFilter, registryFilter, calculableFilter, pageSize]);

    useEffect(() => {
        adjustPageData();
    }, [sortBy, pageIndex]);

    const columns = useMemo(
        () => [
            {
                Header: "Name",
                accessor: "name",
                canSort: true,
                Cell: cellUpdateTld,
            },
            {
                Header: "Droptime Start",
                accessor: "dropTimeStart",
                canSort: true,
            },
            {
                Header: "Droptime End",
                accessor: "dropTimeEnd",
                canSort: true,
            },
            {
                Header: "Registry",
                accessor: "registry",
                disableSortBy: true,
                Cell: cellCenterAlign,
            },
            {
                Header: "Before Drop Status",
                accessor: "beforeDropStatus",
                disableSortBy: true,
                Cell: cellCenterAlign,
            },
            {
                Header: "Calculable",
                accessor: "calculable",
                disableSortBy: true,
                Cell: cellBoolean,
            },
            {
                Header: "Success Fee ($)",
                accessor: "successFee",
                canSort: true,
                Cell: cellPreviewBalance,
            },
            {
                Header: "Reg Fee ($)",
                accessor: "registrarPrice[0].price",
                canSort: true,
                Cell: cellPreviewBalance,
            },
            {
                Header: "Action",
                accessor: "id",
                disableSortBy: true,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: ({ value }: { value: string }) =>
                    cellDeleteIcon({ value, openAlertBox }),
            },
        ],
        [],
    );

    return (
        <Grid container>
            <ConfirmationBox
                title={`Confirm removing ${tldName} from TLDs`}
                subtitle="This action cannot be undone"
                open={alert}
                setOpen={setAlert}
                triggerFunction={deleteTLD}
                triggerValue={tldName}
            />
            <Box
                sx={{
                    mb: 4,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column-reverse", sm: "row" },
                    flexGrow: 1,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        my: 1,
                        flexGrow: 1,
                        justifyContent: { xs: "flex-end", sm: "flex-end" },
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            my: 1,
                            flexGrow: 1,
                            flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "row",
                            },
                            justifyContent: { xs: "flex-start" },
                        }}
                    >
                        <TextField
                            placeholder="Name"
                            sx={{
                                marginRight: { xs: 0, sm: 0, md: 1 },
                                my: { xs: 1, sm: 1, md: 0 },
                                width: { xs: "100%", sm: "100%", md: "25%" },
                            }}
                            value={nameFilter}
                            onKeyDown={(event) => {
                                if (event.key === " ") {
                                    event.preventDefault();
                                }
                            }}
                            onChange={(e) => {
                                setNameFilter(e.target.value.toLowerCase());
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormControl
                            sx={{
                                width: { xs: "100%", sm: "100%", md: "25%" },
                                my: { xs: 1, sm: 1, md: 0 },
                                marginRight: { xs: 0, sm: 0, md: 1 },
                            }}
                        >
                            <InputLabel
                                id="demo-simple-select-label"
                                sx={{
                                    color: "#bbb",
                                    padding: "0px",
                                    height: "20px",
                                }}
                            >
                                {"Registry"}
                            </InputLabel>
                            <MultipleSelect
                                levels={registries}
                                fieldValue={registryFilter}
                                onSelect={setRegistryFilter}
                            />
                        </FormControl>

                        <FormControl
                            sx={{
                                width: { xs: "100%", sm: "100%", md: "25%" },
                                my: { xs: 1, sm: 1, md: 0 },
                                marginRight: { xs: 0, sm: 0, md: 1 },
                            }}
                        >
                            <InputLabel
                                id="demo-simple-select-label"
                                sx={{ color: "#bbb" }}
                            >
                                Calculable
                            </InputLabel>
                            <MultipleSelect
                                levels={["Yes", "No"]}
                                fieldValue={calculableFilter}
                                onSelect={setCalculableFilter}
                            />
                        </FormControl>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: { xs: "flex-end" },
                                width: { xs: "100%", sm: "100%", md: "25%" },
                            }}
                        >
                            <Link to={"/admin/tlds/create"}>
                                <Button
                                    variant="contained"
                                    endIcon={<AddIcon />}
                                    sx={{
                                        height: "41.13px",
                                        whiteSpace: "nowrap",
                                    }}
                                >
                                    Add TLD
                                </Button>
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Grid item xs={12}>
                <ScrollX>
                    <ReactTable
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        columns={columns}
                        data={pageData}
                        setPageIndexProp={setPageIndex}
                        setPageSizeProp={setPageSize}
                        setSortByProp={setSortBy}
                        loading={loading}
                        rowCount={rowCount}
                    />
                </ScrollX>
            </Grid>
        </Grid>
    );
};

export default TldTable;
