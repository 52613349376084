import MainWrapper from "../../components/wrappers/MainWrapper";
import { CircularProgress, Grid } from "@mui/material";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuctionHeading from "../../components/auction/AuctionHeading";
import AuctionInfo from "../../components/auction/AuctionInfo";
import { auctionDetailsType } from "../../types/pageTypes";
import PreAuctionViewTable from "../tables/PerAuctionViewTable";

const PerAuctionView = () => {
    const { fetchData } = useData();
    const { showError } = useMessage();
    const [loading, setLoading] = useState<boolean>(false);
    const params = useParams();
    const navigate = useNavigate();
    const [auctionInfo, setAuctionInfo] = useState<auctionDetailsType>(
        {} as auctionDetailsType,
    );

    const getAuctionData = async () => {
        try {
            if (params && params.auctionId) {
                const response: any = await fetchData(
                    `/auction/${params.auctionId}`,
                );
                if (response.data) {
                    setAuctionInfo(response.data);
                    setLoading(true);
                } else {
                    showError("Server Error");
                }
            } else {
                navigate("allAuction");
            }
        } catch (e: any) {
            if (e.response?.status === 400) {
                showError("Error while retrieving data");
            } else {
                showError("Internal Server Error");
            }
        }
    };

    useEffect(() => {
        getAuctionData();
    }, [params]);

    return (
        <>
            <Grid
                container
                justifyContent="center"
                px={"50%"}
                py={"20%"}
                display={loading ? "none" : "block"}
            >
                <CircularProgress />
            </Grid>
            <Grid
                container
                direction={"column"}
                rowSpacing={2}
                display={!loading ? "none" : "block"}
            >
                <Grid item xs={12}>
                    {auctionInfo ? (
                        <MainWrapper
                            title="Auction Info"
                            topElement={
                                <AuctionHeading
                                    name={auctionInfo.domainName}
                                    id={auctionInfo.auctionId}
                                    type={auctionInfo.auctionType}
                                />
                            }
                        >
                            {auctionInfo && (
                                <AuctionInfo response={auctionInfo} />
                            )}
                        </MainWrapper>
                    ) : (
                        <CircularProgress />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <MainWrapper title="Bid Info">
                        <PreAuctionViewTable
                            auctionId={auctionInfo.auctionId}
                        />
                    </MainWrapper>
                </Grid>
            </Grid>
        </>
    );
};

export default PerAuctionView;
