import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import { MessageProvider } from "./contexts/MessageContext";
import { DataProvider } from "./contexts/DataContext";
import { Provider as ReduxProvider } from "react-redux";
import { ConfigProvider } from "./contexts/ConfigContext";
import Locales from "./components/Locales";
import ThemeCustomization from "./themes";
import { store } from "./store";
import { CurrentRouteProvider } from "./contexts/CurrentRouteContext";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);

root.render(
    <React.StrictMode>
        <ReduxProvider store={store}>
            <ConfigProvider>
                <BrowserRouter>
                    <CurrentRouteProvider>
                        <ThemeCustomization>
                            <Locales>
                                <MessageProvider>
                                    <AuthProvider>
                                        <DataProvider>
                                            <App />
                                        </DataProvider>
                                    </AuthProvider>
                                </MessageProvider>
                            </Locales>
                        </ThemeCustomization>
                    </CurrentRouteProvider>
                </BrowserRouter>
            </ConfigProvider>
        </ReduxProvider>
    </React.StrictMode>,
);
