import jwtDecode from "jwt-decode";
import api from "../configs/apiConfig";
import axios from "axios";

export const getToken = () => {
    const token = sessionStorage.getItem("authToken");
    if (token) {
        return token;
    }
    return null;
};

// verify the token via jwt-decode
export const verifyToken = (token: string) => {
    const decodedToken: any = jwtDecode(token);
    if (decodedToken && decodedToken?.exp)
        return decodedToken.exp > Date.now() / 1000;
    return false;
};

// set or remove JWT token session
export const setJwtSession = (mode: "SET" | "REM", token?: string) => {
    if (mode === "SET" && token) {
        sessionStorage.setItem("authToken", token);
        api.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
        sessionStorage.removeItem("authToken");
        delete api.defaults.headers.common.Authorization;
    }
};

export const setRefreshToken = (mode: "SET" | "REM", token?: string) => {
    if (mode === "SET" && token) {
        sessionStorage.setItem("refreshToken", token);
    } else {
        sessionStorage.removeItem("refreshToken");
    }
};

export const getRefreshToken = async (username: string) => {
    const refreshToken = sessionStorage.getItem("refreshToken");
    if (refreshToken) {
        try {
            const result = await axios.post(
                `${process.env.REACT_APP_BACKEND_URL}/token`,
                {
                    username,
                    refreshToken,
                },
            );
            if (result.data) {
                setJwtSession("SET", result.data.accessToken);
                return true;
            } else {
                return false;
            }
        } catch (error) {
            return false;
        }
    } else {
        return false;
    }
};
