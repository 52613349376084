import { DateTime } from "luxon";

export function generatePassword(length: number) {
    const allChars = [
        "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
        "abcdefghijklmnopqrstuvwxyz",
        "0123456789",
        "!@#$%^&*()-+<>/[]{}|?\\",
    ];
    let password = "";
    for (let index = 0; index < length; index++) {
        const rand = Math.floor(Math.random() * allChars.length);
        const charRand = Math.floor(Math.random() * allChars[rand].length);
        password += allChars[rand][charRand];
    }
    return password;
}
export const trimSpaces = (data: string) => data.replace(/^\s+|\s+$/g, "");
export const onlyNumbers = (data: string) => data.replace(/[^0-9]/g, "");
export const onlyOneSpace = (data: string) => data.replace(/\s+/g, " ");
export const onlyWord = (data: string) => data.replace(/[^\w ]/g, "");
export const onlyAlpha = (data: string) => data.replace(/[^A-Za-z ]/g, "");
export function getValue(value: string) {
    if (value.match(/^[ ]*$/)) {
        return "";
    } else {
        return value;
    }
}

export function formatWhoisDateString(dateString: string | null) {
    //dateString = "2023-07-28T112713";
    //newDateString = "2023-07-28T11:27:13Z"

    try {
        const preProcessed =
            dateString?.slice(0, 13) +
            ":" +
            dateString?.slice(13, 15) +
            ":" +
            dateString?.slice(15, 17).concat("Z");

        let formatted = preProcessed
            ? DateTime.fromSeconds(
                  Math.round(Date.parse(preProcessed) / 1000),
              ).toISO()
            : null;

        if (dateString != null && formatted === null) {
            formatted = DateTime.fromSeconds(
                Math.round(Date.parse(dateString) / 1000),
            ).toISO();
        }

        return formatted;
    } catch (e) {
        return null;
    }
}

export function getCurrentTime() {
    return DateTime.utc().toISO();
}

export function calculateDateDiff(date1: string | null, date2: string | null) {
    try {
        if (date1 === null || date2 === null) return null;

        const date1Formatted = DateTime.fromISO(date1);
        const date2Formatted = DateTime.fromISO(date2);
        const diff = date1Formatted.diff(date2Formatted, [
            "years",
            "months",
            "days",
        ]);

        if (diff.years.toString() === "NaN") return null;
        if (diff.years < 0 || diff.months < 0 || diff.days < 0) return null;

        const date =
            diff.years +
            " Years " +
            diff.months +
            " Months " +
            Math.round(diff.days) +
            " Days ";
        return date;
    } catch (e) {
        return null;
    }
}

export function epochSecondsToDateConverter(epoch: number) {
    try {
        const dateTimeFromEpoch = DateTime.fromSeconds(epoch);
        return dateTimeFromEpoch.toFormat("yyyy-MM-dd");
    } catch (e) {
        return null;
    }
}

export function capitalizeFirstLetters(str: string) {
    return str
        .toLowerCase()
        .split(" ")
        .map((word: any) => word[0].toUpperCase() + word.substring(1))
        .join(" ");
}

export function getCurrenTimeInSec() {
    return Math.round(DateTime.utc().toSeconds());
}

export function isLockedCheck(dropTime: number): boolean {
    return dropTime < getAuctionProcessingEnd();
}

export function getAuctionProcessingEnd(): number {
    return getCurrenTimeInSec() + 3600;
}

export const onlyNumbersWithDot = (data: string) => {
    let result = data.replace(/[^0-9.]/g, "").replace(/\\/g, "");

    const dotIndex = result.indexOf(".");
    if (dotIndex !== -1) {
        result =
            result.substring(0, dotIndex + 1) +
            result.substring(dotIndex + 1).replace(/\./g, "");
    }

    if (result.startsWith(".")) {
        result = "0" + result;
    }
    return result;
};

export const numbersWithTwoDecimals = (data: string) => {
    // Allow only numbers, a dot, and a single leading minus sign
    let result = data.replace(/[^0-9.-]/g, "");

    // Ensure the minus sign is at the beginning (if present)
    if (result.includes("-")) {
        result = "-" + result.replace(/-/g, ""); // Move all minus signs to the start
    }

    // Ensure only one dot is present
    const dotIndex = result.indexOf(".");
    if (dotIndex !== -1) {
        result =
            result.substring(0, dotIndex + 1) +
            result.substring(dotIndex + 1).replace(/\./g, ""); // Remove extra dots

        // Limit to 2 decimal places
        const decimals = result.substring(dotIndex + 1);
        if (decimals.length > 2) {
            result = result.substring(0, dotIndex + 3); // Allow only 2 digits after the dot
        }
    }

    // Ensure it doesn't start with a dot
    if (result.startsWith(".")) {
        result = "0" + result;
    }

    // Handle case where "-" is followed directly by a dot
    if (result.startsWith("-.")) {
        result = "-0." + result.substring(2);
    }

    return result;
};

export const getDefaultStartDateTime = () => {
    return (
        DateTime.now()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toMillis() - 86400000
    );
};

export const getDefaultEndDateTime = () => {
    return DateTime.now()
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        .toMillis();
};
