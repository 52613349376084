import React, { useState } from "react";
import MainWrapper from "../../components/wrappers/MainWrapper";
import ProfileForm from "../../forms/ProfileForm";
import { Stack, Typography } from "@mui/material";

const Profile = ({ disabled = false }: { disabled?: boolean }) => {
    const [username, setUsername] = useState("");

    const ProfileElement = () => {
        return (
            <Stack
                direction="row"
                alignItems="center"
                sx={{ my: { sm: 3, xs: 2 } }}
            >
                <Typography
                    sx={{
                        fontSize: { sm: "22px", xs: "16px" },
                        fontWeight: 600,
                        color: "#3A3541CC",
                        mr: 1,
                    }}
                >
                    Profile :{" "}
                </Typography>
                <Typography
                    sx={{
                        fontSize: { sm: "22px", xs: "16px" },
                    }}
                >
                    {username}
                </Typography>
            </Stack>
        );
    };
    return (
        <MainWrapper element={<ProfileElement />}>
            <ProfileForm disabled={disabled} setUsername={setUsername} />
        </MainWrapper>
    );
};

export default Profile;
