import MainWrapper from "../../components/wrappers/MainWrapper";
import RegistriesTable from "../tables/RegistriesTable";

const RegistriesTablePage = () => {
    return (
        <MainWrapper title="Registries">
            <RegistriesTable />
        </MainWrapper>
    );
};

export default RegistriesTablePage;
