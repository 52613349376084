import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import Big from "big.js";

const BalancePreview = ({ username }: { username: string | undefined }) => {
    const [balance, setBalance] = useState<Big.Big>(new Big(0));
    const { showError } = useMessage();
    const { postData } = useData();
    useEffect(() => {
        const getBalance = async () => {
            try {
                const result: any = await postData(
                    "/users/getUserCurrentBalance",
                    {
                        username,
                    },
                );
                setBalance(new Big(result));
            } catch (error) {
                showError("Data Fetching Error");
            }
        };
        if (username) {
            getBalance();
        }
    }, []);
    return (
        <Stack
            direction="row"
            sx={{
                bgcolor: "#F6FFED",
                color: "#52C41A",
                p: { sm: 1, xs: 0 },
                justifyContent: "space-between",
                alignItems: "center",
                minWidth: { sm: "200px", xs: "120px" },
                fontSize: { sm: "13px", xs: "12px" },
                borderRadius: 1,
            }}
        >
            <Box sx={{ fontWeight: 600 }}>Current Balance ($) : </Box>
            <Box sx={{ fontWeight: 500 }}> {" " + balance.toFixed(2)}</Box>
        </Stack>
    );
};

export default BalancePreview;
