// project import
import other from "./other";
import { NavItemType } from "../types/menu";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
    items: [other],
};

export default menuItems;
