import React from "react";
import { Box, Typography } from "@mui/material";
import { AuctionType } from "../../enums/Status";
import AuctionTypeStatus from "../common/AuctionTypeStatus";

export type HeadingProps = {
    id: number;
    name: string;
    type: AuctionType;
};
const AuctionHeading = (headingProps: HeadingProps) => {
    return (
        <Box
            sx={{
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                paddingBottom: 2,
            }}
        >
            <Box
                sx={{
                    width: "fit-content",
                    backgroundColor: "#F6F6F6",
                    borderRadius: "10px",
                }}
                py={2}
                px={1}
            >
                <Typography variant={"h1"} color={"#1890FF"}>
                    #{headingProps.id}
                </Typography>
            </Box>
            <Typography variant={"h2"} color={"#3A3541CC"} sx={{ ml: 2 }}>
                {headingProps.name}
            </Typography>
            <AuctionTypeStatus status={headingProps.type} size="large" />
        </Box>
    );
};
export default AuctionHeading;
