import React from "react";
import { Box } from "@mui/material";
import { formStyles, loginContainer, rightLogo } from "./LoginPageStyles";
import largeLogo from "../../assets/images/autobackorder_logo_login.svg";
import loginBackground from "../../assets/images/login_background.svg";
import ChangePasswordForm from "../../forms/ChangePasswordForm";

const ChangePassword = () => {
    return (
        <Box
            sx={{
                ...loginContainer,
                backgroundImage: `url(${loginBackground})`,
            }}
        >
            <Box sx={rightLogo}>
                <img src={largeLogo} style={{ width: "inherit" }} />
            </Box>
            <Box sx={formStyles.wrapper}>
                <ChangePasswordForm />
            </Box>
        </Box>
    );
};

export default ChangePassword;
