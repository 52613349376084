import { Chip } from "@mui/material";
import { DomainTypeTextColors, DomainTypeBgColors } from "../../enums/Status";
import { DomainStatusEnum } from "../../enums/DomainStatus";

const sizes = {
    small: "8px",
    medium: "12px",
    large: "14px",
};
const DomainTypeStatus = ({
    status,
    size = "small",
}: {
    status: DomainStatusEnum;
    size?: "small" | "medium" | "large";
}) => {
    const chipStyles = {
        px: 0,
        py: 0,
        borderRadius: "3px",
        fontSize: sizes[size],
        height: 1,
        "&& .MuiChip-labelMedium": {
            px: "6px",
            py: "3px",
        },
    };
    switch (status) {
        case DomainStatusEnum.SUCCESS:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: DomainTypeBgColors.SUCCESS,
                        color: DomainTypeTextColors.SUCCESS,
                    }}
                    label="Success"
                />
            );
        case DomainStatusEnum.CANCELLED:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: DomainTypeBgColors.CANCELLED,
                        color: DomainTypeTextColors.CANCELLED,
                    }}
                    label="Cancelled"
                />
            );
        case DomainStatusEnum.AUTO_RENEWED:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: DomainTypeBgColors.AUTO_RENEWED,
                        color: DomainTypeTextColors.AUTO_RENEWED,
                    }}
                    label="Auto Renewed"
                />
            );
        case DomainStatusEnum.DOMAIN_NOT_FOUND:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: DomainTypeBgColors.DOMAIN_NOT_FOUND,
                        color: DomainTypeTextColors.DOMAIN_NOT_FOUND,
                    }}
                    label="Not Found"
                />
            );
        case DomainStatusEnum.FAILURE:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: DomainTypeBgColors.FAILURE,
                        color: DomainTypeTextColors.FAILURE,
                    }}
                    label="Failure"
                />
            );
        case DomainStatusEnum.NOT_PROCESSED:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: DomainTypeBgColors.NOT_PROCESSED,
                        color: DomainTypeTextColors.NOT_PROCESSED,
                    }}
                    label="Not Processed"
                />
            );
        case DomainStatusEnum.SKIPPED:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: DomainTypeBgColors.SKIPPED,
                        color: DomainTypeTextColors.SKIPPED,
                    }}
                    label="Skipped"
                />
            );
        default:
            return <></>;
    }
};

export default DomainTypeStatus;
