import { useEffect, useMemo, useState } from "react";

import { Box, Button, Grid, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import ScrollX from "../../components/ScrollX";
import ReactTable from "./react-table/ReactTable";
import { registriesTableDataType } from "../../types/tableTypes";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import { Link } from "react-router-dom";
import { cellDeleteIconWithID } from "./CellFormatters";
import ConfirmationBox from "../../components/common/AlertBoxes/ConfirmationBox";

function cellArrayToString({ value }: any) {
    if (!value || value.length === 0) return <></>;
    else {
        return (
            <Typography align="justify" sx={{ whiteSpace: "normal" }}>
                {value.join(", ")}
            </Typography>
        );
    }
}

function cellRegistryLink(registry: any) {
    return (
        <Link
            style={{ textDecoration: "none", color: "#1890FF" }}
            to={`/admin/registries/update/${registry.id}`}
        >
            <Typography>{registry.registry}</Typography>
        </Link>
    );
}

const RegistriesTable = () => {
    const { fetchData, deleteData } = useData();
    const { showError, showSuccess } = useMessage();
    const [data, setData] = useState<registriesTableDataType[]>([]);

    const [deleteFocused, setDeleteFocused] = useState<string>("");

    const [deleteId, setDeleteId] = useState(0);

    const [alert, setAlert] = useState<boolean>(false);

    function openAlertBox(registry: string, id: number) {
        setDeleteFocused(registry);
        setDeleteId(id);
        setAlert(true);
    }

    async function deleteRegistry(id: number) {
        setAlert(false);
        // console.log(id);
        try {
            setLoading(true);
            await deleteData(`/registry/${id}`);
            showSuccess(`'${deleteFocused}' removed from registries`);
            getData();
        } catch (error) {
            showError("Failed to remove the registry");
            console.log(error);
        }
        setLoading(false);
    }

    const [loading, setLoading] = useState<boolean>(false);

    const getData = async () => {
        setLoading(true);
        try {
            const response: any = await fetchData("/registry");
            if (response?.data) {
                setData(response.data);
            } else {
                setData([]);
            }
        } catch (error) {
            showError("Data Fetching Error");
        }
        setLoading(false);
    };

    useEffect(() => {
        getData();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "Registry",
                accessor: "registry",
                disableSortBy: true,
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: ({ value }: { value: string }) => {
                    const registry: any = data.find(
                        (item) => item.registry == value,
                    );
                    return cellRegistryLink(registry);
                },
            },
            {
                Header: "TLDs",
                accessor: "tlds",
                disableSortBy: true,
                Cell: cellArrayToString,
                width: "fit-content",
            },
            {
                Header: "Action",
                accessor: "id",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: ({ value }: { value: string }) => {
                    const registry: any = data.find(
                        (item) => item.id == +value,
                    );
                    return cellDeleteIconWithID({
                        value: registry?.registry,
                        id: registry?.id,
                        openAlertBox,
                    });
                },
                disableSortBy: true,
            },
        ],
        [data],
    );

    return (
        <Grid container>
            <ConfirmationBox
                title={`Confirm removing ${deleteFocused} from Registries`}
                subtitle="This action cannot be undone"
                open={alert}
                setOpen={setAlert}
                triggerFunction={deleteRegistry}
                triggerValue={deleteId}
            />
            <Box
                sx={{
                    mb: 4,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column-reverse", sm: "row" },
                    flexGrow: 1,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        my: 1,
                        flexGrow: 1,
                        justifyContent: { xs: "flex-end", sm: "flex-end" },
                        alignItems: "center",
                    }}
                >
                    <Link to={"/admin/registries/create"}>
                        <Button
                            variant="contained"
                            endIcon={<AddIcon />}
                            sx={{
                                ml: 1,
                                height: "40px",
                                whiteSpace: "nowrap",
                            }}
                        >
                            Add Registry
                        </Button>
                    </Link>
                </Box>
            </Box>

            {/* Table Section */}
            <Grid item xs={12}>
                <ScrollX>
                    <ReactTable
                        pageSize={0}
                        pageIndex={0}
                        columns={columns}
                        data={data}
                        setPageIndexProp={() => {}}
                        setPageSizeProp={() => {}}
                        setSortByProp={() => {}}
                        loading={loading}
                        rowCount={1}
                        top={true}
                    />
                </ScrollX>
            </Grid>
        </Grid>
    );
};

export default RegistriesTable;
