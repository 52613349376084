// material-ui
import { Box, Typography } from "@mui/material";

// project import
import NavGroup from "./NavGroup";
import menuItem from "../../../../../menu-items";

// ==============================|| DRAWER CONTENT - NAVIGATION ||============================== //

const Navigation = () => {
    const navGroups: any = menuItem.items.map((item) => {
        switch (item.type) {
            case "group":
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography
                        key={item.id}
                        variant="h6"
                        color="error"
                        align="center"
                    >
                        Fix - Navigation Group
                    </Typography>
                );
        }
    });

    return (
        <Box
            sx={{
                pt: "24px",
                "& > ul:first-of-type": { mt: 0 },
            }}
        >
            {navGroups}
        </Box>
    );
};

export default Navigation;
