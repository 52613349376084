import React from "react";
import MainWrapper from "../../components/wrappers/MainWrapper";
import CreateRegistryForm from "../../forms/CreateRegistryForm";

const UpdateRegistry = () => {
    return (
        <MainWrapper title="Update Registry">
            <CreateRegistryForm update />
        </MainWrapper>
    );
};

export default UpdateRegistry;
