export enum ChipStatus {
    DANGER = "Danger",
    WARN = "Warning",
    INFO = "Info",
    SUCCESS = "Success",
}

export enum ChipBgColors {
    DANGER = "#FFF1F0",
    WARN = "#FFF3DB",
    INFO = "#E6F7FF",
    SUCCESS = "#EEF9DE",
}

export enum ChipTextColors {
    DANGER = "#FF4D4F",
    WARN = "#EB8E51",
    INFO = "#1890FF",
    SUCCESS = "#3BB800",
}

export enum AuctionType {
    PRE = "Pre auction",
    POST = "Post auction",
}

export enum AuctionTypeColors {
    POST = "#A76BC7",
    PRE = "#52C41A",
}

export enum AuctionTypeTextColors {
    WHITE = "#FFFFFF",
}

export enum DomainTypeTextColors {
    SUCCESS = "rgb(103, 191, 107)",
    CANCELLED = "#838087",
    AUTO_RENEWED = "rgb(173, 181, 189)",
    DOMAIN_NOT_FOUND = "rgb(108, 117, 125)",
    NOT_PROCESSED = "rgb(244, 174, 26)",
    FAILURE = "rgb(239, 73, 35)",
    SKIPPED = "rgb(108, 117, 126)",
}

export enum DomainTypeBgColors {
    SUCCESS = "rgba(103, 191, 107, 0.25)",
    CANCELLED = "#F0F0F0",
    AUTO_RENEWED = "rgba(173, 181, 189, 0.25)",
    DOMAIN_NOT_FOUND = "rgba(108, 117, 125, 0.25)",
    NOT_PROCESSED = "rgba(244, 174, 26, 0.25)",
    FAILURE = "rgba(239, 73, 35, 0.25)",
    SKIPPED = "rgba(108, 117, 126, 0.25)",
}
