import MainLayout from "../layout/MainLayout";
import AuthGuard from "../utils/route-guard/AuthGuard";

// Pages
import AllBidsPage from "../pages/allBids/AllBidsPage";
import UsersTablePage from "../pages/users/UsersTablePage";
import ModifyUserPage from "../pages/users/ModifyUserPage";
import CreateUser from "../pages/users/CreateUser";
import MessagesPage from "../pages/messages/MessagesPage";
import CreateMessage from "../pages/messages/CreateMessage";
import EmailsTablePage from "../pages/emails/EmailsTablePage";
import CreateEmail from "../pages/emails/CreateEmail";
import CreateEmailsWithTemplate from "../pages/emails/CreateEmailsWithTemplate";
import BlacklistTablePage from "../pages/blacklist/BlacklistTablePage";
import AddBlacklistDomains from "../pages/blacklist/AddBlacklistDomains";
import WhoisPage from "../pages/whois/WhoisPage";
import RegistriesTablePage from "../pages/registries/RegistriesTablePage";
import CreateRegistry from "../pages/registries/CreateRegistry";
import UpdateRegistry from "../pages/registries/UpdateRegistry";
import TldPage from "../pages/tld/TldPage";
import CreateTldPage from "../pages/tld/CreateTld";
import UpdateTldPage from "../pages/tld/UpdateTld";
import BalancesTablePage from "../pages/balances/BalancesTablePage";
import UserTransactionsPage from "../pages/balances/UserTransactionsPage";
import Profile from "../pages/profile/Profile";
import PerBidViewPage from "../pages/perDomainView/PerBidViewPage";
import AllAuctionsPage from "../pages/allAuctions/AllAuctionsPage";
import PerAuctionView from "../pages/allAuctions/PerAuctionView";
import AnnouncementPage from "../pages/announcements/Announcement";
import AddBalancePage from "../pages/balances/AddBalancePage";
import SubmissionsPage from "../pages/submissions/SubmissionsPage";

const mainRoutes = {
    path: "/",
    children: [
        {
            path: "/",
            element: (
                <AuthGuard>
                    <MainLayout />
                </AuthGuard>
            ),
            children: [
                {
                    path: "allBids",
                    element: <AllBidsPage />,
                },
                {
                    path: "allAuctions",
                    element: <AllAuctionsPage />,
                },
                {
                    path: "submissions",
                    element: <SubmissionsPage />,
                },
                {
                    path: "balances",
                    element: <BalancesTablePage />,
                },
                {
                    path: "balances/:username",
                    element: <UserTransactionsPage />,
                },
                {
                    path: "addBalance/:username",
                    element: <AddBalancePage />,
                },
                {
                    path: "perAuctionView/:auctionId",
                    element: <PerAuctionView />,
                },
                {
                    path: "admin",
                    children: [
                        {
                            path: "users",
                            element: <UsersTablePage />,
                        },
                        {
                            path: "users/create",
                            element: <CreateUser />,
                        },
                        {
                            path: "users/:username",
                            element: <ModifyUserPage />,
                        },
                        {
                            path: "messages",
                            element: <MessagesPage />,
                        },
                        {
                            path: "messages/create",
                            element: <CreateMessage />,
                        },
                        {
                            path: "emails",
                            element: <EmailsTablePage />,
                        },
                        {
                            path: "emails/create",
                            element: <CreateEmail />,
                        },
                        {
                            path: "emails/createWithTemplate",
                            element: <CreateEmailsWithTemplate />,
                        },
                        {
                            path: "blacklist",
                            element: <BlacklistTablePage />,
                        },
                        {
                            path: "blacklist/add",
                            element: <AddBlacklistDomains />,
                        },
                        {
                            path: "registries",
                            element: <RegistriesTablePage />,
                        },
                        {
                            path: "registries/create",
                            element: <CreateRegistry />,
                        },
                        {
                            path: "registries/update/:id",
                            element: <UpdateRegistry />,
                        },
                        {
                            path: "whois",
                            element: <WhoisPage />,
                        },
                        {
                            path: "tlds",
                            element: <TldPage />,
                        },
                        {
                            path: "tlds/create",
                            element: <CreateTldPage />,
                        },
                        {
                            path: "tlds/update/:tldName",
                            element: <UpdateTldPage />,
                        },
                        {
                            path: "perBidView/:bidId",
                            element: <PerBidViewPage />,
                        },
                        {
                            path: "announcements",
                            element: <AnnouncementPage />,
                        },
                    ],
                },
                {
                    path: "profile/:username",
                    element: <Profile disabled />,
                },
                {
                    path: "profile/:username/edit",
                    element: <Profile />,
                },
            ],
        },
    ],
};

export default mainRoutes;
