// third-party
import { combineReducers } from "redux";

// project import
import menu from "./menu";
import snackbar from "./snackbar";

// ==============================|| COMBINE REDUCERS ||============================== //

const reducers = combineReducers({
    menu,
    snackbar,
});

export default reducers;
