import { useState, useEffect } from "react";
import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { DateTime } from "luxon";
import { DateEnum } from "../../../../enums/Common";

const Clock = () => {
    const matchesMd = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md"),
    );

    const [time, setTime] = useState(
        DateTime.now()
            .toUTC()
            .toFormat(DateEnum.DateTimeFormatLuxon + " 'UTC'"),
    );

    useEffect(() => {
        const timerID = setInterval(() => tick(), 1000);

        return function cleanup() {
            clearInterval(timerID);
        };
    }, []);

    const tick = () => {
        setTime(
            DateTime.now()
                .toUTC()
                .toFormat(DateEnum.DateTimeFormatLuxon + " 'UTC'"),
        );
    };

    return matchesMd ? (
        <h4 style={{ padding: "0 1rem 0 1rem", margin: "0", width: "100%" }}>
            {time}
        </h4>
    ) : (
        <h3 style={{ padding: "0 1rem 0 1rem", margin: "0", width: "100%" }}>
            {time}
        </h3>
    );
};
export default Clock;
