import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useMessage from "../hooks/useMessage";
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    OutlinedInput,
    IconButton,
    FormControlLabel,
} from "@mui/material";
import { TextField } from "@mui/material";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Checkbox } from "@mui/material";
import { generatePassword } from "../utils/helpers";
import { floor } from "lodash";
import useData from "../hooks/useApi";
import { useNavigate } from "react-router-dom";
import { IoMdArrowDropup, IoMdArrowDropdown } from "react-icons/io";
import { regExValidations } from "../data/consts";
import { Domains } from "../enums/Consts";

// import useAuth from "../hooks/useAuth";

const CreateUserForm = ({
    modify,
    username,
}: {
    modify?: boolean;
    username?: string;
}) => {
    const { postData } = useData();
    const { showError, showSuccess } = useMessage();
    // const { logout } = useAuth();
    const navigate = useNavigate();
    const [showPasswd, setShowPasswd] = useState<boolean>(false);
    const toggleShowPasswd = () => {
        setShowPasswd((showPasswd) => !showPasswd);
    };
    const [initData, setInitData] = useState<{
        status: string;
        dailyDomain: number;
        isAdmin: boolean;
        isTrusted: boolean;
    }>();
    const [loading, setLoading] = useState<boolean>(false);

    const initialValues = {
        update: {
            username: "",
            status: "",
            email: "",
            domainAddLimit: 20,
            isAdmin: false,
            trusted: false,
        },
        new: {
            username: "",
            status: "",
            email: "",
            password: "",
            domainAddLimit: 20,
            isAdmin: false,
            trusted: false,
        },
    };

    const validationSchemaNew = {
        username: yup
            .string()
            .required("Required Field")
            .matches(regExValidations.username, "Invalid Username"),
        status: yup.string().required("Required Field"),
        email: yup
            .string()
            .required("Required Field")
            .test("email", "Invalid Email", function (value) {
                return regExValidations.email.test(value as string);
            }),
        password: yup.string().required("Required Field"),
        domainAddLimit: yup.number(),
        isAdmin: yup.boolean(),
        trusted: yup.boolean(),
    };

    const validationSchemaUpdate = {
        username: yup
            .string()
            .required("Required Field")
            .matches(regExValidations.username, "Invalid Characters"),
        status: yup.string().required("Required Field"),
        email: yup.string().email("Invalid Email").required("Required Field"),
        domainAddLimit: yup.number(),
        isAdmin: yup.boolean(),
        trusted: yup.boolean(),
    };

    const validationSchema = yup.object(
        modify ? validationSchemaUpdate : validationSchemaNew,
    );

    const formik = useFormik({
        initialValues: modify
            ? { ...initialValues.update }
            : { ...initialValues.new },
        validationSchema,
        async onSubmit(values) {
            setLoading(true);
            try {
                // New User creation
                console.log(values);
                if (!modify) {
                    const response: any = await postData("/users/createUser", {
                        username: values.username,
                        isDisabled: values.status == "true",
                        email: values.email,
                        // @ts-expect-error error
                        password: values.password,
                        dailyDomainsLimit: values.domainAddLimit,
                        role: values.isAdmin ? "admin" : "user",
                        isTrusted: values.trusted,
                    });
                    if (response && response?.success) {
                        showSuccess("User registered successfully");
                        navigate("/admin/users");
                    } else {
                        throw Error("failed");
                    }
                }
                // Update User
                else {
                    const response: any = await postData("/users/modifyUser", {
                        username,
                        status: values.status == "true",
                        dailyDomainsLimit: values.domainAddLimit,
                        isAdmin: values.isAdmin,
                        isTrusted: values.trusted,
                    });

                    if (response && response?.success) {
                        showSuccess("User details updated successfully");
                        navigate("/admin/users");
                    } else {
                        throw Error("failed");
                    }
                }
            } catch (e: any) {
                if (e.response?.status === 409) {
                    showError("Username or Email already exists");
                } else if (e.response?.status === 500) {
                    showError("Internal server error");
                } else {
                    showError(
                        modify
                            ? "User Modification Failed"
                            : "User Creation Failed",
                    );
                }
            }
            setLoading(false);
        },
    });

    useEffect(() => {
        async function getUserData() {
            const payload = {
                page: 1,
                size: 10,
                filterAnd: [
                    {
                        key: "username",
                        operation: "=",
                        value: username?.trim(),
                    },
                ],
                sort: {
                    key: "id",
                    order: "ASC",
                },
            };
            try {
                const response: any = await postData(
                    "/users/getUsersData",
                    payload,
                );
                const userData = response.data[0];
                console.log(userData);
                formik.setFieldValue("username", userData.username);
                formik.setFieldValue("status", `${userData.isDisabled}`);
                formik.setFieldValue("email", userData.email);
                formik.setFieldValue(
                    "domainAddLimit",
                    userData.dailyDomainsLimit,
                );
                formik.setFieldValue("isAdmin", userData.role === "admin");
                formik.setFieldValue("trusted", userData.isTrusted);
                setInitData({
                    status: `${userData.isDisabled}`,
                    dailyDomain: userData.dailyDomainsLimit,
                    isAdmin: userData.role === "admin",
                    isTrusted: userData.isTrusted,
                });
            } catch (error) {
                showError("Data Fetching Error");
            }
        }
        if (modify && username) {
            getUserData();
        } else {
            generatePasswdField();
        }
    }, []);

    function generatePasswdField() {
        const password = generatePassword(6);
        if (password.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W).{6,}$/))
            formik.setFieldValue("password", password);
        else generatePasswdField();
    }

    const styles = {
        formControlStyle: {
            my: 1,
            minWidth: 100,
        },
        inputGroup: {
            my: 1,
        },
    };

    return (
        <form
            onSubmit={formik.handleSubmit}
            style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexGrow: 1,
            }}
        >
            <Grid container spacing={3} sx={{ pt: 4 }}>
                <Grid container spacing={3} sx={{ mx: "auto" }}>
                    {/* Username Field */}
                    <Grid item md={4} sm={6} xs={12} sx={styles.inputGroup}>
                        <Stack>
                            <InputLabel>Username</InputLabel>
                            <FormControl sx={styles.formControlStyle}>
                                <TextField
                                    id="username"
                                    name="username"
                                    fullWidth
                                    autoComplete="username"
                                    disabled={modify}
                                    sx={{
                                        "&:disabled": {
                                            color: "#3A354180 !important",
                                        },
                                    }}
                                    // @ts-expect-error error
                                    error={
                                        formik.errors.username &&
                                        formik.touched.username
                                    }
                                    value={formik.values.username}
                                    inputProps={{
                                        style: {
                                            backgroundColor: modify
                                                ? "#EEEEEE"
                                                : "unset",
                                            color: modify
                                                ? "#3A354180"
                                                : "unset",
                                        },
                                    }}
                                    onChange={(event) => {
                                        const trimmedValue = event.target.value
                                            .replace(/[^0-9a-zA-Z_]+/, "")
                                            .trim();
                                        if (trimmedValue.length <= 15)
                                            formik.setFieldValue(
                                                "username",
                                                trimmedValue,
                                            );
                                    }}
                                />
                                {formik.errors.username && (
                                    <FormHelperText sx={{ mx: 0 }} error>
                                        {""}
                                        {formik.touched.username &&
                                            formik.errors.username}{" "}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                    </Grid>

                    {/* Status Selector */}
                    <Grid item md={4} sm={6} xs={12} sx={styles.inputGroup}>
                        <Stack>
                            <InputLabel>Status</InputLabel>
                            <FormControl sx={styles.formControlStyle}>
                                <Select
                                    id="status"
                                    name="status"
                                    value={formik.values.status}
                                    onChange={formik.handleChange}
                                    displayEmpty
                                    inputProps={{
                                        "aria-label": "Status",
                                    }}
                                    // @ts-expect-error error
                                    error={
                                        formik.touched.status &&
                                        formik.errors.status
                                    }
                                >
                                    <MenuItem value="false">Active</MenuItem>
                                    <MenuItem value="true">Inactive</MenuItem>
                                </Select>
                                {formik.errors.status && (
                                    <FormHelperText error sx={{ mx: 0 }}>
                                        {" "}
                                        {formik.touched.status &&
                                            formik.errors.status}{" "}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                    </Grid>

                    {/* Email Field */}
                    <Grid item md={4} sm={6} xs={12} sx={styles.inputGroup}>
                        <Stack>
                            <InputLabel>Email</InputLabel>
                            <FormControl sx={styles.formControlStyle}>
                                <TextField
                                    id="email"
                                    name="email"
                                    fullWidth
                                    autoComplete="email"
                                    value={formik.values.email}
                                    disabled={modify}
                                    onChange={(event) => {
                                        const trimmedValue = event.target.value
                                            .replace(/[ ]+/, "")
                                            .trim()
                                            .toLowerCase();
                                        formik.setFieldValue(
                                            "email",
                                            trimmedValue,
                                        );
                                    }}
                                    inputProps={{
                                        style: {
                                            backgroundColor: modify
                                                ? "#EEEEEE"
                                                : "unset",
                                        },
                                    }}
                                    // @ts-expect-error error
                                    error={
                                        formik.errors.email &&
                                        formik.touched.email
                                    }
                                />
                                {formik.errors.email && (
                                    <FormHelperText error sx={{ mx: 0 }}>
                                        {" "}
                                        {formik.touched.email &&
                                            formik.errors.email}{" "}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                    </Grid>

                    {/* Password Field */}
                    {!modify && (
                        <Grid item md={4} sm={6} xs={12} sx={styles.inputGroup}>
                            <Stack>
                                <InputLabel>Password</InputLabel>
                                <FormControl sx={styles.formControlStyle}>
                                    <Stack direction="row">
                                        <OutlinedInput
                                            id="password"
                                            name="password"
                                            fullWidth
                                            type={
                                                !showPasswd
                                                    ? "password"
                                                    : "text"
                                            }
                                            autoComplete="password"
                                            // @ts-expect-error error
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            readOnly
                                            sx={{
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                            }}
                                            endAdornment={
                                                <IconButton
                                                    onClick={toggleShowPasswd}
                                                >
                                                    {showPasswd ? (
                                                        <EyeOutlined />
                                                    ) : (
                                                        <EyeInvisibleOutlined />
                                                    )}
                                                </IconButton>
                                            }
                                        />
                                        <Button
                                            variant="outlined"
                                            sx={{
                                                borderTopLeftRadius: 0,
                                                borderBottomLeftRadius: 0,
                                            }}
                                            onClick={generatePasswdField}
                                        >
                                            Generate
                                        </Button>
                                    </Stack>

                                    {
                                        // @ts-expect-error error
                                        formik.errors.password && (
                                            <FormHelperText
                                                error
                                                sx={{ mx: 0 }}
                                            >
                                                {" "}
                                                {
                                                    // @ts-expect-error error
                                                    formik.touched.password &&
                                                        // @ts-expect-error error
                                                        formik.errors.password
                                                }{" "}
                                            </FormHelperText>
                                        )
                                    }
                                </FormControl>
                            </Stack>
                        </Grid>
                    )}

                    {/* Daily domain add limit */}
                    <Grid item md={4} sm={6} xs={12} sx={styles.inputGroup}>
                        <Stack>
                            <InputLabel>Daily Domain Add Limit</InputLabel>
                            <FormControl sx={styles.formControlStyle}>
                                <OutlinedInput
                                    // type="number"
                                    id="domainAddLimit"
                                    name="domainAddLimit"
                                    // placeholder="20"
                                    fullWidth
                                    autoComplete="domainAddLimit"
                                    value={formik.values.domainAddLimit}
                                    onChange={(event) => {
                                        let value = floor(
                                            +event.target.value
                                                ? +event.target.value
                                                : 0,
                                            0,
                                        );
                                        value = value < 0 ? 0 : value;
                                        value =
                                            value > Domains.DOMAIN_ADD_LIMIT
                                                ? Domains.DOMAIN_ADD_LIMIT
                                                : value;
                                        formik.setFieldValue(
                                            "domainAddLimit",
                                            value,
                                        );
                                    }}
                                    endAdornment={
                                        <Stack
                                            sx={{
                                                position: "relative",
                                                height: "35px",
                                            }}
                                        >
                                            <IoMdArrowDropup
                                                color={
                                                    formik.values
                                                        .domainAddLimit <
                                                    Domains.DOMAIN_ADD_LIMIT
                                                        ? "#8C8C8C"
                                                        : "ddd"
                                                }
                                                style={{
                                                    fontSize: "20px",
                                                    position: "absolute",
                                                    border: "1px solid #1890FF",
                                                    top: -2,
                                                    right: -13,
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    if (
                                                        formik.values
                                                            .domainAddLimit <
                                                        Domains.DOMAIN_ADD_LIMIT
                                                    )
                                                        formik.setFieldValue(
                                                            "domainAddLimit",
                                                            formik.values
                                                                .domainAddLimit +
                                                                1,
                                                        );
                                                }}
                                            />
                                            <IoMdArrowDropdown
                                                color={
                                                    formik.values
                                                        .domainAddLimit > 0
                                                        ? "#8C8C8C"
                                                        : "ddd"
                                                }
                                                style={{
                                                    fontSize: "20px",
                                                    position: "absolute",
                                                    border: "1px solid #1890FF",
                                                    bottom: -2,
                                                    right: -13,
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    if (
                                                        formik.values
                                                            .domainAddLimit > 0
                                                    )
                                                        formik.setFieldValue(
                                                            "domainAddLimit",
                                                            formik.values
                                                                .domainAddLimit -
                                                                1,
                                                        );
                                                }}
                                            />
                                        </Stack>
                                    }
                                />
                                {formik.errors.domainAddLimit && (
                                    <FormHelperText error sx={{ mx: 0 }}>
                                        {" "}
                                        {formik.touched.domainAddLimit &&
                                            formik.errors.domainAddLimit}{" "}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Stack>
                    </Grid>
                </Grid>

                {/* Checkbox Section */}
                <Grid
                    item
                    xs={12}
                    md={4}
                    sm={6}
                    sx={{
                        ...styles.inputGroup,
                    }}
                >
                    <Stack direction="row" sx={{ marginLeft: "2px" }}>
                        <FormControlLabel
                            sx={{
                                color: "#3A3541CC",
                                flex: 1,
                            }}
                            control={
                                <Checkbox
                                    color="primary"
                                    name="isAdmin"
                                    value={true}
                                    sx={{
                                        "& .MuiBox-root": {
                                            border: formik.values.isAdmin
                                                ? 0
                                                : 1,
                                        },
                                    }}
                                    onChange={formik.handleChange}
                                    checked={formik.values.isAdmin}
                                />
                            }
                            label="Is Admin"
                        />
                        <FormControlLabel
                            sx={{ color: "#3A3541CC", flex: 1 }}
                            control={
                                <Checkbox
                                    color="primary"
                                    name="trusted"
                                    value={true}
                                    sx={{
                                        "& .MuiBox-root": {
                                            border: formik.values.trusted
                                                ? 0
                                                : 1,
                                        },
                                    }}
                                    checked={formik.values.trusted}
                                    onChange={formik.handleChange}
                                />
                            }
                            label="Trusted"
                        />
                    </Stack>
                </Grid>

                {/* Buttons */}
                <Grid item xs={12}>
                    <Stack direction="row" justifyContent="flex-end">
                        <Button
                            variant="contained"
                            type="submit"
                            disabled={
                                loading ||
                                (modify &&
                                    (initData
                                        ? initData?.dailyDomain ==
                                              formik.values.domainAddLimit &&
                                          initData.isAdmin ==
                                              formik.values.isAdmin &&
                                          initData.isTrusted ==
                                              formik.values.trusted &&
                                          initData.status ==
                                              formik.values.status
                                        : true))
                            }
                            style={{
                                width: "100px",
                                height: "40px",
                            }}
                        >
                            {modify ? "Update" : "Submit"}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </form>
    );
};

export default CreateUserForm;
