import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Checkbox,
} from "@mui/material";

// third-party
import {
    useTable,
    useFilters,
    usePagination,
    Column,
    useSortBy,
} from "react-table";

import {
    EmptyTable,
    HeaderSort,
    TablePagination,
} from "../../../components/third-party/ReactTable";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import PopupMenu from "../../../components/popups/PopUpMenu";
import AuctionPopUpMenu from "../../../components/popups/AuctionPopUpMenu";

type BidActionFunction<T> = (action: string, nextState: boolean) => Promise<T>;

function ActionReactTable({
    rowCount,
    pageSize,
    setPageSizeProp,
    pageIndex,
    setPageIndexProp,
    setSortByProp,
    setSelectedRowsProp,
    loading,
    columns,
    data,
    top,
    selectedRows,
    whichPage,
    handleBidAction,
    setDeleteConfirmation,
    pageType,
}: {
    columns: Column[];
    data: any[];
    top?: boolean;
    rowCount: number;
    loading: boolean;
    setPageSizeProp: Dispatch<SetStateAction<number>>;
    setPageIndexProp: Dispatch<SetStateAction<number>>;
    setSortByProp: Dispatch<SetStateAction<any>>;
    setSelectedRowsProp: Dispatch<SetStateAction<boolean[]>>;
    pageIndex: number;
    pageSize: number;
    selectedRows: boolean[];
    whichPage: string;
    handleBidAction: BidActionFunction<void>;
    setDeleteConfirmation: Dispatch<SetStateAction<boolean>>;
    pageType?: "auction" | "bid";
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // @ts-expect-error error expected
        page,
        prepareRow,
        // @ts-expect-error error expected
        state: { sortBy },
    } = useTable(
        {
            columns,
            data,
            // @ts-expect-error error expected
            manualPagination: true,
            manualSortBy: true,
            disableSortRemove: true,
        },
        useFilters,
        useSortBy,
        usePagination,
    );

    const [selectAll, setSelectAll] = useState<boolean>(false);

    const handleVertClick = (index: number) => {
        const newCheckedCheckboxes = new Array(data.length).fill(false);
        newCheckedCheckboxes[index] = !newCheckedCheckboxes[index];
        setSelectedRowsProp(newCheckedCheckboxes);
        if (newCheckedCheckboxes.every((value) => value == true)) {
            setSelectAll(true);
        } else {
            setSelectAll(false);
        }
    };

    const handleCheckboxChange = (index: number) => {
        const newCheckedCheckboxes = [...selectedRows];
        newCheckedCheckboxes[index] = !newCheckedCheckboxes[index];
        setSelectedRowsProp(newCheckedCheckboxes);
    };

    const handleFullCheckboxChange = () => {
        setSelectAll(!selectAll);
        setSelectedRowsProp(new Array(data.length).fill(!selectAll));
    };

    useEffect(() => {
        if (selectedRows.length > 0) {
            if (selectedRows.every((value) => value == true)) {
                setSelectAll(true);
            } else {
                setSelectAll(false);
            }
        } else {
            setSelectAll(false);
        }
    }, [selectedRows]);

    useEffect(() => {
        if (sortBy?.length) {
            setSortByProp(sortBy[0]);
        }
    }, [sortBy]);

    return (
        <Box
            sx={{
                display: "flex",
                flexGrow: 1,
            }}
        >
            <Table
                {...getTableProps()}
                sx={{
                    "& .MuiTableRow-root .MuiTableCell-root:first-of-type": {
                        paddingLeft: "12px",
                    },
                }}
            >
                <TableHead sx={{ borderTopWidth: top ? 2 : 1 }}>
                    {headerGroups.map((headerGroup: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            <TableCell
                                sx={{
                                    height: "70px",
                                    whiteSpace: "nowrap",
                                    textTransform: "none",
                                    color: "#3A3541CC",
                                    fontWeight: 600,
                                    textAlign: "center",
                                }}
                            >
                                {/* Render the checkbox */}
                                <Checkbox
                                    checked={selectAll || false}
                                    onChange={() => handleFullCheckboxChange()}
                                    sx={{ transform: "scale(0.8)" }}
                                    inputProps={{
                                        "aria-label": "controlled",
                                    }}
                                />
                            </TableCell>
                            {headerGroup.headers.map((column: any) => (
                                // eslint-disable-next-line react/jsx-key
                                <TableCell
                                    {...column.getHeaderProps([
                                        { className: column.className },
                                    ])}
                                    sx={{
                                        height: "70px",
                                        whiteSpace: "nowrap",
                                        textTransform: "none",
                                        color: "#3A3541CC",
                                        fontWeight: 600,
                                        textAlign: "center",
                                    }}
                                >
                                    <HeaderSort column={column} sort />
                                </TableCell>
                            ))}
                            <TableCell></TableCell>
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {!loading ? (
                        page && page.length > 0 ? (
                            page.map((row: any /*, i: number */) => {
                                prepareRow(row);
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <TableRow
                                        sx={
                                            data[row.id].isHighestBid
                                                ? {
                                                      border: {
                                                          xs: "1.8px solid #3BB800",
                                                          sm: "1.8px solid #3BB800",
                                                          md: "2px solid #3BB800",
                                                      },
                                                  }
                                                : {}
                                        }
                                        {...row.getRowProps()}
                                    >
                                        <TableCell
                                            sx={{
                                                textAlign: "center",
                                            }}
                                        >
                                            <Checkbox
                                                checked={
                                                    selectedRows[row.id] ||
                                                    false
                                                }
                                                onChange={() =>
                                                    handleCheckboxChange(row.id)
                                                }
                                                sx={{ transform: "scale(0.8)" }}
                                                inputProps={{
                                                    "aria-label": "controlled",
                                                }}
                                            />
                                        </TableCell>
                                        {row.cells.map((cell: any) => (
                                            // eslint-disable-next-line react/jsx-key
                                            <TableCell
                                                {...cell.getCellProps([
                                                    {
                                                        className:
                                                            cell.column
                                                                .className,
                                                    },
                                                ])}
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    fontSize: "13px",
                                                }}
                                            >
                                                {cell.render("Cell")}
                                            </TableCell>
                                        ))}
                                        <TableCell
                                            onClick={() =>
                                                handleVertClick(row.id)
                                            }
                                        >
                                            {pageType === "auction" ? (
                                                <AuctionPopUpMenu
                                                    domainName={
                                                        data[row.id].Domain
                                                    }
                                                    dropTime={
                                                        data[row.id].dropTime
                                                    }
                                                    whichPage={whichPage}
                                                    auctionType={
                                                        data[row.id].auctionType
                                                    }
                                                    handleBidAction={
                                                        handleBidAction
                                                    }
                                                    setDeleteConfirmation={
                                                        setDeleteConfirmation
                                                    }
                                                />
                                            ) : (
                                                <PopupMenu
                                                    username={
                                                        data[row.id].Username
                                                    }
                                                    domainName={
                                                        data[row.id].Domain
                                                    }
                                                    whichPage={whichPage}
                                                    isLocked={
                                                        data[row.id].isLocked
                                                    }
                                                    isPaused={
                                                        data[row.id].isPaused
                                                    }
                                                    auctionType={
                                                        data[row.id].auctionType
                                                    }
                                                    handleBidAction={
                                                        handleBidAction
                                                    }
                                                    setDeleteConfirmation={
                                                        setDeleteConfirmation
                                                    }
                                                />
                                            )}{" "}
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        ) : (
                            <EmptyTable msg={"No Data"} colSpan={12} />
                        )
                    ) : (
                        <TableRow>
                            <TableCell colSpan={12}>
                                <Box
                                    sx={{
                                        textAlign: "center",
                                        height: "200px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <LoadingOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}

                    {!top && (
                        <TableRow>
                            <TableCell
                                sx={{
                                    "&.MuiTableCell-root:first-of-type": {
                                        px: 0,
                                        mx: 0,
                                    },
                                    "&.MuiTableCell-root:last-of-type": {
                                        px: 0,
                                        mx: 0,
                                    },
                                    pt: 4,
                                }}
                                colSpan={12}
                            >
                                <TablePagination
                                    rows={rowCount}
                                    setPageSize={setPageSizeProp}
                                    pageSize={pageSize}
                                    setPageIndex={setPageIndexProp}
                                    pageIndex={pageIndex}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Box>
    );
}

export default ActionReactTable;
