import { ReactNode, useMemo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { AppBar, Toolbar, useMediaQuery, AppBarProps } from "@mui/material";

// project import
import AppBarStyled from "./AppBarStyled";
import HeaderContent from "./HeaderContent";
import IconButton from "../../../components/@extended/IconButton";

// assets
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

// ==============================|| MAIN LAYOUT - HEADER ||============================== //

interface Props {
    open: boolean;
    handleDrawerToggle?: () => void;
}

const Header = ({ open, handleDrawerToggle }: Props) => {
    const theme = useTheme();
    const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));

    // header content
    const headerContent = useMemo(() => <HeaderContent />, []);

    const iconBackColorOpen =
        theme.palette.mode === "dark" ? "grey.200" : "grey.300";
    const iconBackColor =
        theme.palette.mode === "dark" ? "background.default" : "grey.100";

    // common header
    const mainHeader: ReactNode = (
        <Toolbar>
            <IconButton
                aria-label="open drawer"
                onClick={handleDrawerToggle}
                edge="start"
                variant="light"
                sx={{
                    color: "text.primary",
                    bgColor: open ? iconBackColorOpen : iconBackColor,
                    ml: { xs: 0, lg: -2 },
                }}
            >
                {!open ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            </IconButton>
            {headerContent}
        </Toolbar>
    );

    // app-bar params
    const appBar: AppBarProps = {
        position: "fixed",
        color: "inherit",
        elevation: 0,
        sx: {
            zIndex: 1200,
        },
    };

    return (
        <>
            {!matchDownMD ? (
                <AppBarStyled open={open} {...appBar}>
                    {mainHeader}
                </AppBarStyled>
            ) : (
                <AppBar {...appBar}>{mainHeader}</AppBar>
            )}
        </>
    );
};

export default Header;
