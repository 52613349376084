// ==============================|| OVERRIDES - ALERT TITLE ||============================== //

export default function AlertTitle() {
    return {
        MuiAlertTitle: {
            styleOverrides: {
                root: {
                    marginBottom: 4,
                    marginTop: 0,
                    fontWeight: 400,
                },
            },
        },
    };
}
