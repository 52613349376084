import MainWrapper from "../../components/wrappers/MainWrapper";
import BalancesTable from "../tables/BalancesTable";

const BalancesTablePage = () => {
    return (
        <MainWrapper title="Balances">
            <BalancesTable />
        </MainWrapper>
    );
};

export default BalancesTablePage;
