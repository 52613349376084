import { Grid, IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";

export enum FieldNames {
    NAME = "templateName",
    TITLE = "templateTitle",
}

export const validateInput = (value: string) => {
    if (value.trim() === "") {
        return "Required Field";
    }

    return null;
};

type ContentRowProps = {
    id: number;
    value: string;
    deleteAllowed: boolean;
    handleChange: (id: number, msg: string) => void;
    handleDelete: (index: number) => void;
};

export const ContentRow = ({
    id,
    value,
    deleteAllowed,
    handleChange,
    handleDelete,
}: ContentRowProps) => {
    const [error, setError] = useState<string | null>(null);

    return (
        <Grid container spacing={1} mb={1}>
            <Grid item xs={11} md={11.5}>
                <TextField
                    key={id}
                    fullWidth
                    value={value}
                    onChange={(event) => {
                        handleChange(id, event.target.value ?? "");
                        setError(validateInput(event.target.value));
                    }}
                    error={!!error}
                    helperText={error}
                />
            </Grid>
            <Grid
                item
                xs={1}
                md={0.5}
                display={"flex"}
                justifyContent={"center"}
            >
                <IconButton
                    disabled={!deleteAllowed}
                    onClick={() => handleDelete(id)}
                >
                    <DeleteIcon />
                </IconButton>
            </Grid>
        </Grid>
    );
};

export type AnnouncementProps = {
    isCreateMode: boolean;
    setIsCreateMode: any;
    templateName?: string;
};

export type Template = {
    name: string;
    isLive: boolean;
};
