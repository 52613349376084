import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import LoginForm from "../../forms/LoginForm";
import { formStyles, loginContainer, rightLogo } from "./LoginPageStyles";
import largeLogo from "../../assets/images/autobackorder_logo_login.svg";
import loginBackground from "../../assets/images/login_background.svg";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const LoginPage = () => {
    const navigate = useNavigate();
    const [athorized, setAthorized] = useState(true);
    const auth = useAuth();

    useEffect(() => {
        if (auth.isLogged || auth.user) {
            setAthorized(true);
            navigate("/allBids");
        } else {
            setAthorized(false);
        }
    }, [auth]);

    return !athorized ? (
        <Box
            sx={{
                ...loginContainer,
                backgroundImage: `url(${loginBackground})`,
            }}
        >
            <Box sx={rightLogo}>
                <img src={largeLogo} style={{ width: "inherit" }} />
            </Box>
            <Box sx={formStyles.wrapper}>
                <LoginForm />
            </Box>
        </Box>
    ) : null;
};

export default LoginPage;
