import { Button, Grid, InputLabel, TextField } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import useData from "../hooks/useApi";
import useMessage from "../hooks/useMessage";
import {
    ContentRow,
    FieldNames,
    AnnouncementProps,
    validateInput,
} from "../pages/announcements/Announcement.util";

const AnnouncementForm = ({
    isCreateMode,
    templateName,
    setIsCreateMode,
}: AnnouncementProps) => {
    const { postData } = useData();
    const { showSuccess, showError } = useMessage();

    const nameRef = useRef<HTMLInputElement>();
    const headerRef = useRef<HTMLInputElement>();
    const messageCounter = useRef<number>(0);

    const [messages, setMessages] = useState<{ id: number; msg: string }[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errors, setErrors] = useState<{
        [key in FieldNames]: string | null;
    }>({ [FieldNames.NAME]: null, [FieldNames.TITLE]: null });

    const payload = useMemo(() => {
        const name = nameRef?.current?.value?.trim();
        const header = headerRef?.current?.value?.trim();
        const content = messages.map((item) => item.msg).filter(Boolean);

        if (name && header && content.length > 0) {
            return { name, header, content };
        }

        return null;
    }, [nameRef?.current?.value, headerRef?.current?.value, messages]);

    const addContent = () => {
        const newMessageObj = {
            id: messageCounter.current,
            msg: "",
        };
        messageCounter.current = messageCounter.current + 1;
        setMessages((prevMessages) => [...prevMessages, newMessageObj]);
    };

    const handleChange = (id: number, msg: string) => {
        setMessages((prevMessages) =>
            prevMessages.map((message) =>
                message.id === id ? { ...message, msg: msg } : message,
            ),
        );
    };

    const deleteContent = (id: number) => {
        if (messages.length > 1) {
            setMessages((prevMessages) =>
                prevMessages.filter((message) => message.id !== id),
            );
        }
    };

    const handleValidation = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        idf: FieldNames,
    ) => {
        const inputValue = event.target.value;
        const isError = validateInput(inputValue);

        setErrors((currentErrors) => ({
            ...currentErrors,
            [idf]: isError,
        }));
    };

    const saveNotification = async () => {
        try {
            setIsLoading(true);

            if (payload) {
                const endPoint = "/notifications/createNotification";
                const response: any = await postData(endPoint, payload);

                if (response) {
                    if (response.success) {
                        showSuccess("Announcement created successfully");
                        setIsCreateMode(false);
                    } else {
                        showError("Announcement creation failed");
                    }
                }
            }
        } catch (error) {
            showError("Data Fetching Error");
        } finally {
            setIsLoading(false);
        }
    };

    const updateNotification = async () => {
        try {
            setIsLoading(true);

            if (payload) {
                const endPoint = "/notifications/updateNotification";
                const response: any = await postData(endPoint, payload);

                if (response) {
                    if (response.success) {
                        showSuccess("Announcement updated successfully");
                        setIsCreateMode(false);
                    } else {
                        showError("Announcement update failed");
                    }
                }
            }
        } catch (error) {
            showError("Data Fetching Error");
        } finally {
            setIsLoading(false);
        }
    };

    const loadSelectedTemplate = async (templateName: string) => {
        try {
            const response: any = await postData(
                "/notifications/getSingleNotification",
                { name: templateName },
            );
            if (response?.data && nameRef?.current && headerRef?.current) {
                const { name, header, content } = response.data;
                nameRef.current.value = name || "";
                headerRef.current.value = header || "";

                const tempMessages = [];
                for (const item of content) {
                    tempMessages.push({
                        id: messageCounter.current,
                        msg: item,
                    });
                    messageCounter.current = messageCounter.current + 1;
                }

                setMessages(tempMessages);
            }
        } catch (error) {
            console.log();
        }
    };

    useEffect(() => {
        if (isCreateMode) {
            if (messageCounter.current === 0) {
                addContent();
            }
        } else {
            if (templateName) {
                loadSelectedTemplate(templateName);
            }
        }
    }, [isCreateMode, templateName]);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
                <InputLabel sx={{ marginBottom: 1 }}>Template Name</InputLabel>
                <TextField
                    fullWidth
                    inputRef={(el) => {
                        nameRef.current = el!;
                    }}
                    error={!!errors[FieldNames.NAME]}
                    helperText={errors[FieldNames.NAME]}
                    onChange={(event) =>
                        handleValidation(event, FieldNames.NAME)
                    }
                    disabled={!isCreateMode}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <InputLabel sx={{ marginBottom: 1 }}>Title</InputLabel>
                <TextField
                    fullWidth
                    inputRef={(el) => {
                        headerRef.current = el!;
                    }}
                    error={!!errors[FieldNames.TITLE]}
                    helperText={errors[FieldNames.TITLE]}
                    onChange={(event) =>
                        handleValidation(event, FieldNames.TITLE)
                    }
                />
            </Grid>
            <Grid item container xs={12}>
                <Grid item xs={12}>
                    <InputLabel sx={{ marginBottom: 1 }}>Messages</InputLabel>
                </Grid>
                {messages.map((msgObj) => (
                    <ContentRow
                        key={msgObj.id}
                        id={msgObj.id}
                        value={msgObj.msg}
                        deleteAllowed={messages.length > 1}
                        handleChange={handleChange}
                        handleDelete={deleteContent}
                    />
                ))}
                <Grid item xs={12}>
                    <Button
                        variant="outlined"
                        endIcon={<AddIcon />}
                        sx={{
                            height: "32px",
                            marginTop: "8px",
                        }}
                        onClick={addContent}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
                {isCreateMode && (
                    <Button
                        variant="outlined"
                        sx={{
                            height: "40px",
                            marginRight: "8px",
                            color: "red",
                            borderColor: "red",
                        }}
                        onClick={() => setIsCreateMode(false)}
                    >
                        Cancel
                    </Button>
                )}
                <Button
                    variant="contained"
                    sx={{
                        height: "40px",
                    }}
                    onClick={() => {
                        if (isCreateMode) {
                            saveNotification();
                        } else {
                            updateNotification();
                        }
                    }}
                    disabled={!payload || isLoading}
                >
                    {isCreateMode ? "Save" : "Update"}
                </Button>
            </Grid>
        </Grid>
    );
};

export default AnnouncementForm;
