import { Chip } from "@mui/material";
import {
    AuctionType,
    AuctionTypeColors,
    AuctionTypeTextColors,
} from "../../enums/Status";

const sizes = {
    small: "8px",
    medium: "10px",
    large: "14px",
};
const AuctionTypeStatus = ({
    status,
    size = "small",
}: {
    status: AuctionType;
    size?: "small" | "medium" | "large";
}) => {
    const chipStyles = {
        px: 0,
        py: 0,
        mx: 1,
        borderRadius: "3px",
        fontSize: sizes[size],
        height: 1,
        "&& .MuiChip-labelMedium": {
            px: "4px",
        },
    };
    switch (status) {
        case AuctionType.PRE:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: AuctionTypeColors.PRE,
                        color: AuctionTypeTextColors.WHITE,
                    }}
                    label="PRE"
                />
            );
        case AuctionType.POST:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: AuctionTypeColors.POST,
                        color: AuctionTypeTextColors.WHITE,
                    }}
                    label="POST"
                />
            );
        default:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: AuctionTypeColors.PRE,
                        color: AuctionTypeTextColors.WHITE,
                    }}
                    label="PRE"
                />
            );
    }
};

export default AuctionTypeStatus;
