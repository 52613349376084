import {
    Button,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
} from "@mui/material";
import MainWrapper from "../../components/wrappers/MainWrapper";
import { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import AnnouncementForm from "../../forms/AnnouncementForm";
import useData from "../../hooks/useApi";
import { Template } from "./Announcement.util";
import useMessage from "../../hooks/useMessage";

const AnnouncementPage = () => {
    const { fetchData, postData } = useData();
    const { showSuccess, showError } = useMessage();

    const [isCreateMode, setIsCreateMode] = useState<boolean>(false);
    const [allTemplates, setAllTemplates] = useState<Template[]>([]);
    const [currentTemplate, setCurrentTemplate] = useState<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const isCurrentNotificationLive = (templateName: string): boolean => {
        const chosenTemplate = allTemplates.find(
            (item) => item.name === templateName,
        );

        if (chosenTemplate) {
            return chosenTemplate.isLive;
        }

        return false;
    };

    const loadNotificationTemplates = async () => {
        try {
            const response: any = await fetchData(
                "/notifications/getAllNotifications",
            );
            if (response?.data) {
                const tempAllTemplates: Template[] = response.data;
                const liveTemplate = tempAllTemplates.find(
                    (item) => item.isLive === true,
                );
                setAllTemplates(tempAllTemplates);
                if (liveTemplate) {
                    setCurrentTemplate(liveTemplate.name);
                } else {
                    const latestTemplate =
                        tempAllTemplates[tempAllTemplates.length - 1];
                    setCurrentTemplate(latestTemplate.name);
                }
            } else {
                setAllTemplates([]);
            }
        } catch (error) {
            setAllTemplates([]);
        }
    };

    const changeNotificationState = async (
        templateName: string,
        isNotificationLIve: boolean,
    ) => {
        try {
            setIsLoading(true);

            const endPoint = "/notifications/changeNotificationStatus";

            const response: any = await postData(endPoint, {
                name: templateName,
                isLive: isNotificationLIve,
            });

            if (response) {
                if (response.success === true) {
                    setAllTemplates((prevTemplates) =>
                        prevTemplates.map((template) =>
                            template.name === templateName
                                ? { ...template, isLive: isNotificationLIve }
                                : { ...template, isLive: false },
                        ),
                    );
                    showSuccess(
                        `Announcement is now ${
                            isNotificationLIve ? "Visible" : "Hidden"
                        }`,
                    );
                } else {
                    showError("Announcement update failed");
                }
            }
        } catch (error) {
            showError("Data Fetching Error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        loadNotificationTemplates();
    }, [isCreateMode]);

    return (
        <Grid container direction={"column"} rowSpacing={2}>
            {isCreateMode && (
                <Grid item>
                    <MainWrapper title={"Create Announcement"}>
                        <AnnouncementForm
                            isCreateMode={true}
                            setIsCreateMode={setIsCreateMode}
                        />
                    </MainWrapper>
                </Grid>
            )}
            {!isCreateMode && (
                <>
                    <Grid item>
                        <MainWrapper title="Announcements">
                            <Grid container columnSpacing={3}>
                                <Grid
                                    item
                                    xs={12}
                                    display={"flex"}
                                    justifyContent={"flex-end"}
                                    marginBottom={2}
                                >
                                    <Button
                                        variant="contained"
                                        endIcon={<AddIcon />}
                                        sx={{
                                            height: "40px",
                                        }}
                                        onClick={() => setIsCreateMode(true)}
                                    >
                                        Create Announcement
                                    </Button>
                                </Grid>
                                <Grid item xs={12} marginBottom={2}>
                                    <InputLabel>
                                        Announcement Template
                                    </InputLabel>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    sx={{ marginBottom: { xs: 2, md: 0 } }}
                                >
                                    <Select
                                        value={currentTemplate}
                                        onChange={(event) => {
                                            setCurrentTemplate(
                                                event.target.value,
                                            );
                                        }}
                                        MenuProps={{
                                            PaperProps: {
                                                style: {
                                                    maxHeight: "150px",
                                                    overflowY: "auto",
                                                },
                                            },
                                        }}
                                        sx={{ width: "100%" }}
                                    >
                                        {allTemplates.map((template, index) => (
                                            <MenuItem
                                                key={index}
                                                value={template.name}
                                            >
                                                {template.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Grid>
                                {currentTemplate && (
                                    <Grid
                                        item
                                        xs={12}
                                        md={3}
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        <Switch
                                            checked={isCurrentNotificationLive(
                                                currentTemplate,
                                            )}
                                            onChange={(event) =>
                                                changeNotificationState(
                                                    currentTemplate,
                                                    event.target.checked,
                                                )
                                            }
                                            disabled={isLoading}
                                        />
                                        {isCurrentNotificationLive(
                                            currentTemplate,
                                        )
                                            ? "Announcement Visible"
                                            : "Announcement Not Visible"}
                                    </Grid>
                                )}
                            </Grid>
                        </MainWrapper>
                    </Grid>
                    {currentTemplate && (
                        <Grid item>
                            <MainWrapper title={undefined}>
                                <AnnouncementForm
                                    isCreateMode={false}
                                    templateName={currentTemplate}
                                    setIsCreateMode={setIsCreateMode}
                                />
                            </MainWrapper>
                        </Grid>
                    )}
                </>
            )}
        </Grid>
    );
};

export default AnnouncementPage;
