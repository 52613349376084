import React from "react";
import MainWrapper from "../../components/wrappers/MainWrapper";
import BlacklistTable from "../tables/BlacklistTable";

const BlacklistTablePage = () => {
    return (
        <MainWrapper title="Domain Blacklist">
            <BlacklistTable />
        </MainWrapper>
    );
};

export default BlacklistTablePage;
