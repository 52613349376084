import { Box, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import ArchiveIcon from "@mui/icons-material/Archive";
import AllAuctionsTable from "../tables/AllAuctionsTable";
import { BsCoin } from "react-icons/bs";
import { containerWithTabShift } from "../allBids/AllBidsPage";

const AllAuctionsPage = () => {
    const [pageType, setPageType] = useState<string>("active");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setPageType(newValue);
    };

    return (
        <Box sx={containerWithTabShift}>
            <Tabs
                value={pageType}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab
                    value="active"
                    icon={
                        <BsCoin
                            color={
                                pageType == "active" ? "primary" : "disabled"
                            }
                            style={{
                                paddingRight: "0px",
                                marginTop: "4px",
                                marginRight: "8px",
                                fontSize: "20px",
                            }}
                        />
                    }
                    label="All Auctions"
                    sx={{
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                />
                <Tab
                    value="archived"
                    icon={
                        <ArchiveIcon
                            fontSize="small"
                            color={
                                pageType == "archived" ? "primary" : "disabled"
                            }
                            sx={{
                                paddingRight: "0px",
                                marginTop: "4px",
                                marginRight: "8px",
                            }}
                        />
                    }
                    label="Archive"
                    sx={{
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                />
            </Tabs>

            {pageType == "active" ? (
                <AllAuctionsTable key="active" pageType="active" />
            ) : (
                <AllAuctionsTable key="archived" pageType="archived" />
            )}
        </Box>
    );
};

export default AllAuctionsPage;
