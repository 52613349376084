import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ChecklistIcon from "@mui/icons-material/Checklist";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { Box, Button, Checkbox } from "@mui/material";
import {
    auctionArchiveColMapping,
    auctionColMapping,
    bidColumnMapping,
    stringKeyDict,
} from "../../types/exportTypes";

type SelectDropDownProps = {
    selectedType: "bid" | "auction";
    pageType: "active" | "archived";
    onChange: (value: string[]) => void;
};

const SelectDropDown = (props: SelectDropDownProps) => {
    const { selectedType, pageType, onChange } = props;

    const [selectedRows, setSelectedRows] = useState<boolean[]>();
    const [selectedAll, setSelectedAll] = useState<boolean>(true);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const [columnMap, setColumnMap] = useState<stringKeyDict>();

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    function handleClose() {
        setAnchorEl(null);
    }

    const handleCheckboxChange = (index: number) => {
        if (selectedRows) {
            const newCheckedCheckboxes = [...selectedRows];
            newCheckedCheckboxes[index] = !newCheckedCheckboxes[index];
            setSelectedRows(newCheckedCheckboxes);
        }
    };

    const handleAllSelect = () => {
        if (columnMap) {
            setSelectedRows(
                new Array(Object.keys(columnMap).length).fill(!selectedAll),
            );
            setSelectedAll(!selectedAll);
        }
    };

    // Selecting the Column Mapping Based on the Props
    useEffect(() => {
        if (selectedType === "bid") {
            setColumnMap(bidColumnMapping);
        } else {
            if (pageType === "active") {
                setColumnMap(auctionColMapping);
            } else {
                setColumnMap(auctionArchiveColMapping);
            }
        }
    }, [selectedType, pageType]);

    // Based on the column Map updating the boolean state
    useEffect(() => {
        if (columnMap) {
            const count = Object.keys(columnMap).length;
            setSelectedRows(new Array(count).fill(true));
        }
    }, [columnMap]);

    // Handling selection state change
    useEffect(() => {
        if (columnMap && selectedRows) {
            if (selectedRows.every((value) => value === true)) {
                setSelectedAll(true);
            } else {
                setSelectedAll(false);
            }
            const tempSelectedFields: string[] = [];

            Object.keys(columnMap).forEach((key, index) => {
                if (selectedRows[index]) {
                    tempSelectedFields.push(key);
                }
            });

            onChange(tempSelectedFields);
        }
    }, [selectedRows]);

    return columnMap && selectedRows ? (
        <Box
            sx={{
                mt: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                padding: 0,
                width: "60px",
                my: { xs: 1, sm: 1, md: 0 },
                height: "35px",
                border: "1px solid #d9d9d9",
            }}
            borderRadius={1}
        >
            <Button
                endIcon={<ArrowDropDownIcon fontSize="small" />}
                sx={{
                    mx: 1,
                    height: "35px",
                    my: { xs: 1, sm: 1, md: 0 },
                    width: "100%",
                    color: "#8C8C8C",
                }}
                onClick={handleClick}
            >
                <ChecklistIcon fontSize="small" color="inherit" />
            </Button>
            <Menu
                id="popup-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    transform: "translateX(5px)",
                    "& .MuiList-root": {
                        paddingTop: 0,
                        paddingBottom: 2,
                        width: 200,
                    },
                }}
            >
                <MenuItem sx={{ border: "2px solid #1890FF" }}>
                    <Checkbox
                        checked={selectedAll}
                        onChange={() => handleAllSelect()}
                        sx={{ transform: "scale(0.8)" }}
                    />
                    All
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            marginLeft: "60%",
                        }}
                        onClick={handleClose}
                    >
                        <ArrowDropUpIcon fontSize="small" />
                    </Box>
                </MenuItem>
                {Object.keys(columnMap).map((item, index) => (
                    <MenuItem key={index}>
                        <Checkbox
                            checked={selectedRows[index]}
                            onChange={() => handleCheckboxChange(index)}
                            sx={{ transform: "scale(0.8)" }}
                        />
                        {columnMap[item]}
                    </MenuItem>
                ))}
            </Menu>
        </Box>
    ) : null;
};

export default SelectDropDown;
