import { Link } from "react-router-dom";
import { To } from "history";
import { ButtonBase } from "@mui/material";
import { SxProps } from "@mui/system";
import abLargeIcon from "../../assets/images/logoWithName.webp";
import abIcon from "../../assets/images/logoWithoutName.webp";
import config from "../../configs";

interface Props {
    reverse?: boolean;
    isIcon?: boolean;
    sx?: SxProps;
    to?: To;
}

const LogoSection = ({ isIcon, sx, to }: Props) => (
    <ButtonBase
        disableRipple
        component={Link}
        to={!to ? config.defaultPath : to}
        sx={sx}
    >
        {!isIcon ? (
            <img
                src={abLargeIcon}
                style={{
                    width: "inherit",
                    height: "inherit",
                    backgroundColor: "white",
                }}
            />
        ) : (
            <img src={abIcon} style={{ width: "inherit", height: "inherit" }} />
        )}
    </ButtonBase>
);

export default LogoSection;
