import { AuthAction, AuthState } from "../types/authTypes";

export const enum authStates {
    LOGIN = "login",
    LOGOUT = "logout",
}

const authReducer = (state: AuthState, action: AuthAction) => {
    switch (action.type) {
        case authStates.LOGIN:
            return {
                ...state,
                user: action.payload?.user,
                isLogged: true,
            };
        case authStates.LOGOUT:
            return {
                ...state,
                user: null,
                isLogged: false,
            };
        default:
            return state;
    }
};

export default authReducer;
