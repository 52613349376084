import {
    Box,
    InputLabel,
    FormControl,
    Grid,
    TextField,
    Stack,
    Button,
    useMediaQuery,
} from "@mui/material";

import { DateTime } from "luxon";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import { useEffect, useMemo, useState, useRef } from "react";
import RangePicker from "../../components/filters/RangePicker";
import MultipleSelect from "../../components/filters/MulipleSelect";
import ActionReactTable from "./react-table/ActionReactTable";
import useMessage from "../../hooks/useMessage";
import { bidTableType } from "../../types/tableTypes";
import {
    cellDateAndTime,
    cellAuctionStatus,
    cellCenterAlign,
    cellPreviewBalance,
} from "./CellFormatters";
import useData from "../../hooks/useApi";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SelectDropDown from "../../components/popups/SelectDropDown";
import { CSVLink } from "react-csv";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ArchiveIcon from "@mui/icons-material/Archive";
import PauseIcon from "@mui/icons-material/Pause";
import DeleteIcon from "@mui/icons-material/Delete";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ConfirmationBox from "../../components/common/AlertBoxes/ConfirmationBox";
import { Link } from "react-router-dom";
import AuctionTypeStatus from "../../components/common/AuctionTypeStatus";
import { AuctionType } from "../../enums/Status";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { bidColumnMapping, stringKeyDict } from "../../types/exportTypes";

type AllBidsProps = {
    pageType: "active" | "archived";
};

interface RequestType {
    page: number;
    size: number;
    filterAnd: any[];
    filterOr: any[];
    sort: {
        key: string;
        order: "ASC" | "DESC";
    };
}

interface BidActionRequestType {
    auctionWiseBids: {
        auctionId: number;
        bidIds: number[];
    }[];
    whichField: string;
    nextState: boolean;
}

interface BidDeleteRequestType {
    auctionWiseBids: {
        auctionId: number;
        bidIds: number[];
    }[];
}

const AllBidsTable = (props: AllBidsProps) => {
    const { showError, showSuccess, showInfo } = useMessage();
    const { fetchData, postData } = useData();
    const isXs = useMediaQuery((theme: any) => theme.breakpoints.down("sm"));

    const [availableStatus, setAvailableStatus] = useState<string[]>([
        "Waiting",
        "Inprogress",
    ]);

    const [data, setData] = useState<bidTableType[]>([]);
    const [exportData, setExportData] = useState<stringKeyDict[]>([]);

    const [pageSize, setPageSize] = useState<number>(10);
    const [rowCount, setRowCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [sortBy, setSortBy] = useState<{
        id: string;
        desc: boolean;
    }>({
        id: "bidId",
        desc: true,
    });

    const csvLinkRef = useRef<
        CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }
    >(null);

    const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
    const [selectedRows, setSelectedRows] = useState<boolean[]>([]);
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [openDelete, setOpenDelete] = useState<boolean>(false);

    // All Admin Emails
    const [adminEmails, setAdminEmails] = useState<string[]>([]);

    // Filters & searches
    const [domainSearch, setDomainSearch] = useState<string>("");
    const [usernameSearch, setUsernameSearch] = useState<string>("");
    const [statusFilter, setStatusFilter] = useState<string[]>([]);
    const [pauseFilter, setPauseFilter] = useState<string[]>([]);
    const [typeFilter, setTypeFilter] = useState<string[]>([]);
    const [registryData, setRegistryData] = useState<any | null>();
    const [availableRegistries, setAvailableRegistries] = useState<string[]>(
        [],
    );
    const [registryFilter, setRegistryFilter] = useState<string[]>([]);

    const [resetSwitch, setResetSwitch] = useState<boolean>(false);

    const defaultStartDate = DateTime.now()
        .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
        .minus({ days: 7 })
        .toMillis();

    const defaultEndDate = DateTime.now()
        .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
        .plus({ days: 100 })
        .toMillis();

    const [startDate, setStartDate] = useState(defaultStartDate);
    const [endDate, setEndDate] = useState(defaultEndDate);

    const [showOldBids, setShowOldBids] = useState<boolean>(false);
    const [showUserBids, setShowUserBids] = useState<boolean>(false);

    const searchValues = useRef({
        startDate,
        endDate,
        statusFilter,
        pauseFilter,
        registryFilter,
        domainSearch,
        usernameSearch,
        typeFilter,
    });

    const handleOnDateChange = (value: any) => {
        setStartDate(value[0]);
        setEndDate(value[1]);
    };

    const loadAvailableRegistries = async () => {
        try {
            const availableRegs = await fetchData("/registry");
            setRegistryData(availableRegs ? availableRegs : []);
        } catch {
            setAvailableRegistries([]);
        }
    };

    const loadAdminUsernames = async () => {
        try {
            const adminEmailResponse = await fetchData(
                "/users/getAdminUsernames",
            );
            setAdminEmails(adminEmailResponse ? adminEmailResponse : []);
            setShowUserBids(true);
        } catch {
            setAdminEmails([]);
        }
    };

    const groupByAuction = () => {
        const auctionWiseBids: {
            auctionId: number;
            bidIds: number[];
        }[] = [];

        for (let i = 0; i < data.length; i++) {
            if (selectedRows[i]) {
                const existingEntryIndex = auctionWiseBids.findIndex(
                    (entry) => entry.auctionId === data[i].auctionId,
                );
                if (existingEntryIndex !== -1) {
                    auctionWiseBids[existingEntryIndex].bidIds.push(
                        data[i].bidId,
                    );
                } else {
                    auctionWiseBids.push({
                        auctionId: data[i].auctionId,
                        bidIds: [data[i].bidId],
                    });
                }
            }
        }

        return auctionWiseBids;
    };

    const postBidAction = async (action: string, nextState: boolean) => {
        try {
            if (!selectedRows.every((value) => value === false)) {
                const auctionWiseBids = groupByAuction();

                const bidActionData: BidActionRequestType = {
                    auctionWiseBids: auctionWiseBids,
                    whichField: action,
                    nextState: nextState,
                };

                const bidActionResponse: any = await postData(
                    "/bid/applyBidAction",
                    bidActionData,
                );

                if (bidActionResponse && bidActionResponse?.success) {
                    if (bidActionResponse.failedBids.length == 0) {
                        showSuccess("Bid Action Successful");
                    } else {
                        showInfo(
                            `${bidActionResponse.failedBids.length} bid actions failed`,
                        );
                    }
                } else {
                    showError("All bid actions failed");
                }

                resetView();
            }
        } catch {
            console.log("Bid Action Failed !");
        }
    };

    const postBidDelete = async (selectedBids: boolean[]) => {
        try {
            if (!selectedBids.every((value) => value === false)) {
                const auctionWiseBids = groupByAuction();

                const bidDeleteData: BidDeleteRequestType = {
                    auctionWiseBids: auctionWiseBids,
                };

                const bidDeleteResponse: any = await postData(
                    "/bid/applyBidDelete",
                    bidDeleteData,
                );

                if (bidDeleteResponse && bidDeleteResponse?.success) {
                    if (bidDeleteResponse.failedBids.length == 0) {
                        showSuccess("Bids have been deleted successfully");
                    } else {
                        showError(
                            `${bidDeleteResponse.failedBids.length} bid deletes failed`,
                        );
                    }
                } else {
                    showError("Bid Delete Failed !");
                }

                resetView();
                setOpenDelete(false);
            }
        } catch {
            console.log("Bid Delete Failed !");
        }
    };

    const loadBidData = async (toExport: boolean) => {
        if (!toExport) {
            setLoading(true);
        }
        const userData: RequestType = {
            page: pageIndex + 1,
            size: toExport ? rowCount : pageSize,
            filterAnd: [],
            filterOr: [],
            sort: {
                key: sortBy.id,
                order: sortBy.desc ? "DESC" : "ASC",
            },
        };

        if (props.pageType == "active") {
            userData.filterAnd.push({
                key: "Status",
                operation: "in",
                value: ["Inprogress", "Waiting", "Paused"],
            });
            userData.filterAnd.push({
                // eslint-disable-next-line quotes
                key: "isArchived",
                operation: "=",
                value: false,
            });
            if (searchValues.current.statusFilter.length > 0) {
                userData.filterOr.push({
                    // eslint-disable-next-line quotes
                    key: "Status",
                    operation: "in",
                    value: searchValues.current.statusFilter,
                });
            }
            if (showOldBids) {
                userData.filterAnd.push({
                    key: "isOldBid",
                    operation: "=",
                    value: true,
                });
            }
        } else {
            userData.filterAnd.push({
                key: "Status",
                operation: "in",
                value: [
                    "Archived",
                    "Cancelled",
                    "Paused&Archived",
                    "Success",
                    "Failure",
                ],
            });
            if (searchValues.current.statusFilter.length > 0) {
                userData.filterOr.push({
                    key: "Status",
                    operation: "in",
                    value: searchValues.current.statusFilter.includes("Paused")
                        ? searchValues.current.statusFilter
                              .filter((value) => value != "Paused")
                              .push("Paused&Archived")
                        : searchValues.current.statusFilter,
                });
            }
        }

        if (searchValues.current.pauseFilter.length > 0) {
            if (searchValues.current.pauseFilter.includes("Paused")) {
                if (props.pageType == "active") {
                    userData.filterAnd.push({
                        key: "Status",
                        operation: "in",
                        value: ["Paused"],
                    });
                } else {
                    userData.filterAnd.push({
                        key: "Status",
                        operation: "in",
                        value: ["Paused&Archived"],
                    });
                }
            }
            if (searchValues.current.pauseFilter.includes("Not Paused")) {
                if (props.pageType == "active") {
                    userData.filterAnd.push({
                        key: "Status",
                        operation: "in",
                        value: ["Inprogress", "Waiting"],
                    });
                } else {
                    userData.filterAnd.push({
                        key: "Status",
                        operation: "in",
                        value: ["Success", "Failure", "Archived", "Cancelled"],
                    });
                }
            }
        }

        if (searchValues.current.typeFilter.length > 0) {
            const typeFilterValues = [];
            if (searchValues.current.typeFilter.includes("Pre Auction")) {
                typeFilterValues.push("Pre auction");
            }
            if (searchValues.current.typeFilter.includes("Post Auction")) {
                typeFilterValues.push("Post auction");
            }
            userData.filterAnd.push({
                key: "auctionType",
                operation: "IN",
                value: typeFilterValues,
            });
        }

        if (searchValues.current.registryFilter.length > 0) {
            userData.filterAnd.push({
                key: "Registry",
                operation: "IN",
                value: searchValues.current.registryFilter,
            });
        }

        if (searchValues.current.domainSearch !== "") {
            const domainsList: string[] = searchValues.current.domainSearch
                .split(",")
                .map((str) => str.trim());
            userData.filterAnd.push({
                key: "Domain",
                operation: "IN",
                value: domainsList,
            });
        }

        if (searchValues.current.usernameSearch !== "") {
            userData.filterAnd.push({
                key: "Username",
                operation: "LIKE",
                value: [searchValues.current.usernameSearch + "%"],
            });
        }

        if (showUserBids) {
            userData.filterAnd.push({
                key: "Username",
                operation: "NOT IN",
                value: adminEmails,
            });
        }

        if (searchValues.current.startDate) {
            userData.filterAnd.push({
                // eslint-disable-next-line quotes
                key: "dropTime",
                operation: ">",
                value: [Math.floor(searchValues.current.startDate / 1000)],
            });
        }

        if (searchValues.current.endDate) {
            userData.filterAnd.push({
                // eslint-disable-next-line quotes
                key: "dropTime",
                operation: "<",
                value: [Math.ceil(searchValues.current.endDate / 1000)],
            });
        }

        try {
            const response: any = await postData(
                "/bid/getActiveBids",
                userData,
            );

            switch (toExport) {
                case true:
                    if (response?.data) {
                        const filteredData = response.data.map((item: any) => {
                            const filteredItem: any = {};
                            selectedColumns.forEach((col) => {
                                filteredItem[bidColumnMapping[col]] = item[col];
                            });
                            return filteredItem;
                        });
                        setExportData(filteredData);
                    } else {
                        setExportData([]);
                    }
                    break;
                case false:
                    if (response?.data) {
                        setData(response.data);
                        setSelectedRows(
                            new Array(response.data.length).fill(false),
                        );
                    } else {
                        setData([]);
                    }
                    setRowCount(response?.count ? response.count : 0);
                    break;
            }
        } catch (error) {
            console.log(error);
            showError("Data Fetching Error");
        }
        if (!toExport) {
            setLoading(false);
        }
    };

    function stringifySelectedRows(): string {
        const selectedBids: any = [];

        for (let i = 0; i < data.length; i++) {
            if (selectedRows[i]) {
                selectedBids.push(data[i]);
            }
        }
        return JSON.stringify(selectedBids, null, 2);
    }

    function cellLink({ value, row }: any) {
        if (value) {
            return (
                <Box
                    display={"flex"}
                    justifyContent={"flex-start"}
                    alignItems={"center"}
                >
                    <Link
                        style={{
                            textDecoration: "none",
                            color: "#1890FF",
                            textAlign: "center",
                        }}
                        to={`/admin/perBidView/${row.original.bidId}`}
                    >
                        {value}
                    </Link>
                    <AuctionTypeStatus
                        status={
                            row.original.auctionType == "Pre auction"
                                ? AuctionType.PRE
                                : AuctionType.POST
                        }
                    />
                </Box>
            );
        } else {
            return <></>;
        }
    }

    async function handleCopy() {
        const stringToCopy = stringifySelectedRows();
        try {
            await navigator.clipboard.writeText(stringToCopy);
            showSuccess("Copied to clipboard!");
        } catch (err) {
            showError("Failed to copy!");
        }
    }

    const dataExport = async () => {
        if (data.length === 0) {
            showInfo("No Data Available to Export");
        } else if (selectedColumns.length === 0) {
            showInfo("Select at least 1 Column to Export");
        } else {
            await loadBidData(true);
        }
    };

    const resetView = async () => {
        setLoading(true);
        setStartDate(
            DateTime.now()
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .minus({ days: 7 })
                .toMillis(),
        );
        setEndDate(
            DateTime.now()
                .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
                .plus({ days: 100 })
                .toMillis(),
        );
        setDomainSearch("");
        setStatusFilter([]);
        setPauseFilter([]);
        setRegistryFilter([]);
        setUsernameSearch("");
        setTypeFilter([]);
        setPageIndex(0);
        setSelectedRows(new Array(data.length).fill(false));
        searchValues.current = {
            endDate: DateTime.now()
                .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
                .plus({ days: 100 })
                .toMillis(),
            startDate: DateTime.now()
                .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
                .minus({ days: 7 })
                .toMillis(),
            domainSearch: "",
            pauseFilter: [],
            registryFilter: [],
            statusFilter: [],
            usernameSearch: "",
            typeFilter: [],
        };
        setResetSwitch(!resetSwitch);
        setShowOldBids(false);
        setLoading(false);
    };

    const postOldBidRemove = async () => {
        if (showOldBids && isSelected) {
            try {
                if (!selectedRows.every((value) => value === false)) {
                    const auctionWiseBids = groupByAuction();

                    const oldBidDeletePayload = {
                        auctionWiseBids: auctionWiseBids,
                    };

                    const oldBidDeleteResponse: any = await postData(
                        "/bid/removeOldBids",
                        oldBidDeletePayload,
                    );

                    if (oldBidDeleteResponse && oldBidDeleteResponse?.success) {
                        if (oldBidDeleteResponse.failedBids.length == 0) {
                            showSuccess("Bid Action Successful");
                        } else {
                            showInfo(
                                `${oldBidDeleteResponse.failedBids.length} bids failed`,
                            );
                        }
                    } else {
                        throw "Move to failed state failed";
                    }

                    loadBidData(false);
                }
            } catch (error: any) {
                showError(error);
            }
        }
    };

    // Use Effects

    useEffect(() => {
        if (exportData.length > 0 && selectedColumns.length > 0) {
            csvLinkRef?.current?.link.click();
        }
    }, [exportData]);

    useEffect(() => {
        if (!selectedRows.every((value) => value === false)) {
            setIsSelected(true);
        } else if (!selectedRows.every((value) => value === true)) {
            setIsSelected(false);
        } else {
            setIsSelected(false);
        }
    }, [selectedRows]);

    useEffect(() => {
        setPageIndex(0);
        setSelectedRows([]);
        setAvailableStatus(
            props.pageType == "active"
                ? ["Waiting", "Inprogress"]
                : ["Success", "Failure", "Cancelled", "Archived"],
        );
        setStatusFilter([]);
        setResetSwitch((resetSwitch) => !resetSwitch);
    }, [pageSize, props.pageType]);

    useEffect(() => {
        loadBidData(false);
    }, [sortBy, pageIndex, resetSwitch, showOldBids, showUserBids]);

    useEffect(() => {
        if (registryData?.data?.length > 0) {
            setAvailableRegistries(
                registryData.data.map((item: any) => item.registry),
            );
        }
    }, [registryData]);

    useEffect(() => {
        loadAvailableRegistries();
        loadAdminUsernames();
    }, []);

    const columns = useMemo(
        () => [
            {
                Header: "Domain",
                accessor: "Domain",
                Cell: cellLink,
                canSort: true,
            },
            {
                Header: "Username",
                accessor: "Username",
                Cell: cellCenterAlign,
                canSort: true,
            },
            {
                Header: "Registry",
                accessor: "Registry",
                Cell: cellCenterAlign,
                canSort: true,
            },
            {
                Header: "Priority",
                accessor: "Priority",
                Cell: cellCenterAlign,
                canSort: true,
            },
            {
                Header: "Bid ($)",
                accessor: "Bid",
                Cell: cellPreviewBalance,
                canSort: true,
            },
            {
                Header: "Status",
                accessor: "Status",
                Cell: cellAuctionStatus,
                disableSortBy: true,
            },
            {
                Header: "Drop Date",
                accessor: "dropTime",
                Cell: cellDateAndTime,
                canSort: true,
            },
            {
                Header: "Auction End",
                accessor: "auctionEnd",
                Cell: cellDateAndTime,
                canSort: true,
            },
            {
                Header: "Bidders",
                accessor: "Bidders",
                Cell: cellCenterAlign,
                disableSortBy: true,
            },
        ],
        [],
    );

    return (
        <Grid container>
            <ConfirmationBox
                title={`Confirm removing selected bids from ${
                    props.pageType == "active" ? "All Bids" : "Archive"
                }`}
                subtitle="This action cannot be undone"
                open={openDelete}
                setOpen={setOpenDelete}
                triggerFunction={postBidDelete}
                triggerValue={selectedRows}
            />
            <Grid item xs={12}>
                <Box
                    sx={{
                        display: "flex",
                        paddingTop: 4,
                        paddingBottom: 1,
                    }}
                >
                    <RangePicker
                        startDate={startDate}
                        endDate={endDate}
                        handleOnDateChange={handleOnDateChange}
                        noFutureDates={false}
                        startDateLabel={"Drop Date"}
                        endDateLabel={"Drop Date"}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        my: 1,
                        flexGrow: 1,
                        flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "row",
                        },
                        justifyContent: { xs: "flex-end" },
                        width: { xs: "100%", sm: "100%", md: "100%" },
                    }}
                >
                    <TextField
                        fullWidth
                        id="message"
                        placeholder="Domains"
                        value={domainSearch}
                        multiline
                        rows={3}
                        name="message"
                        autoComplete="message"
                        sx={{
                            width: {
                                xs: "100%",
                                sm: "100%",
                                md: "72%",
                            },
                            paddingRight: { xs: 0, sm: 0, md: 0.2 },
                        }}
                        onKeyDown={(event) => {
                            if (event.key === " ") {
                                event.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            setDomainSearch(e.target.value.toLowerCase());
                        }}
                    />
                    <Stack sx={{ p: 0 }}>
                        <TextField
                            placeholder="Username"
                            sx={{
                                mx: { xs: 0, sm: 0, md: 1 },
                                my: { xs: 1, sm: 1, md: 0 },
                                paddingBottom: {
                                    xs: 0,
                                    sm: 1.3,
                                    md: 1.3,
                                },
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "300px",
                                },
                            }}
                            value={usernameSearch}
                            onKeyDown={(event) => {
                                if (event.key === " ") {
                                    event.preventDefault();
                                }
                            }}
                            onChange={(e) => {
                                setUsernameSearch(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormControl
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "95%",
                                },
                                my: { xs: 1, sm: 1, md: 0 },
                                mx: { xs: 0, sm: 0, md: 1 },
                            }}
                        >
                            <InputLabel
                                id="demo-simple-select-label"
                                sx={{ color: "#bbb", paddingBottom: 2 }}
                            >
                                Registry
                            </InputLabel>
                            <MultipleSelect
                                levels={availableRegistries}
                                fieldValue={registryFilter}
                                onSelect={setRegistryFilter}
                            />
                        </FormControl>
                    </Stack>
                    <Stack
                        sx={{
                            width: {
                                xs: "100%",
                                sm: "100%",
                                md: "40%",
                            },
                            gap: {
                                md: 1.2,
                                sm: 0,
                            },
                        }}
                    >
                        <FormControl
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "100%",
                                },
                                my: { xs: 1, sm: 1, md: 0 },
                                mr: { xs: 0, sm: 0, md: 1 },
                            }}
                        >
                            <InputLabel
                                id="demo-simple-select-label"
                                sx={{ color: "#bbb" }}
                            >
                                Status
                            </InputLabel>
                            <MultipleSelect
                                levels={availableStatus}
                                fieldValue={statusFilter}
                                onSelect={setStatusFilter}
                            />
                        </FormControl>
                        <FormControl
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "100%",
                                },
                                my: { xs: 1, sm: 1, md: 0 },
                            }}
                        >
                            <InputLabel
                                id="demo-simple-select-label"
                                sx={{ color: "#bbb", overflow: "visible" }}
                            >
                                Auction Type
                            </InputLabel>
                            <MultipleSelect
                                levels={["Pre Auction", "Post Auction"]}
                                fieldValue={typeFilter}
                                onSelect={setTypeFilter}
                            />
                        </FormControl>
                    </Stack>
                    <FormControl
                        sx={{
                            width: {
                                xs: "100%",
                                sm: "100%",
                                md: "40%",
                            },
                            my: { xs: 1, sm: 1, md: 0 },
                            ml: { xs: 0, sm: 0, md: 1 },
                        }}
                    >
                        <InputLabel
                            id="demo-simple-select-label"
                            sx={{ color: "#bbb" }}
                        >
                            Paused
                        </InputLabel>
                        <MultipleSelect
                            levels={["Paused", "Not Paused"]}
                            fieldValue={pauseFilter}
                            onSelect={setPauseFilter}
                        />
                    </FormControl>
                </Box>
                <Grid container rowSpacing={1} marginTop={1}>
                    <Grid
                        item
                        xs={12}
                        sm={7}
                        container
                        columnSpacing={1}
                        justifyContent={{ xs: "center", sm: "flex-start" }}
                    >
                        <Grid item xs={12} sm={6} md={4}>
                            <Button
                                variant="text"
                                startIcon={
                                    showUserBids ? (
                                        <CheckBoxIcon />
                                    ) : (
                                        <CheckBoxOutlineBlankIcon />
                                    )
                                }
                                sx={{
                                    height: "35px",
                                    width: { xs: "100%", sm: "200px" },
                                    color: showUserBids ? "#1890ff" : "#bbbbbb",
                                    textTransform: "None",
                                    padding: 0,
                                    "&:hover": {
                                        color: "#1890ff",
                                        backgroundColor: "transparent",
                                    },
                                }}
                                onClick={() => setShowUserBids((prev) => !prev)}
                            >
                                {"Show Bids from Users"}
                            </Button>
                        </Grid>
                        {props.pageType === "active" && (
                            <Grid item xs={12} sm={6} md={4}>
                                <Button
                                    variant="text"
                                    startIcon={
                                        showOldBids ? (
                                            <CheckBoxIcon />
                                        ) : (
                                            <CheckBoxOutlineBlankIcon />
                                        )
                                    }
                                    sx={{
                                        height: "35px",
                                        width: { xs: "100%", sm: "200px" },
                                        color: showOldBids
                                            ? "#1890ff"
                                            : "#bbbbbb",
                                        textTransform: "None",
                                        padding: 0,
                                        "&:hover": {
                                            color: "#1890ff",
                                            backgroundColor: "transparent",
                                        },
                                    }}
                                    onClick={() =>
                                        setShowOldBids((prev) => !prev)
                                    }
                                >
                                    {"Show Bids with No Results"}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        sm={5}
                        container
                        justifyContent={{ xs: "center", sm: "flex-end" }}
                        columnSpacing={1}
                        marginTop={{ xs: 1, sm: 0 }}
                    >
                        <Grid item xs={6} sm={4} md={3}>
                            <Button
                                variant="outlined"
                                color="error"
                                sx={{
                                    height: "35px",
                                    width: "100%",
                                    "&:hover": {
                                        color: "#ff4d4f",
                                        borderColor: "#ff4d4f",
                                    },
                                }}
                                onClick={() => {
                                    resetView();
                                }}
                            >
                                Reset
                            </Button>
                        </Grid>
                        <Grid item xs={6} sm={4} md={3}>
                            <Button
                                variant="contained"
                                endIcon={<SearchIcon />}
                                sx={{
                                    mx: 0,
                                    height: "35px",
                                    width: "100%",
                                }}
                                onClick={() => {
                                    setPageIndex(0);
                                    searchValues.current = {
                                        endDate,
                                        startDate,
                                        domainSearch,
                                        pauseFilter,
                                        registryFilter,
                                        statusFilter,
                                        usernameSearch,
                                        typeFilter,
                                    };
                                    loadBidData(false);
                                }}
                            >
                                Search
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        mb: {
                            xs: 1,
                            sm: 3,
                            md: 3,
                        },
                        mt: 1,
                        flexGrow: 1,
                        flexDirection: { xs: "column", sm: "row" },
                        justifyContent:
                            props.pageType === "active"
                                ? undefined
                                : "flex-end",
                        width: {
                            xs: "100%",
                            sm: "100%",
                            md: "100%",
                        },
                    }}
                ></Box>
                <Box
                    sx={{
                        display: "flex",
                        my: 1,
                        flexGrow: 1,
                        flexDirection: {
                            xs: "row",
                            sm: "row",
                            md: "row",
                        },
                        justifyContent: { xs: "flex-start" },
                        width: {
                            xs: "100%",
                            sm: "100%",
                            md: "100%",
                        },
                        paddingRight: "0px",
                    }}
                >
                    <SelectDropDown
                        selectedType="bid"
                        pageType={props.pageType}
                        onChange={setSelectedColumns}
                    />
                    <CSVLink
                        data={exportData}
                        className="exportButton"
                        filename="BidData.csv"
                        ref={csvLinkRef}
                    ></CSVLink>
                    <Button
                        variant="contained"
                        endIcon={isXs ? undefined : <FileDownloadIcon />}
                        sx={{
                            height: "35px",
                            my: { xs: 1, sm: 1, md: 0 },
                            width: "100px",
                            marginLeft: "5px",
                            marginRight:
                                props.pageType === "active"
                                    ? "max(calc(100% - 400px), 5px)"
                                    : "max(calc(100% - 265px), 5px)",
                        }}
                        onClick={() => {
                            dataExport();
                        }}
                    >
                        Export
                    </Button>
                    {props.pageType == "active" && (
                        <Box
                            sx={{
                                marginRight: "5px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                padding: 0,
                                cursor:
                                    isSelected && showOldBids
                                        ? "pointer"
                                        : "auto",
                                my: { xs: 1, sm: 1, md: 0 },
                                width: "130px",
                                height: "35px",
                                border:
                                    isSelected && showOldBids
                                        ? "1px solid #1890ff"
                                        : "1px solid #d9d9d9",
                                color:
                                    isSelected && showOldBids
                                        ? "#1890ff"
                                        : "#d9d9d9",
                            }}
                            borderRadius={1}
                            onClick={() => postOldBidRemove()}
                        >
                            {isXs ? "Fail" : "Move to Failed"}
                        </Box>
                    )}
                    <Box
                        sx={{
                            marginRight: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 0,
                            cursor: isSelected ? "pointer" : "auto",
                            width: "30px",
                            my: { xs: 1, sm: 1, md: 0 },
                            height: "35px",
                            border: isSelected
                                ? "1px solid #1890ff"
                                : "1px solid #d9d9d9",
                        }}
                        borderRadius={1}
                        onClick={() => handleCopy()}
                    >
                        <ContentCopyIcon
                            color={isSelected ? "primary" : "disabled"}
                            sx={{ transform: "scale(0.7)" }}
                        />
                    </Box>
                    <Box
                        sx={{
                            marginRight: "5px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 0,
                            cursor: isSelected ? "pointer" : "auto",
                            width: "30px",
                            my: { xs: 1, sm: 1, md: 0 },
                            height: "35px",
                            border: isSelected
                                ? "1px solid #1890ff"
                                : "1px solid #d9d9d9",
                        }}
                        borderRadius={1}
                        onClick={() =>
                            postBidAction(
                                "isArchived",
                                props.pageType === "active" ? true : false,
                            )
                        }
                    >
                        {props.pageType === "active" ? (
                            <ArchiveIcon
                                color={isSelected ? "primary" : "disabled"}
                                sx={{ transform: "scale(0.7)" }}
                            />
                        ) : (
                            <UnarchiveIcon
                                color={isSelected ? "primary" : "disabled"}
                                sx={{ transform: "scale(0.7)" }}
                            />
                        )}
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            padding: 0,
                            cursor: isSelected ? "pointer" : "auto",
                            width: "30px",
                            my: { xs: 1, sm: 1, md: 0 },
                            height: "35px",
                            border: isSelected
                                ? "1px solid #1890ff"
                                : "1px solid #d9d9d9",
                        }}
                        borderRadius={1}
                        onClick={() => {
                            if (props.pageType === "active") {
                                postBidAction("isPaused", true);
                            } else {
                                setOpenDelete(true);
                            }
                        }}
                    >
                        {props.pageType === "active" ? (
                            <PauseIcon
                                color={isSelected ? "primary" : "disabled"}
                                sx={{ transform: "scale(0.7)" }}
                            />
                        ) : (
                            <DeleteIcon
                                color={isSelected ? "primary" : "disabled"}
                                sx={{ transform: "scale(0.7)" }}
                            />
                        )}
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12} style={{ overflowX: "auto" }}>
                <div style={{ width: "100%" }}>
                    <ActionReactTable
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        columns={columns}
                        data={data}
                        setPageIndexProp={setPageIndex}
                        setPageSizeProp={setPageSize}
                        setSortByProp={setSortBy}
                        loading={loading}
                        rowCount={rowCount}
                        selectedRows={selectedRows}
                        setSelectedRowsProp={setSelectedRows}
                        whichPage={props.pageType}
                        handleBidAction={postBidAction}
                        setDeleteConfirmation={setOpenDelete}
                    />
                </div>
            </Grid>
        </Grid>
    );
};

export default AllBidsTable;
