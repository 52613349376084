import MainWrapper from "../../components/wrappers/MainWrapper";
import SubmissionsTable from "../tables/SubmissionsTable";

const SubmissionsPage = () => {
    return (
        <MainWrapper title="Submissions">
            <SubmissionsTable />
        </MainWrapper>
    );
};

export default SubmissionsPage;
