import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack,
} from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { BsXLg } from "react-icons/bs";

const ConfirmationBox = ({
    open,
    setOpen,
    triggerFunction,
    triggerValue,
    title,
    subtitle,
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    triggerFunction: (value: any) => void;
    triggerValue: any;
    title: string;
    subtitle: string;
}) => {
    function handleClose() {
        setOpen(false);
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                "&& .MuiDialog-paperScrollPaper": {
                    borderRadius: 0,
                    minWidth: { md: "500px" },
                },
            }}
        >
            <Stack
                direction="row"
                sx={{ width: "100%", justifyContent: "flex-end", pr: 2, pt: 2 }}
            >
                <BsXLg onClick={handleClose} style={{ cursor: "pointer" }} />
            </Stack>
            <Box
                sx={{
                    p: 1,
                    pb: 2.5,
                    pt: 1,
                }}
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{
                        color: "#3A3541CC",
                        fontWeight: 600,
                        pt: 1,
                        pb: 0,
                    }}
                >
                    {title}
                </DialogTitle>
                <DialogContent sx={{ my: 0, py: 0 }}>
                    <DialogContentText
                        id="alert-dialog-description"
                        sx={{ color: "#3A3541CC", mb: 2, py: 0 }}
                    >
                        {subtitle}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ mr: 1.5 }}>
                    <Button
                        variant="outlined"
                        onClick={handleClose}
                        sx={{ borderColor: "#1890FF", height: "30px" }}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={() => triggerFunction(triggerValue)}
                        autoFocus
                        sx={{ bgcolor: "#1890FF", height: "30px" }}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default ConfirmationBox;
