import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { auctionDetailsType } from "../../types/pageTypes";
import {
    cellAuctionTypeChip,
    cellDateAndTime,
} from "../../pages/tables/CellFormatters";
import DomainTypeStatus from "../common/DomainTypeStatus";
import { AuctionType } from "../../enums/Status";
import { Link } from "react-router-dom";
import InfoChip from "../common/InfoChip";

const titleStyle = {
    fontWeight: 700,
};

const AuctionInfo = ({ response }: { response: auctionDetailsType }) => {
    return (
        <Box sx={{ bgcolor: "#F9F9F9", px: 2, py: 2 }}>
            <Grid container display={"flex"}>
                <Grid item xs={12} sm={12} display={"flex"} mb={2}>
                    <Typography sx={titleStyle} width={"150px"}>
                        Domain Status :
                    </Typography>
                    <DomainTypeStatus
                        size="medium"
                        status={response.domainStatus}
                    />
                </Grid>
                <Grid item xs={12} sm={12} display={"flex"} mb={2}>
                    <Typography sx={titleStyle} width={"150px"}>
                        Auction Status :
                    </Typography>
                    <Typography>
                        {cellAuctionTypeChip(
                            response.auctionStatus,
                            response.isArchived,
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} display={"flex"} mb={2}>
                    <Typography sx={titleStyle} width={"150px"}>
                        Winning Price :
                    </Typography>
                    <Typography>{response.winningPrice}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} display={"flex"} mb={2}>
                    <Typography sx={titleStyle} width={"150px"}>
                        Winners :
                    </Typography>
                    <Box
                        sx={{
                            overflowY: "auto",
                            maxHeight: "120px",
                            width: "80%",
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                        }}
                    >
                        {response.winners?.map((winner) => (
                            <InfoChip key={winner}>{winner}</InfoChip>
                        ))}
                    </Box>
                    {/* <Typography>{response.winners?.join(", ")}</Typography> */}
                </Grid>
                <Grid item xs={12} sm={12} display={"flex"} mb={2}>
                    <Typography sx={titleStyle} width={"150px"}>
                        Auction Start :
                    </Typography>
                    {cellDateAndTime({ value: response.auctionStart })}
                </Grid>
                <Grid item xs={12} sm={12} display={"flex"} mb={2}>
                    <Typography sx={titleStyle} width={"150px"}>
                        Auction End :
                    </Typography>
                    <Typography>
                        {cellDateAndTime({ value: response.auctionEnd })}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} display={"flex"} mb={2}>
                    <Typography sx={titleStyle} width={"150px"}>
                        Drop Time :
                    </Typography>
                    <Typography>
                        {cellDateAndTime({ value: response.dropTime })}
                    </Typography>
                </Grid>
                {response && response.refAuctionId && (
                    <Grid item xs={12} sm={12} display={"flex"} mb={2}>
                        <Typography sx={titleStyle} width={"150px"}>
                            {response.auctionType === AuctionType.POST
                                ? "Pre-Auction ID :"
                                : "Post-Auction ID :"}
                        </Typography>
                        <Link
                            style={{ textDecoration: "none" }}
                            to={`/perAuctionView/${response.refAuctionId}`}
                        >
                            <Typography
                                sx={{
                                    color: "#1890FF",
                                    textDecoration: "none",
                                }}
                            >
                                #{response.refAuctionId}
                            </Typography>
                        </Link>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
};
export default AuctionInfo;
