import {
    Box,
    Button,
    InputLabel,
    FormControl,
    Grid,
    TextField,
} from "@mui/material";

import { DateTime } from "luxon";
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import InputAdornment from "@mui/material/InputAdornment";
import React, { useEffect, useMemo, useState } from "react";
import ScrollX from "../../components/ScrollX";
import RangePicker from "../../components/filters/RangePicker";
import MultipleSelect from "../../components/filters/MulipleSelect";
import ReactTable from "./react-table/ReactTable";
import { Link } from "react-router-dom";
import useMessage from "../../hooks/useMessage";
import { usersTableDataType } from "../../types/tableTypes";
import { cellDateAndTime, cellStatusChip, cellSeeMore } from "./CellFormatters";
import useData from "../../hooks/useApi";

const MessagesTable = () => {
    const { showError } = useMessage();
    const { postData } = useData();
    const [data, setData] = useState<usersTableDataType[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [rowCount, setRowCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [sortBy, setSortBy] = useState<{
        id: string;
        desc: boolean;
    }>({
        id: "sentAt",
        desc: true,
    });

    // Filters & searches
    const [receiverSearch, setReceiverSearch] = useState<string>("");
    const [levelFilter, setLevelFilter] = useState<string[]>([]);

    const [startDate, setStartDate] = useState(
        DateTime.now()
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .toMillis() - 86400000,
    );
    const [endDate, setEndDate] = useState(
        DateTime.now()
            .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
            .toMillis(),
    );

    const handleOnDateChange = (value: any) => {
        setStartDate(value[0]);
        setEndDate(value[1]);
    };
    interface RequestType {
        page: number;
        size: number;
        filterAnd: any[];
        sort: {
            key: string;
            order: "ASC" | "DESC";
        };
    }

    const loadMessageData = async () => {
        setLoading(true);
        const userData: RequestType = {
            page: pageIndex + 1,
            size: pageSize,
            filterAnd: [],
            sort: {
                key: sortBy.id,
                order: sortBy.desc ? "DESC" : "ASC",
            },
        };

        if (levelFilter.length > 0) {
            userData.filterAnd.push({
                key: "level",
                operation: "IN",
                value: levelFilter,
            });
        }

        if (receiverSearch !== "") {
            userData.filterAnd.push({
                key: "username",
                operation: "LIKE",
                value: [receiverSearch + "%"],
            });
        }

        if (startDate) {
            userData.filterAnd.push({
                key: "sentAt",
                operation: ">",
                value: [Math.floor(startDate / 1000)],
            });
        }

        if (endDate) {
            userData.filterAnd.push({
                key: "sentAt",
                operation: "<",
                value: [Math.ceil(endDate / 1000)],
            });
        }

        try {
            const response: any = await postData(
                "/messages/getAllMessages",
                userData,
            );
            if (response?.data) {
                setData(response.data);
            } else {
                setData([]);
            }
            setRowCount(response?.count ? response.count : 0);
        } catch (error) {
            showError("Data Fetching Error");
        }
        setLoading(false);
    };

    useEffect(() => {
        setPageIndex(0);
        loadMessageData();
    }, [levelFilter, receiverSearch, startDate, endDate, pageSize]);

    useEffect(() => {
        loadMessageData();
    }, [sortBy, pageIndex]);

    const columns = useMemo(
        () => [
            {
                Header: "Receiver",
                accessor: "receiver.username",
                disableSortBy: true,
            },
            {
                Header: "Message",
                accessor: "message",
                disableSortBy: true,
                Cell: cellSeeMore,
            },
            {
                Header: "Level",
                accessor: "level",
                disableSortBy: true,
                Cell: cellStatusChip,
            },
            {
                Header: "Viewed at",
                accessor: "viewedAt",
                Cell: cellDateAndTime,
                canSort: true,
            },
            {
                Header: "Sent at",
                accessor: "sentAt",
                Cell: cellDateAndTime,
                canSort: true,
            },
        ],
        [],
    );

    return (
        <Grid container>
            <Box
                sx={{
                    mb: 4,
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: { xs: "column-reverse", sm: "row" },
                    flexGrow: 1,
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        my: 1,
                        flexGrow: 1,
                        justifyContent: { xs: "flex-end", sm: "flex-end" },
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            my: 1,
                            pr: 1,
                            flexGrow: 1,
                            flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "row",
                            },
                            justifyContent: { xs: "flex-end" },
                        }}
                    >
                        <TextField
                            placeholder="Receiver"
                            sx={{
                                mx: { xs: 0, md: 1 },
                                mb: { xs: 2, md: 0 },
                                width: { xs: "100%", md: "300px" },
                            }}
                            value={receiverSearch}
                            onKeyDown={(event) => {
                                if (event.key === " ") {
                                    event.preventDefault();
                                }
                            }}
                            onChange={(e) => {
                                setReceiverSearch(e.target.value);
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormControl
                            sx={{
                                width: { xs: "100%", md: "285px" },
                                mb: { xs: 2, md: 0 },
                                mx: { xs: 0, md: 1 },
                                paddingRight: { xs: 0, md: 1 },
                            }}
                        >
                            <InputLabel
                                id="demo-simple-select-label"
                                sx={{ color: "#bbb" }}
                            >
                                Level
                            </InputLabel>
                            <MultipleSelect
                                levels={["info", "warning", "danger"]}
                                fieldValue={levelFilter}
                                onSelect={setLevelFilter}
                            />
                        </FormControl>

                        <RangePicker
                            startDate={startDate}
                            endDate={endDate}
                            handleOnDateChange={handleOnDateChange}
                            noFutureDates={true}
                        />

                        <Link to={"/admin/messages/create"}>
                            <Button
                                variant="contained"
                                endIcon={<AddIcon />}
                                sx={{
                                    mx: { xs: 0, sm: 0, md: 1 },
                                    height: "41.13px",
                                    whiteSpace: "nowrap",
                                    width: { xs: "100%", md: "95%" },
                                    marginTop: { xs: 2, md: 0 },
                                }}
                            >
                                New Message
                            </Button>
                        </Link>
                    </Box>
                </Box>
            </Box>
            <Grid item xs={12}>
                <ScrollX>
                    <ReactTable
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        columns={columns}
                        data={data}
                        setPageIndexProp={setPageIndex}
                        setPageSizeProp={setPageSize}
                        setSortByProp={setSortBy}
                        loading={loading}
                        rowCount={rowCount}
                    />
                </ScrollX>
            </Grid>
        </Grid>
    );
};

export default MessagesTable;
