import {
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Box } from "@mui/material";
import {
    ErrorSwitchType,
    NameserverInputSectionType,
} from "../../types/profileSettingsTypes";
import { Dispatch, SetStateAction } from "react";
import { nameserverRegex } from "../../data/profileData";
import { getValue, trimSpaces } from "../../utils/helpers";

const styles = {
    formControlStyle: {
        my: 1,
        minWidth: 100,
    },
    inputGroup: {
        my: { md: 0, xs: 1 },
        ml: { md: 5, xs: 0 },
    },
};

const ProfileFormSectionDomains = ({
    title,
    disabled = false,
    data,
    setData,
    errors,
    setErrors,
    errorsSwitch,
}: {
    title: string;
    disabled?: boolean;
    data: NameserverInputSectionType;
    setData: Dispatch<SetStateAction<NameserverInputSectionType>>;
    errors: NameserverInputSectionType;
    setErrors: Dispatch<SetStateAction<NameserverInputSectionType>>;
    errorsSwitch: ErrorSwitchType;
}) => {
    const ErrorMessage = ({ children }: { children: string }) =>
        errorsSwitch.nameservers ? (
            <FormHelperText sx={{ mx: 0 }} error>
                {children}
            </FormHelperText>
        ) : (
            <></>
        );
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                mt: 2,
            }}
        >
            <Typography
                sx={{
                    fontSize: { sm: "18px", xs: "12px" },
                    fontWeight: 600,
                    color: "#3A3541CC",
                    mr: 1,
                    mb: 2,
                }}
            >
                {title}
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <Stack>
                        <InputLabel>Nameserver 1</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <TextField
                                fullWidth
                                id="nameserver1"
                                name="nameserver1"
                                disabled={disabled}
                                error={
                                    errorsSwitch.nameservers &&
                                    errors.nameserver1
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    const value = trimSpaces(
                                        getValue(e.target.value),
                                    );
                                    setData((data) => ({
                                        ...data,
                                        nameserver1: value,
                                    }));
                                    if (value === "")
                                        setErrors((errors) => ({
                                            ...errors,
                                            nameserver1: "Required Field",
                                        }));
                                    else {
                                        if (!nameserverRegex.test(value))
                                            setErrors((errors) => ({
                                                ...errors,
                                                nameserver1:
                                                    "Invalid Nameserver",
                                            }));
                                        else {
                                            if (value === data.nameserver2) {
                                                setErrors((errors) => ({
                                                    ...errors,
                                                    nameserver2:
                                                        "Duplicate Nameserver",
                                                }));
                                            } else {
                                                if (
                                                    errors.nameserver2 ===
                                                    "Duplicate Nameserver"
                                                ) {
                                                    setErrors((errors) => ({
                                                        ...errors,
                                                        nameserver2: "",
                                                    }));
                                                }
                                                setErrors((errors) => ({
                                                    ...errors,
                                                    nameserver1: "",
                                                }));
                                            }
                                        }
                                    }
                                }}
                                value={data.nameserver1}
                            />
                            <ErrorMessage>{errors.nameserver1}</ErrorMessage>
                        </FormControl>
                    </Stack>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <Stack>
                        <InputLabel>Nameserver 2</InputLabel>
                        <FormControl sx={styles.formControlStyle}>
                            <TextField
                                fullWidth
                                id="nameserver2"
                                name="nameserver2"
                                disabled={disabled}
                                error={
                                    errorsSwitch.nameservers &&
                                    errors.nameserver2
                                        ? true
                                        : false
                                }
                                onChange={(e) => {
                                    const value = trimSpaces(
                                        getValue(e.target.value),
                                    );
                                    setData((data) => ({
                                        ...data,
                                        nameserver2: value,
                                    }));
                                    if (value === "")
                                        setErrors((errors) => ({
                                            ...errors,
                                            nameserver2: "Required Field",
                                        }));
                                    else {
                                        if (!nameserverRegex.test(value))
                                            setErrors((errors) => ({
                                                ...errors,
                                                nameserver2:
                                                    "Invalid Nameserver",
                                            }));
                                        else {
                                            if (value === data.nameserver1) {
                                                setErrors((errors) => ({
                                                    ...errors,
                                                    nameserver2:
                                                        "Duplicate Nameserver",
                                                }));
                                            } else {
                                                setErrors((errors) => ({
                                                    ...errors,
                                                    nameserver2: "",
                                                }));
                                            }
                                        }
                                    }
                                }}
                                value={data.nameserver2}
                            />
                            <ErrorMessage>{errors.nameserver2}</ErrorMessage>
                        </FormControl>
                    </Stack>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ProfileFormSectionDomains;
