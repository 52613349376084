import MainWrapper from "../../components/wrappers/MainWrapper";
import CreateMessageForm from "../../forms/CreateMessageForm";

const CreateMessage = () => {
    return (
        <MainWrapper title="Create Message">
            <CreateMessageForm />
        </MainWrapper>
    );
};

export default CreateMessage;
