import { Box, Tabs, Tab } from "@mui/material";
import React, { useState } from "react";
import AllBidsTable from "../tables/AllBidsTable";
import PublicIcon from "@mui/icons-material/Public";
import ArchiveIcon from "@mui/icons-material/Archive";

const tabStyle = {
    paddingTop: "0px",
    paddingRight: "0px",
    marginRight: "8px",
};

export const containerWithTabShift = {
    paddingY: "12px",
    paddingX: "24px",
    bgcolor: "white",
};

const AllBidsPage = () => {
    const [pageType, setPageType] = useState<string>("active");

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setPageType(newValue);
    };

    return (
        <Box sx={containerWithTabShift}>
            <Tabs
                value={pageType}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
            >
                <Tab
                    value="active"
                    icon={
                        <PublicIcon
                            fontSize="small"
                            color={
                                pageType == "active" ? "primary" : "disabled"
                            }
                            sx={tabStyle}
                        />
                    }
                    label="All Bids"
                    sx={{
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                />
                <Tab
                    value="archived"
                    icon={
                        <ArchiveIcon
                            fontSize="small"
                            color={
                                pageType == "archived" ? "primary" : "disabled"
                            }
                            sx={tabStyle}
                        />
                    }
                    label="Archive"
                    sx={{
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                />
            </Tabs>

            {pageType == "active" ? (
                <AllBidsTable key={"active"} pageType="active" />
            ) : (
                <AllBidsTable key={"archived"} pageType="archived" />
            )}
        </Box>
    );
};

export default AllBidsPage;
