import { Chip } from "@mui/material";
import { Link } from "react-router-dom";

const InfoChip = ({
    children,
    hasLink = true,
}: {
    children: string;
    hasLink?: boolean;
}) =>
    hasLink ? (
        <Link to={`/admin/users/${children}`}>
            <Chip
                sx={{
                    mr: 1,
                    // my: 0.5,
                    "&& .MuiChip-label": { mx: 1, p: 0 },
                    height: "25px",
                }}
                label={children}
            />
        </Link>
    ) : (
        <Chip
            sx={{
                mr: 1,
                my: 0.5,
                "&& .MuiChip-label": { mx: 1, p: 0 },
                height: "25px",
            }}
            label={children}
        />
    );

export default InfoChip;
