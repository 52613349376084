import { TextField, InputAdornment } from "@mui/material";
import Big from "big.js";
import { Dispatch } from "react";

type CurrencyTypeProps = {
    currentValue: string;
    onCurrencyType: Dispatch<React.SetStateAction<string>>;
    error: boolean;
    onChange: (value: string) => void;
};

const NumberType = ({
    currentValue,
    onCurrencyType,
    error,
    onChange,
}: CurrencyTypeProps) => {
    return (
        <TextField
            value={currentValue}
            placeholder="0.00"
            error={error}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" sx={{ marginLeft: "15px" }}>
                        $
                    </InputAdornment>
                ),
            }}
            style={{ textAlign: "right" }}
            onChange={(event) => {
                onChange(event.target.value);
            }}
            onBlur={(e) => {
                const text = e.target.value;
                let value = text;
                if (!text) {
                    value = "0";
                } else if (text.endsWith(".")) {
                    value = text.split(".")[0];
                }
                try {
                    onCurrencyType(new Big(value).toFixed(2));
                } catch (e) {
                    onCurrencyType(new Big(0).toFixed(2));
                }
            }}
        />
    );
};

export default NumberType;
