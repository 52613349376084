import UsersTable from "../tables/UsersTable";
import MainWrapper from "../../components/wrappers/MainWrapper";

const UsersTablePage = () => {
    return (
        <MainWrapper title="Users">
            <UsersTable />
        </MainWrapper>
    );
};

export default UsersTablePage;
