import { useEffect, useMemo, useState } from "react";

import { Box, Button, Grid, Stack, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import ScrollX from "../../components/ScrollX";
import ReactTable from "./react-table/ReactTable";
import { usersTableDataType } from "../../types/tableTypes";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import { Link } from "react-router-dom";
import { cellCenterAlign, cellRightAlign } from "./CellFormatters";

interface RequestType {
    page: number;
    size: number;
    filterAnd: any[];
    sort: {
        key: string;
        order: "ASC" | "DESC";
    };
}

const BalancesTable = () => {
    const { postData } = useData();
    const { showError } = useMessage();
    const [data, setData] = useState<usersTableDataType[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<{
        id: string;
        desc: boolean;
    }>({
        id: "id",
        desc: false,
    });

    // Filters & searches
    const [username, setUsername] = useState<string>("");
    const [email, setEmail] = useState<string>("");

    const [loading, setLoading] = useState<boolean>(false);
    const [shouldReload, setShouldReload] = useState<number>(Date.now());

    function cellLink({ value }: any) {
        const row = data.find((row) => row.username === value);
        if (value && row) {
            return (
                <Link
                    style={{
                        textDecoration: "none",
                        color: "#1890FF",
                    }}
                    to={`/balances/${row.username}`}
                >
                    {value}
                </Link>
            );
        } else {
            return <></>;
        }
    }

    const fetchData = async () => {
        try {
            setLoading(true);

            const userData: RequestType = {
                page: pageIndex + 1,
                size: pageSize,
                filterAnd: [],
                sort: {
                    key: sortBy.id,
                    order: sortBy.desc ? "DESC" : "ASC",
                },
            };

            if (username !== "") {
                userData.filterAnd.push({
                    key: "username",
                    operation: "LIKE",
                    value: `%${username}%`,
                });
            }

            if (email !== "") {
                userData.filterAnd.push({
                    key: "email",
                    operation: "LIKE",
                    value: `%${email}%`,
                });
            }

            const response: any = await postData(
                "/transaction/getUsersBalance",
                userData,
            );

            if (response?.data) {
                setData(response.data);
            } else {
                setData([]);
            }

            setRowCount(response?.count ? response?.count : 0);
        } catch (error: any) {
            console.log("Error occurred when retrieving Balance data", error);
            showError("Data Fetching Error");
        } finally {
            setLoading(false);
        }
    };

    const resetPageConfig = async () => {
        setPageIndex(0);
        setPageSize(10);
    };

    useEffect(() => {
        setPageIndex(0);
        setShouldReload(Date.now());
    }, [pageSize]);

    useEffect(() => {
        fetchData();
    }, [pageIndex, shouldReload]);

    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                disableSortBy: true,
                width: "100px",
                Cell: cellCenterAlign,
            },
            {
                Header: "Username",
                accessor: "username",
                Cell: cellLink,
                disableSortBy: true,
                align: "left",
            },
            {
                Header: "Email",
                accessor: "email",
                disableSortBy: true,
                align: "left",
            },
            {
                Header: "Balance ($)",
                accessor: "current_balance",
                disableSortBy: true,
                Cell: cellRightAlign,
                align: "right",
            },
        ],
        [cellLink],
    );

    return (
        <Grid container>
            {/* Filter Section */}
            <Grid item xs={12}>
                <Box
                    sx={{
                        mb: 4,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: { xs: "column-reverse", sm: "row" },
                        flexGrow: 1,
                        width: { xs: "100%", sm: "100%" },
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            my: 1,
                            flexGrow: 1,
                            justifyContent: { xs: "flex-end", sm: "flex-end" },
                            flexDirection: { xs: "column", sm: "row" },
                            alignItems: "center",
                            width: { xs: "100%", sm: "100%" },
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                my: 1,
                                pr: { sm: 1, xs: 0 },
                                flexGrow: 1,
                                flexDirection: { xs: "column", sm: "row" },
                                width: { xs: "100%", sm: "100%" },
                            }}
                        >
                            <TextField
                                placeholder="Username"
                                sx={{
                                    mr: { xs: 0, sm: 1 },
                                    my: { xs: 1, sm: 0 },
                                    width: { sm: 250, xs: "100%" },
                                }}
                                value={username}
                                inputProps={{
                                    style: {
                                        padding: "10px 8px",
                                    },
                                }}
                                onKeyDown={(e) => {
                                    if (e.code == "Enter") {
                                        resetPageConfig();
                                        setShouldReload(Date.now());
                                    }
                                }}
                                onChange={(e) => {
                                    setUsername(e.target.value.trim());
                                }}
                            />
                            <TextField
                                placeholder="Email"
                                sx={{
                                    mx: { xs: 0, sm: 1 },
                                    width: { sm: 250, xs: "100%" },
                                }}
                                value={email}
                                onKeyDown={(e) => {
                                    if (e.code == "Enter") {
                                        resetPageConfig();
                                        setShouldReload(Date.now());
                                    }
                                }}
                                onChange={(e) => {
                                    setEmail(
                                        e.target.value.toLowerCase().trim(),
                                    );
                                }}
                                inputProps={{
                                    style: {
                                        padding: "10px 8px",
                                    },
                                }}
                            />
                        </Box>
                        <Stack
                            sx={{
                                flexDirection: { xs: "column", sm: "row" },
                                width: "100%",
                                justifyContent: "flex-end",
                            }}
                        >
                            <Button
                                variant="outlined"
                                color="error"
                                sx={{
                                    mx: { sm: 1, xs: 0 },
                                    height: "40px",
                                    width: { xs: "100%", sm: "auto" },
                                    "&:hover": {
                                        color: "#ff4d4f",
                                        borderColor: "#ff4d4f",
                                    },
                                }}
                                onClick={() => {
                                    setUsername("");
                                    setEmail("");
                                    resetPageConfig();
                                    setShouldReload(Date.now());
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                variant="contained"
                                endIcon={<SearchIcon />}
                                sx={{
                                    mx: { sm: 1, xs: 0 },
                                    height: "40px",
                                    my: { xs: 1, sm: 0 },
                                    width: { xs: "100%", sm: "auto" },
                                }}
                                onClick={() => {
                                    resetPageConfig();
                                    setShouldReload(Date.now());
                                }}
                                onKeyDown={(e) => {
                                    if (e.code == "Enter") {
                                        resetPageConfig();
                                        setShouldReload(Date.now());
                                    }
                                }}
                            >
                                Search
                            </Button>
                        </Stack>
                    </Box>
                </Box>
            </Grid>
            {/* Table Section */}
            <Grid item xs={12}>
                <ScrollX>
                    <ReactTable
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        columns={columns}
                        data={data}
                        setPageIndexProp={setPageIndex}
                        setPageSizeProp={setPageSize}
                        setSortByProp={setSortBy}
                        loading={loading}
                        rowCount={rowCount}
                    />
                </ScrollX>
            </Grid>
        </Grid>
    );
};

export default BalancesTable;
