import MainWrapper from "../../components/wrappers/MainWrapper";
import TldTable from "../tables/TldTable";

const TldPage = () => {
    return (
        <MainWrapper title="TLDs">
            <TldTable />
        </MainWrapper>
    );
};

export default TldPage;
