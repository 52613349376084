import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";
import { Grid, Typography, useMediaQuery, Theme } from "@mui/material";

type RangePickerProps = {
    startDate: number;
    endDate: number;
    handleOnDateChange: (value: [number | null, number | null]) => void;
    noFutureDates: boolean;
    startDateLabel?: string;
    endDateLabel?: string;
    disableEndDate?: boolean;
};

const pickerWidth = "189.2px";

const RangePicker = (props: RangePickerProps) => {
    const isDownMd = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md"),
    );

    const { handleOnDateChange } = props;

    const handleStartDateChange = (date: any) => {
        if (date) {
            date = date
                .set({ hour: 0, minute: 0, second: 0, milliSecond: 0 })
                .toMillis();

            handleOnDateChange([date, props.endDate]);
        }
    };

    const handleEndDateChange = (date: any) => {
        if (date) {
            date = date
                .set({ hour: 23, minute: 59, second: 59, millisecond: 999 })
                .toMillis();

            handleOnDateChange([props.startDate, date]);
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Grid container alignItems="top" width={"100%"} rowSpacing={2.5}>
                <Grid item xs={isDownMd ? 12 : undefined}>
                    <DesktopDatePicker
                        label={
                            props.startDateLabel
                                ? props.startDateLabel
                                : "Start date"
                        }
                        value={DateTime.fromMillis(props.startDate)}
                        onChange={handleStartDateChange}
                        maxDate={DateTime.fromMillis(props.endDate)}
                        format="yyyy-MM-dd"
                        sx={{ width: isDownMd ? "100%" : pickerWidth }}
                    />
                </Grid>
                {!isDownMd && (
                    <Grid
                        item
                        display={"flex"}
                        alignItems={"center"}
                        paddingX={1}
                    >
                        <Typography>to</Typography>
                    </Grid>
                )}
                <Grid item xs={isDownMd ? 12 : undefined}>
                    <DesktopDatePicker
                        label={
                            props.endDateLabel ? props.endDateLabel : "End date"
                        }
                        value={DateTime.fromMillis(props.endDate)}
                        onChange={handleEndDateChange}
                        minDate={DateTime.fromMillis(props.startDate)}
                        maxDate={
                            props.noFutureDates
                                ? DateTime.now().set({
                                      hour: 23,
                                      minute: 59,
                                      second: 59,
                                      millisecond: 999,
                                  })
                                : DateTime.now().plus({ years: 2 })
                        }
                        format="yyyy-MM-dd"
                        disabled={props.disableEndDate ?? false}
                        sx={{ width: isDownMd ? "100%" : pickerWidth }}
                    />
                </Grid>
            </Grid>
        </LocalizationProvider>
    );
};

export default RangePicker;
