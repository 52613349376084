import * as React from "react";
import { Theme, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Chip from "@mui/material/Chip";
import CancelIcon from "@mui/icons-material/Cancel";

const itemHeight = 48;
const itemPaddingTop = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: itemHeight * 4.5 + itemPaddingTop,
            width: 25,
        },
    },
};

function getStyles(name: string, personName: readonly string[], theme: Theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

type MultipleSelectProps = {
    levels: string[];
    fieldValue: string[];
    onSelect: (value: string[]) => void;
};

const MultipleSelect = (props: MultipleSelectProps) => {
    const theme = useTheme();

    const { onSelect } = props;

    const handleChange = (
        event: SelectChangeEvent<typeof props.fieldValue>,
    ) => {
        const {
            target: { value },
        } = event;
        onSelect(
            // On autofill we get a stringified value.
            typeof value === "string" ? value.split(",") : value,
        );
    };

    const handleDelete = (deleteChip: string) => () => {
        onSelect(props.fieldValue.filter((item) => item !== deleteChip));
    };

    return (
        <Select
            id="demo-multiple-chip"
            multiple
            value={props.fieldValue}
            onChange={handleChange}
            input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
            renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                        <Chip
                            key={value}
                            label={
                                value.charAt(0).toUpperCase() + value.slice(1)
                            }
                            sx={{
                                height: "20px",
                                marginRight: "1px",
                            }}
                            onMouseDown={(e) => {
                                e.stopPropagation();
                            }}
                            deleteIcon={<CancelIcon style={{ fontSize: 15 }} />}
                            onDelete={handleDelete(value)}
                        />
                    ))}
                </Box>
            )}
            MenuProps={MenuProps}
        >
            {props.levels.map((level) => (
                <MenuItem
                    key={level}
                    value={level}
                    style={getStyles(level, props.fieldValue, theme)}
                >
                    {level.charAt(0).toUpperCase() + level.slice(1)}
                </MenuItem>
            ))}
        </Select>
    );
};

export default MultipleSelect;
