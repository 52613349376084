import MainWrapper from "../../components/wrappers/MainWrapper";
import CreateEmailForm from "../../forms/CreateEmailForm";

const CreateEmail = () => {
    return (
        <MainWrapper title="Create Email">
            <CreateEmailForm />
        </MainWrapper>
    );
};

export default CreateEmail;
