import React from "react";
import { Box, Typography } from "@mui/material";

export type NameCardProps = {
    name: string;
};
const DomainName = (nameCardProps: NameCardProps) => {
    return (
        <Box
            pt={{ xs: 3, sm: 5 }}
            style={{
                width: "fit-content",
            }}
        >
            <Box
                style={{
                    width: "fit-content",
                    backgroundColor: "#F6F6F6",
                    borderRadius: "10px",
                }}
                py={2}
                px={1}
            >
                <Typography variant={"h1"} color={"#1890FF"}>
                    {nameCardProps.name}
                </Typography>
            </Box>
        </Box>
    );
};
export default DomainName;
