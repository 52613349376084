import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

type TypeDropDownProps = {
    availableValues: string[];
    selectedValue: string | null;
    onSelect: (value: string) => void;
    onInput: (value: string) => void;
    error: boolean;
    isFreeze: boolean;
    isLowerCase: boolean;
};

const TypeDropDown = (props: TypeDropDownProps) => {
    const { onSelect, onInput } = props;

    const handleValueChange = (value: any) => {
        onSelect(value);
    };

    return (
        <Autocomplete
            value={props.selectedValue}
            disabled={props.isFreeze}
            onKeyDown={(event) => {
                if (event.key === " ") {
                    event.preventDefault();
                }
            }}
            onChange={(event, newValue) => {
                handleValueChange(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                onInput(newInputValue);
            }}
            options={props.availableValues}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label=""
                    error={props.error}
                    sx={{
                        "& input": {
                            textTransform: props.isLowerCase
                                ? "lowercase"
                                : "none",
                        },
                    }}
                />
            )}
        />
    );
};

export default TypeDropDown;
