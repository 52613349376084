import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import ScrollX from "../../components/ScrollX";
import ReactTable from "./react-table/ReactTable";
import { emailTableDataType } from "../../types/tableTypes";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import { Link } from "react-router-dom";
import { cellDeleteIcon } from "./CellFormatters";
import ConfirmationBox from "../../components/common/AlertBoxes/ConfirmationBox";

const BlacklistTable = () => {
    const { postData } = useData();
    const { showError, showSuccess } = useMessage();
    const [data, setData] = useState<emailTableDataType[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [alert, setAlert] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [deleteFocused, setDeleteFocused] = useState<string>("");
    const [sortBy, setSortBy] = useState<{
        id: string;
        desc: boolean;
    }>({
        id: "domain",
        desc: false,
    });

    async function deleteDomain(name: string) {
        setAlert(false);
        const domainName = name.trim();
        try {
            setLoading(true);
            const body = {
                domains: [domainName],
            };
            const response: any = await postData(
                "/blacklistedDomains/deleteDomains",
                body,
            );
            if (
                response.success &&
                response.data.success.includes(domainName)
            ) {
                loadBlacklist();
                showSuccess(`'${domainName}' removed from blacklist`);
            }
        } catch (error) {
            console.log(error);
            showError("Failed to remove the domain");
        }
        setLoading(false);
    }

    function openAlertBox(name: string) {
        setDeleteFocused(name);
        setAlert(true);
    }

    interface RequestType {
        page: number;
        size: number;
        filterAnd: any[];
        sort: {
            key: string;
            order: "ASC" | "DESC";
        };
    }

    const loadBlacklist = async (isInit: boolean = false) => {
        setLoading(true);
        const reqBody: RequestType = {
            page: isInit ? 1 : pageIndex + 1,
            size: pageSize,
            filterAnd: [],
            sort: {
                key: sortBy.id,
                order: sortBy.desc ? "DESC" : "ASC",
            },
        };

        try {
            const response: any = await postData(
                "/blacklistedDomains/getAllDomains",
                reqBody,
            );
            if (response?.data) {
                setData(
                    response.data.map((row: any) => ({
                        ...row,
                        deleteId: row.domain,
                    })),
                );
            } else {
                setData([]);
            }
            setRowCount(response?.count ? response?.count : 0);
        } catch (error) {
            showError("Data Fetching Error");
        }
        setLoading(false);
    };

    useEffect(() => {
        loadBlacklist();
    }, [pageIndex, sortBy, rowCount]);

    useEffect(() => {
        setPageIndex(0);
        loadBlacklist(true);
    }, [pageSize]);

    const columns = useMemo(
        () => [
            {
                Header: "Domain",
                accessor: "domain",
                disableSortBy: true,
                align: "left",
            },
            {
                Header: "Action",
                accessor: "deleteId",
                disableSortBy: true,
                width: "10px",

                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: ({ value }: { value: string }) =>
                    cellDeleteIcon({ value, openAlertBox }),
            },
        ],
        [],
    );

    return (
        <Grid container>
            <ConfirmationBox
                title={`Confirm removing ${deleteFocused} from Domain Blacklist`}
                subtitle="This action cannot be undone"
                open={alert}
                setOpen={setAlert}
                triggerFunction={deleteDomain}
                triggerValue={deleteFocused}
            />
            <Box
                sx={{
                    display: "flex",
                    my: 1,
                    flexGrow: 1,
                    justifyContent: { xs: "flex-end", sm: "flex-end" },
                    alignItems: "center",
                }}
            >
                <Link to={"/admin/blacklist/add"}>
                    <Button
                        variant="contained"
                        endIcon={<AddIcon />}
                        sx={{
                            ml: 1,
                            height: "40px",
                            whiteSpace: "nowrap",
                            mb: "19px",
                            textTransform: "none",
                        }}
                    >
                        Add Domains
                    </Button>
                </Link>
            </Box>

            {/* Table Section */}
            <Grid item xs={12}>
                <ScrollX>
                    <ReactTable
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        columns={columns}
                        data={data}
                        setPageIndexProp={setPageIndex}
                        setPageSizeProp={setPageSize}
                        setSortByProp={setSortBy}
                        loading={loading}
                        rowCount={rowCount}
                    />
                </ScrollX>
            </Grid>
        </Grid>
    );
};

export default BlacklistTable;
