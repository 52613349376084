import {
    Button,
    InputLabel,
    FormControl,
    Grid,
    TextField,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import React, { useEffect, useMemo, useState } from "react";
import ScrollX from "../../components/ScrollX";
import RangePicker from "../../components/filters/RangePicker";
import MultipleSelect from "../../components/filters/MulipleSelect";
import ReactTable from "./react-table/ReactTable";
import useMessage from "../../hooks/useMessage";
import { usersTableDataType } from "../../types/tableTypes";
import {
    cellCenterAlign,
    cellDateAndTimeNormal,
    cellSeeMore,
    cellSubmissionStatus,
} from "./CellFormatters";
import useData from "../../hooks/useApi";
import {
    getDefaultStartDateTime,
    getDefaultEndDateTime,
} from "../../utils/helpers";

interface RequestType {
    page: number;
    size: number;
    filterAnd: any[];
    sort: {
        key: string;
        order: "ASC" | "DESC";
    };
}

const SubmissionsTable = () => {
    const { showError } = useMessage();
    const { postData } = useData();
    const [data, setData] = useState<usersTableDataType[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [rowCount, setRowCount] = useState<number>(0);
    const [loading, setLoading] = useState<boolean>(false);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [sortBy, setSortBy] = useState<{
        id: string;
        asc: boolean;
    }>({
        id: "date",
        asc: false,
    });

    // Filters & searches
    const [domainSearch, setDomainSearch] = useState<string>("");
    const [usernameSearch, setUsernameSearch] = useState<string>("");
    const [statusFilter, setStatusFilter] = useState<string[]>([]);

    const [startDate, setStartDate] = useState(getDefaultStartDateTime());
    const [endDate, setEndDate] = useState(getDefaultEndDateTime);

    const [shouldReload, setShouldReload] = useState<number>(Date.now());

    const handleOnDateChange = (value: any) => {
        setStartDate(value[0]);
        setEndDate(value[1]);
    };

    const resetPageConfig = () => {
        setPageIndex(0);
        setPageSize(10);
    };

    const resetView = () => {
        setDomainSearch("");
        setUsernameSearch("");
        setStatusFilter([]);
        setStartDate(getDefaultStartDateTime());
        setEndDate(getDefaultEndDateTime());
        resetPageConfig();
        setShouldReload(Date.now());
    };

    const loadSubmissionData = async (): Promise<void> => {
        try {
            setLoading(true);
            const userData: RequestType = {
                page: pageIndex + 1,
                size: pageSize,
                filterAnd: [],
                sort: {
                    key: sortBy.id,
                    order: sortBy.asc ? "ASC" : "DESC",
                },
            };

            if (domainSearch !== "") {
                userData.filterAnd.push({
                    key: "domain",
                    operation: "LIKE",
                    value: ["%" + domainSearch + "%"],
                });
            }

            if (usernameSearch !== "") {
                userData.filterAnd.push({
                    key: "username",
                    operation: "LIKE",
                    value: [usernameSearch + "%"],
                });
            }

            if (statusFilter.length > 0) {
                userData.filterAnd.push({
                    key: "status",
                    operation: "IN",
                    value: statusFilter,
                });
            }

            if (startDate) {
                userData.filterAnd.push({
                    key: "date",
                    operation: ">",
                    value: [Math.floor(startDate / 1000)],
                });
            }

            if (endDate) {
                userData.filterAnd.push({
                    key: "date",
                    operation: "<",
                    value: [Math.ceil(endDate / 1000)],
                });
            }
            const response: any = await postData(
                "/submissions/getAllSubmissions",
                userData,
            );
            if (response?.data) {
                setData(response.data);
            } else {
                setData([]);
            }
            setRowCount(response?.count ? response.count : 0);
        } catch (error) {
            showError("Data Fetching Error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setPageIndex(0);
        loadSubmissionData();
    }, [pageSize, shouldReload]);

    useEffect(() => {
        loadSubmissionData();
    }, [sortBy, pageIndex]);

    const columns = useMemo(
        () => [
            {
                Header: "Domain",
                accessor: "domain",
                disableSortBy: true,
                cell: cellSeeMore,
                align: "left",
            },
            {
                Header: "Username",
                accessor: "username",
                disableSortBy: true,
                align: "left",
            },
            {
                Header: "Added Time (UTC)",
                accessor: "date",
                disableSortBy: true,
                Cell: cellDateAndTimeNormal,
            },
            {
                Header: "Status",
                accessor: "status",
                disableSortBy: true,
                Cell: cellSubmissionStatus,
            },
            {
                Header: "Attempt Count",
                accessor: "attemptCount",
                disableSortBy: true,
                Cell: cellCenterAlign,
            },
            {
                Header: "Message",
                accessor: "message",
                disableSortBy: true,
                Cell: cellSeeMore,
            },
        ],
        [],
    );

    return (
        <Grid container sx={{ mb: 4, width: "100%", mx: 0 }}>
            <Grid item container spacing={2} sx={{ marginBottom: 3 }}>
                <Grid item xs={12} md={6} lg={1.5}>
                    <TextField
                        sx={{
                            width: "100%",
                        }}
                        placeholder="Domain"
                        value={domainSearch}
                        onKeyDown={(e) => {
                            if (e.key === " ") {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) =>
                            setDomainSearch(e.target.value.toLowerCase())
                        }
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={1.5}>
                    <TextField
                        sx={{
                            width: "100%",
                        }}
                        placeholder="Username"
                        fullWidth
                        value={usernameSearch}
                        onKeyDown={(e) => {
                            if (e.key === " ") {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => setUsernameSearch(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12} md={6} lg={2}>
                    <FormControl
                        sx={{
                            width: "100%",
                        }}
                    >
                        <InputLabel
                            id="submission-status-label"
                            sx={{ color: "#bbb" }}
                        >
                            Submission Status
                        </InputLabel>
                        <MultipleSelect
                            levels={["Success", "Pending", "Failure"]}
                            fieldValue={statusFilter}
                            onSelect={setStatusFilter}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={6} lg={5}>
                    <RangePicker
                        startDate={startDate}
                        endDate={endDate}
                        handleOnDateChange={handleOnDateChange}
                        noFutureDates={true}
                    />
                </Grid>
                <Grid
                    item
                    container
                    xs={12}
                    md={6}
                    lg={2}
                    spacing={1}
                    justifyContent={"flex-end"}
                >
                    <Grid item xs={6}>
                        <Button
                            variant="outlined"
                            color="error"
                            sx={{
                                height: "40px",
                                "&:hover": {
                                    color: "#ff4d4f",
                                    borderColor: "#ff4d4f",
                                },
                                width: "100%",
                            }}
                            onClick={resetView}
                        >
                            Reset
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button
                            variant="contained"
                            endIcon={<SearchIcon />}
                            sx={{
                                height: "40px",
                                width: "100%",
                            }}
                            onClick={() => {
                                resetPageConfig();
                                setShouldReload(Date.now());
                            }}
                            onKeyDown={(e) => {
                                if (e.code === "Enter") {
                                    resetPageConfig();
                                    setShouldReload(Date.now());
                                }
                            }}
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <ScrollX>
                    <ReactTable
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        columns={columns}
                        data={data}
                        setPageIndexProp={setPageIndex}
                        setPageSizeProp={setPageSize}
                        setSortByProp={setSortBy}
                        loading={loading}
                        rowCount={rowCount}
                    />
                </ScrollX>
            </Grid>
        </Grid>
    );
};

export default SubmissionsTable;
