import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import EmailIcon from "@mui/icons-material/Email";
import TelegramIcon from "@mui/icons-material/Telegram";
import LockIcon from "@mui/icons-material/Lock";
import PauseIcon from "@mui/icons-material/Pause";
import { LockOpen, PlayArrow } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Dispatch, SetStateAction, useState } from "react";

type PopUpMenuProps = {
    username: string;
    domainName: string;
    whichPage: string;
    isPaused: boolean;
    isLocked: boolean;
    auctionType: string;
    handleBidAction: (action: string, nextState: boolean) => Promise<void>;
    setDeleteConfirmation: Dispatch<SetStateAction<boolean>>;
};

const PopupMenu = (props: PopUpMenuProps) => {
    const navigate = useNavigate();
    const { handleBidAction, setDeleteConfirmation } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleBidActionClick = async (action: string, nextState: boolean) => {
        await handleBidAction(action, nextState);
        setAnchorEl(null);
    };

    const handleBidDeleteClick = () => {
        setDeleteConfirmation(true);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            sx={{
                mt: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <IconButton
                aria-controls="popup-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                id="popup-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    "& .MuiList-root": {
                        py: 0.2,
                    },
                }}
            >
                {props.whichPage === "active" && (
                    <MenuItem
                        onClick={() =>
                            handleBidActionClick("isPaused", !props.isPaused)
                        }
                        sx={{ borderBottom: "1.5px solid #ccc" }}
                        disabled={props.auctionType === "Post auction"}
                    >
                        {" "}
                        {props.isPaused ? (
                            <>
                                <PlayArrow
                                    fontSize="small"
                                    sx={{
                                        paddingRight: "2px",
                                        marginRight: "8px",
                                        color: "#1890FF",
                                    }}
                                />
                                <span>Resume</span>
                            </>
                        ) : (
                            <>
                                <PauseIcon
                                    fontSize="small"
                                    sx={{
                                        paddingRight: "2px",
                                        marginRight: "8px",
                                        color: "#1890FF",
                                    }}
                                />
                                <span>Pause</span>
                            </>
                        )}
                    </MenuItem>
                )}
                {props.whichPage === "active" && (
                    <MenuItem
                        onClick={() =>
                            handleBidActionClick("isLocked", !props.isLocked)
                        }
                        disabled={props.auctionType === "Post auction"}
                        sx={{ borderBottom: "1.5px solid #ccc" }}
                    >
                        {" "}
                        {props.isLocked ? (
                            <>
                                <LockOpen
                                    fontSize="small"
                                    sx={{
                                        paddingRight: "2px",
                                        marginRight: "8px",
                                        color: "#D8DEE8",
                                    }}
                                />
                                <span>Unlock</span>
                            </>
                        ) : (
                            <>
                                <LockIcon
                                    fontSize="small"
                                    sx={{
                                        paddingRight: "2px",
                                        marginRight: "8px",
                                        color: "#D8DEE8",
                                    }}
                                />
                                <span>Lock</span>
                            </>
                        )}
                    </MenuItem>
                )}
                <MenuItem
                    onClick={handleBidDeleteClick}
                    sx={{ borderBottom: "1.5px solid #ccc" }}
                    disabled={props.auctionType === "Post auction"}
                >
                    {" "}
                    <DeleteIcon
                        fontSize="small"
                        sx={{
                            paddingRight: "2px",
                            marginRight: "8px",
                            color: "#FF4D4F",
                        }}
                    />
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        window.location.href =
                            "https://www.expireddomains.net/login/";
                    }}
                    sx={{ borderBottom: "1.5px solid #ccc" }}
                >
                    {" "}
                    <EventAvailableIcon
                        fontSize="small"
                        sx={{
                            paddingRight: "2px",
                            marginRight: "8px",
                            color: "#1890FF",
                        }}
                    />
                    Check Drop Date
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        navigate(
                            `/admin/messages/create?username=${props.username}`,
                        )
                    }
                    sx={{ borderBottom: "1.5px solid #ccc" }}
                >
                    {" "}
                    <TelegramIcon
                        fontSize="small"
                        sx={{
                            paddingRight: "2px",
                            marginRight: "8px",
                            color: "#1890FF",
                        }}
                    />
                    Send Message
                </MenuItem>
                {props.whichPage === "archived" && (
                    <MenuItem
                        onClick={() =>
                            navigate("/admin/emails/createWithTemplate", {
                                state: {
                                    domain: props.domainName,
                                    user: props.username,
                                },
                            })
                        }
                    >
                        <EmailIcon
                            fontSize="small"
                            sx={{
                                paddingRight: "2px",
                                marginRight: "8px",
                                color: "#1890FF",
                            }}
                        />
                        Send Email
                    </MenuItem>
                )}
            </Menu>
        </Box>
    );
};

export default PopupMenu;
