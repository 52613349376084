import MainWrapper from "../../components/wrappers/MainWrapper";
import CreateUserForm from "../../forms/CreateUserForm";

const CreateUser = () => {
    return (
        <MainWrapper title="Create New User">
            <CreateUserForm />
        </MainWrapper>
    );
};

export default CreateUser;
