import { useEffect, useMemo, useState } from "react";

import { Grid } from "@mui/material";

import ScrollX from "../../components/ScrollX";
import ReactTable from "./react-table/ReactTable";
import { perAuctionTableDataType } from "../../types/tableTypes";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import { cellBigNumber } from "./CellFormatters";
import ConfirmationBox from "../../components/common/AlertBoxes/ConfirmationBox";
import { useNavigate } from "react-router-dom";
import PerAuctionPopUpMenu from "../../components/popups/PerAuctionPopUpMenu";

type PreAuctionViewTableProps = {
    auctionId: number;
};

const PreAuctionViewTable = (props: PreAuctionViewTableProps) => {
    const { postData } = useData();
    const { showError, showSuccess } = useMessage();
    const [data, setData] = useState<perAuctionTableDataType[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [deletePopup, setDeletePopup] = useState<boolean>(false);
    const [selected, setSelected] = useState<any>();
    const [sortBy, setSortBy] = useState<{
        id: string;
        desc: boolean;
    }>({
        id: "Username",
        desc: false,
    });
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();

    async function deleteBidHandler(value: any) {
        setDeletePopup(false);
        try {
            const response: any = await postData("/bid/applyBidDelete", {
                auctionWiseBids: [
                    {
                        auctionId: props.auctionId,
                        bidIds: [+value],
                    },
                ],
            });
            if (response && response?.success) {
                navigate(0);
                showSuccess("Bid Delete Success");
            } else {
                showError("Bid Delete Failed");
            }
        } catch (error) {
            showError("Bid Delete Failed");
        }
    }
    async function handleBidAction(
        data: any,
        action: string,
        nextState: boolean,
    ) {
        try {
            const response: any = await postData("/bid/applyBidAction", {
                auctionWiseBids: [
                    {
                        auctionId: data?.auctionId,
                        bidIds: [data?.bid_id],
                    },
                ],
                whichField: action,
                nextState: nextState,
            });
            if (response && response?.success) {
                showSuccess("Bid Action Successful");
                navigate(0);
            } else {
                showError("Bid Action Failed");
            }
        } catch (error: any) {
            console.log("error");
        }
    }

    function cellActionMenu({ row }: any) {
        return (
            <PerAuctionPopUpMenu
                row={row.original}
                handleBidAction={handleBidAction}
                handleBidDeleteClick={() => {
                    setSelected(row.original);
                    setDeletePopup(true);
                }}
            />
        );
    }

    interface RequestType {
        page: number;
        size: number;
        filterAnd: any[];
        sort: {
            key: string;
            order: "ASC" | "DESC";
        };
    }

    const searchHandler = async (isInit: boolean = false) => {
        setLoading(true);
        const bidDataReq: RequestType = {
            page: isInit ? 1 : pageIndex + 1,
            size: pageSize,
            filterAnd: [
                {
                    // eslint-disable-next-line quotes
                    key: "auctionId",
                    operation: "IN",
                    value: [props.auctionId],
                },
            ],
            sort: {
                key: sortBy.id,
                order: sortBy.desc ? "DESC" : "ASC",
            },
        };
        try {
            const response: any = await postData(
                "/bid/getActiveBids",
                bidDataReq,
            );
            if (response?.data) {
                setData(
                    response.data.map((item: any) => ({
                        ...item,
                        bidId: item.Bid,
                        action: item.Username,
                        amount: item.Bid,
                        username: item.Username,
                        auctionId: item.auctionId,
                        bid_id: item.bidId,
                    })),
                );
            } else {
                setData([]);
            }
            setRowCount(response?.count ? response?.count : 0);
        } catch (error) {
            showError("Data Fetching Error");
        }
        setLoading(false);
    };

    useEffect(() => {
        if (sortBy.id) searchHandler();
    }, [sortBy, pageIndex]);

    useEffect(() => {
        setPageIndex(0);
        searchHandler();
    }, [props.auctionId, pageSize]);

    const columns = useMemo(
        () => [
            {
                Header: "Username",
                accessor: "username",
                align: "left",
            },
            {
                Header: "Bid ($)",
                accessor: "amount",
                align: "right",
                Cell: cellBigNumber,
            },
            {
                Header: "Action",
                accessor: "id",
                Cell: cellActionMenu,
                disableSortBy: true,
                width: 10,
            },
        ],
        [],
    );

    return (
        <>
            <ConfirmationBox
                open={deletePopup}
                setOpen={setDeletePopup}
                triggerFunction={deleteBidHandler}
                triggerValue={selected?.bid_id}
                title={`Confirm removing the bid of ${selected?.username} ?`}
                subtitle="This action cannot be undone"
            />
            <Grid container>
                {/* Table Section */}
                <Grid item xs={12}>
                    <ScrollX>
                        <ReactTable
                            pageSize={pageSize}
                            pageIndex={pageIndex}
                            columns={columns}
                            data={data}
                            setPageIndexProp={setPageIndex}
                            setPageSizeProp={setPageSize}
                            setSortByProp={setSortBy}
                            loading={loading}
                            rowCount={rowCount}
                        />
                    </ScrollX>
                </Grid>
            </Grid>
        </>
    );
};

export default PreAuctionViewTable;
