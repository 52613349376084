// types
import { DefaultConfigProps } from "../types/config";

export const drawerWidth = 230;

// ==============================|| THEME CONFIG  ||============================== //

const config: DefaultConfigProps = {
    defaultPath: "/login",
    fontFamily: "'Public Sans', sans-serif",
    i18n: "en",
    miniDrawer: false,
    container: true,
    mode: "light",
    presetColor: "default",
    themeDirection: "ltr",
};

export default config;
