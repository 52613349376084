import { Theme } from "@mui/material/styles";
import { Box, useMediaQuery } from "@mui/material";
import Profile from "./Profile";
import Clock from "./Clock";

const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down("md"),
    );

    return (
        <>
            <Clock />
            {!matchesXs && <Box sx={{ width: "100%", ml: 1 }} />}
            <Profile />
        </>
    );
};

export default HeaderContent;
