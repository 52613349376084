import {
    Box,
    Button,
    CircularProgress,
    Paper,
    Grid,
    TextField,
    Typography,
} from "@mui/material";

import SearchIcon from "@mui/icons-material/Search";
import React, { useState } from "react";

import useMessage from "../../hooks/useMessage";
import useData from "../../hooks/useApi";
import {
    CustomIcon,
    StyledGridOverlay,
} from "../../components/third-party/ReactTable";

const WhoisDataView = () => {
    const { showError } = useMessage();
    const { postData } = useData();
    const [loading, setLoading] = useState<boolean>(false);

    // Filters & searches
    const [domainSearch, setDomainSearch] = useState<string>("");
    const [domainData, setDomainData] = useState<string | null>(null);
    const [isDomainData, setIsDomainData] = useState<boolean>(false);

    interface RequestType {
        domain: string;
    }

    const loadWhoisData = async () => {
        setLoading(true);
        const domainData: RequestType = {
            domain: domainSearch,
        };

        try {
            const response: any = await postData(
                "/whois/getDomainWhois",
                domainData,
            );
            if (response) {
                if (response.success) {
                    if (response.result !== undefined) {
                        setDomainData(response.result._raw);
                        setIsDomainData(true);
                    } else if (response.message !== undefined) {
                        setDomainData(response.message);
                        setIsDomainData(true);
                    } else {
                        showError("Server Error");
                        setIsDomainData(false);
                    }
                } else {
                    showError("Server Error");
                    setIsDomainData(false);
                }
            }
        } catch (e: any) {
            if (e.response?.status === 400) {
                showError("Invalid Domain");
                setIsDomainData(false);
            } else {
                showError("Internal Server Error");
                setIsDomainData(false);
            }
        }
        setLoading(false);
    };

    return (
        <Box
            sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: "column",
                flexGrow: 1,
            }}
        >
            <Box
                sx={{
                    display: "flex",
                    my: 1,
                    flexGrow: 1,
                    flexDirection: {
                        xs: "column",
                        sm: "column",
                        md: "row",
                    },
                    justifyContent: { xs: "flex-start" },
                }}
            >
                <TextField
                    placeholder="Domain Name"
                    sx={{
                        marginRight: 1,
                        my: { xs: 1, sm: 1, md: 0 },
                        width: { xs: "95%", sm: "95%", md: "300px" },
                    }}
                    value={domainSearch}
                    onKeyDown={(event) => {
                        if (event.key === " ") {
                            event.preventDefault();
                        } else if (event.code == "Enter") {
                            loadWhoisData();
                        }
                    }}
                    onChange={(e) => {
                        setDomainSearch(e.target.value.toLowerCase());
                    }}
                />
                <Button
                    variant="outlined"
                    color="error"
                    sx={{
                        mx: 1,
                        height: "41.13px",
                        "&:hover": {
                            color: "#ff4d4f",
                            borderColor: "#ff4d4f",
                        },
                        width: { xs: "95%", sm: "95%", md: "80px" },
                    }}
                    onClick={() => {
                        setDomainData(null);
                        setIsDomainData(false);
                        setDomainSearch("");
                    }}
                >
                    Reset
                </Button>
                <Button
                    variant="contained"
                    endIcon={<SearchIcon />}
                    sx={{
                        mx: 1,
                        height: "41.13px",
                        my: { xs: 1, sm: 1, md: 0 },
                        width: { xs: "95%", sm: "95%", md: "120px" },
                    }}
                    onClick={loadWhoisData}
                >
                    Search
                </Button>
            </Box>
            {!loading ? (
                <Box
                    sx={{
                        mt: 3,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        flexGrow: 1,
                    }}
                >
                    <Paper
                        elevation={0}
                        square={true}
                        sx={{
                            padding: 5,
                            backgroundColor: "#F9F9F9",
                        }}
                    >
                        {isDomainData ? (
                            <Typography
                                variant="body1"
                                component="pre"
                                sx={{
                                    maxWidth: "100%",
                                    overflowX: "auto",
                                    width: 950,
                                }}
                            >
                                {domainData}
                            </Typography>
                        ) : (
                            <StyledGridOverlay
                                alignItems="center"
                                justifyContent="center"
                                spacing={1}
                            >
                                <CustomIcon />
                                <Typography align="center" color="secondary">
                                    {"No Data"}
                                </Typography>
                            </StyledGridOverlay>
                        )}
                    </Paper>
                </Box>
            ) : (
                <Grid
                    item
                    xs={12}
                    md={12}
                    container
                    justifyContent="center"
                    padding={10}
                >
                    <CircularProgress />
                </Grid>
            )}
        </Box>
    );
};

export default WhoisDataView;
