import React, { useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import useMessage from "../hooks/useMessage";
import {
    Button,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Stack,
} from "@mui/material";
import { TextField } from "@mui/material";
import useData from "../hooks/useApi";
import { useNavigate } from "react-router-dom";
import TypeDropDown from "../components/filters/DropDown";
import { useLocation } from "react-router-dom";
import { regExValidations } from "../data/consts";

const CreateMessageForm = () => {
    const { postData } = useData();
    const { showError, showSuccess } = useMessage();
    const navigate = useNavigate();

    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const initialValues = {
        username: "",
        message: "",
        level: "",
    };

    const validationSchemaNew = {
        username: yup
            .string()
            .required("Required Field")
            .matches(regExValidations.username, "Invalid Username"),
        message: yup.string().trim().required("Required Field"),
        level: yup.string().required("Required Field"),
    };

    const validationSchema = yup.object(validationSchemaNew);

    const [allUsernames, setAllUsernames] = useState<string[]>([]);
    const [availableUsernames, setAvailableUsernames] = useState<string[]>([]);
    const [selectedUsername, setSelectedUsername] = useState<string | null>(
        null,
    );
    const [fixedUserName, setFixedUserName] = useState<string | null>(null);
    const maxCharacterLimit = 1000;

    interface RequestType {
        page: number;
        size: number;
    }

    const loadAllUserNames = async () => {
        const userData: RequestType = {
            page: 1,
            size: 1000,
        };

        try {
            const response: any = await postData(
                "/users/getUsersData",
                userData,
            );
            if (response?.data) {
                const usernames: string[] = response.data.map(
                    (row: any) => row.username,
                );
                setAllUsernames(usernames);
                setAvailableUsernames(usernames);
            } else {
                setAllUsernames([]);
            }
        } catch (error) {
            showError("Data Fetching Error");
        }
    };

    const filterUserNames = async (partialUserName: string) => {
        setAvailableUsernames(
            allUsernames.filter((str) => str.startsWith(partialUserName)),
        );
    };

    const setUsername = (value: any) => {
        formik.setFieldValue("username", value);
        setSelectedUsername(value);
    };

    useEffect(() => {
        loadAllUserNames();
        const receivedUsername: string | null = params.get("username");
        if (receivedUsername !== null) {
            setFixedUserName(receivedUsername.trim());
            setUsername(receivedUsername.trim());
        }
    }, []);

    const formik = useFormik({
        initialValues,
        validationSchema,
        async onSubmit(values) {
            try {
                // New message creation
                const notCapitalMsg: string = values.message.trim();
                const response: any = await postData("/users/sendMessage", {
                    receiver: values.username,
                    message:
                        notCapitalMsg.charAt(0).toUpperCase() +
                        notCapitalMsg.slice(1),
                    level: values.level,
                });
                if (response && response?.success) {
                    showSuccess("Message sent successfully");
                    navigate("/admin/messages");
                } else {
                    throw Error("failed");
                }
            } catch (e: any) {
                if (e.response?.status === 400) {
                    showError("Username does not exist");
                } else if (e.response?.status === 500) {
                    showError("Internal server error");
                } else {
                    showError("Message sending failed");
                }
            }
        },
    });

    const styles = {
        formControlStyle: {
            my: 1,
            minWidth: 100,
        },
        inputGroup: {
            my: { md: 0, xs: 1 },
            ml: { md: 5, xs: 0 },
        },
    };

    return (
        <form
            onSubmit={formik.handleSubmit}
            style={{
                margin: "auto",
                display: "flex",
                justifyContent: "center",
                flexGrow: 1,
            }}
        >
            <Grid container spacing={5}>
                {/* First Column */}
                <Grid item xs={12} sm={12} md={6}>
                    <Grid container spacing={2} direction="column">
                        {/* First Row in First Column */}
                        <Grid item>
                            <Stack>
                                <InputLabel>Username</InputLabel>
                                <FormControl sx={styles.formControlStyle}>
                                    <TypeDropDown
                                        availableValues={availableUsernames}
                                        selectedValue={selectedUsername}
                                        onSelect={setUsername}
                                        onInput={filterUserNames}
                                        // @ts-expect-error error
                                        error={
                                            formik.errors.username &&
                                            formik.touched.username
                                        }
                                        isFreeze={fixedUserName !== null}
                                        isLowerCase={false}
                                    />
                                    {formik.errors.username && (
                                        <FormHelperText sx={{ mx: 0 }} error>
                                            {" "}
                                            {formik.touched.username &&
                                                formik.errors.username}{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        </Grid>
                        {/* Second Row in First Column */}
                        <Grid item>
                            <Stack>
                                <InputLabel>Level</InputLabel>
                                <FormControl sx={styles.formControlStyle}>
                                    <Select
                                        id="level"
                                        name="level"
                                        value={formik.values.level}
                                        onChange={formik.handleChange}
                                        displayEmpty
                                        inputProps={{
                                            "aria-label": "Level",
                                        }}
                                        // @ts-expect-error error
                                        error={
                                            formik.errors.level &&
                                            formik.touched.level
                                        }
                                    >
                                        <MenuItem value="info">Info</MenuItem>
                                        <MenuItem value="warning">
                                            Warning
                                        </MenuItem>
                                        <MenuItem value="danger">
                                            Danger
                                        </MenuItem>
                                    </Select>
                                    {formik.errors.level && (
                                        <FormHelperText sx={{ mx: 0 }} error>
                                            {" "}
                                            {formik.touched.level &&
                                                formik.errors.level}{" "}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Second Column */}
                <Grid item xs={12} sm={12} md={6}>
                    <Grid item xs={12}>
                        <Stack>
                            <InputLabel>Message</InputLabel>
                            <FormControl sx={styles.formControlStyle}>
                                <TextField
                                    fullWidth
                                    id="message"
                                    multiline
                                    rows={5}
                                    name="message"
                                    autoComplete="message"
                                    inputProps={{
                                        maxLength: maxCharacterLimit,
                                    }}
                                    value={formik.values.message}
                                    onChange={formik.handleChange}
                                    // @ts-expect-error error
                                    error={
                                        formik.errors.message &&
                                        formik.touched.message
                                    }
                                />
                                {formik.errors.message && (
                                    <FormHelperText sx={{ mx: 0 }} error>
                                        {" "}
                                        {formik.touched.message &&
                                            formik.errors.message}{" "}
                                    </FormHelperText>
                                )}
                            </FormControl>
                            <p
                                style={{
                                    textAlign: "right",
                                    margin: "0",
                                    color: "#3A3541",
                                    opacity: 0.3,
                                }}
                            >
                                {formik.values.message.length} /{" "}
                                {maxCharacterLimit}
                            </p>
                        </Stack>
                        <Grid item xs={12} mt={3} textAlign="right">
                            <Button
                                variant="contained"
                                type="submit"
                                style={{
                                    width: "100px",
                                    height: "40px",
                                }}
                            >
                                {"Submit"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default CreateMessageForm;
