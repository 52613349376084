import UpdateTldForm from "../../forms/UpdateTldForm";
import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

const UpdateTldPage = () => {
    const { tldName } = useParams();

    return (
        <Box
            sx={{
                padding: "24px",
                bgcolor: "white",
            }}
        >
            <Box
                display={"flex"}
                flexDirection={"row"}
                marginBottom={5}
                alignItems={"center"}
            >
                <Typography
                    sx={{
                        fontSize: { sm: "22px", xs: "16px" },
                        fontWeight: 600,
                        color: "#3A3541CC",
                        marginRight: 3,
                    }}
                >
                    Update TLD:
                </Typography>
                <Box
                    sx={{
                        fontSize: { sm: "22px", xs: "16px" },
                        lineHeight: { sm: "22px", xs: "16px" },
                        fontWeight: 500,
                        color: "#1890FF",
                        bgcolor: "#F6F6F6",
                        width: "100px",
                        borderRadius: "6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "40px",
                    }}
                >
                    {tldName}
                </Box>
            </Box>
            <UpdateTldForm />
        </Box>
    );
};

export default UpdateTldPage;
