import MainWrapper from "../../components/wrappers/MainWrapper";
import WhoisDataView from "./whois";

const WhoisPage = () => {
    return (
        <MainWrapper title="Whois">
            <WhoisDataView />
        </MainWrapper>
    );
};

export default WhoisPage;
