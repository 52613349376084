import TextField from "@mui/material/TextField";
import React, { Dispatch, useState, useEffect } from "react";

type TimeTypeProps = {
    timeSelected: string;
    onTimeType: Dispatch<React.SetStateAction<string>>;
    error: boolean;
};

const TimeType = (props: TimeTypeProps) => {
    const { onTimeType } = props;
    const [timeNumbers, setTimeNumbers] = useState<string[]>([]);

    const handleValueChange = (keyPressed: any) => {
        if (keyPressed === "Backspace" && timeNumbers.length > 0) {
            setTimeNumbers((prevNumbers) => {
                const updatedNumbers = [...prevNumbers];
                updatedNumbers.pop();
                return updatedNumbers;
            });
        }

        if (/[0-9]/.test(keyPressed) && timeNumbers.length < 6) {
            const newTimeNumbers = [...timeNumbers, keyPressed].join("");
            const pairs = newTimeNumbers.match(/.{1,2}/g) || [];

            const isValidTime = pairs.every((pair, index) => {
                const num =
                    pair.length === 1 ? parseInt(pair) * 10 : parseInt(pair);
                return num < (index === 0 ? 24 : 60);
            });

            if (isValidTime) {
                setTimeNumbers((prevNumbers) => [...prevNumbers, keyPressed]);
            }
        }
    };

    useEffect(() => {
        let formattedTime = "";

        for (let i = 0; i < timeNumbers.length; i++) {
            if (formattedTime.length === 2 || formattedTime.length === 5) {
                formattedTime = formattedTime + ":" + timeNumbers[i];
            } else {
                formattedTime = formattedTime + timeNumbers[i];
            }
        }

        onTimeType(formattedTime);
    }, [timeNumbers]);

    return (
        <TextField
            value={props.timeSelected}
            placeholder="HH:MM:SS"
            error={props.error}
            sx={{
                "&::placeholder": {
                    textTransform: "uppercase",
                },
            }}
            onKeyDown={(event) => {
                handleValueChange(event.key);
            }}
        />
    );
};

export default TimeType;
