import {
    Box,
    // Box,
    // Box,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from "@mui/material";

// third-party
import {
    useTable,
    useFilters,
    usePagination,
    Column,
    useSortBy,
} from "react-table";

import {
    EmptyTable,
    HeaderSort,
    TablePagination,
} from "../../../components/third-party/ReactTable";
import { Dispatch, SetStateAction, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";

function ReactTable({
    rowCount,
    pageSize,
    setPageSizeProp,
    pageIndex,
    setPageIndexProp,
    setSortByProp,
    loading,
    columns,
    data,
    top,
}: {
    columns: Column[];
    data: any[];
    top?: boolean;
    rowCount: number;
    loading: boolean;
    setPageSizeProp: Dispatch<SetStateAction<number>>;
    setPageIndexProp: Dispatch<SetStateAction<number>>;
    setSortByProp: Dispatch<SetStateAction<any>>;
    pageIndex: number;
    pageSize: number;
}) {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        // @ts-expect-error error expected
        page,
        prepareRow,
        // @ts-expect-error error expected
        state: { sortBy },
    } = useTable(
        {
            columns,
            data,
            // @ts-expect-error error expected
            manualPagination: true,
            manualSortBy: true,
            disableSortRemove: true,
        },
        useFilters,
        useSortBy,
        usePagination,
    );
    // console.log(headerGroups);
    // console.log(getTableProps, getTableBodyProps, headerGroups);

    useEffect(() => {
        if (sortBy?.length) {
            setSortByProp(sortBy[0]);
        }
    }, [sortBy]);

    useEffect(() => {
        if (data && data.length <= 0 && pageIndex !== 1) {
            setPageIndexProp(0);
        }
    }, [data]);

    return (
        <Stack>
            <Table {...getTableProps()}>
                <TableHead sx={{ borderTopWidth: top ? 2 : 1 }}>
                    {headerGroups.map((headerGroup: any) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableRow {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                // eslint-disable-next-line react/jsx-key
                                <TableCell
                                    {...column.getHeaderProps([
                                        { className: column.className },
                                    ])}
                                    sx={{
                                        height: "70px",
                                        whiteSpace: "nowrap",
                                        textTransform: "none",
                                        color: "#3A3541CC",
                                        fontWeight: 600,
                                        width: column?.width ?? "auto",
                                        textAlign: column?.align ?? "center",
                                    }}
                                >
                                    <HeaderSort column={column} sort />
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody {...getTableBodyProps()}>
                    {!loading ? (
                        page && page.length > 0 ? (
                            page.map((row: any /*, i: number */) => {
                                prepareRow(row);
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <TableRow {...row.getRowProps()}>
                                        {row.cells.map((cell: any) => (
                                            // eslint-disable-next-line react/jsx-key
                                            <TableCell
                                                {...cell.getCellProps([
                                                    {
                                                        className:
                                                            cell.column
                                                                .className,
                                                    },
                                                ])}
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    fontSize: "13px",
                                                }}
                                            >
                                                {cell.render("Cell")}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                );
                            })
                        ) : (
                            <EmptyTable
                                msg={"No Data"}
                                colSpan={columns.length}
                            />
                        )
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length}>
                                <Box
                                    sx={{
                                        textAlign: "center",
                                        height: "200px",
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    <LoadingOutlined
                                        style={{ fontSize: "20px" }}
                                    />
                                </Box>
                            </TableCell>
                        </TableRow>
                    )}

                    {!top && (
                        <TableRow sx={{}}>
                            <TableCell
                                sx={{
                                    "&.MuiTableCell-root:first-of-type": {
                                        px: 0,
                                        mx: 0,
                                    },
                                    "&.MuiTableCell-root:last-of-type": {
                                        px: 0,
                                        mx: 0,
                                    },
                                    pt: 4,
                                }}
                                colSpan={12}
                            >
                                <TablePagination
                                    rows={rowCount}
                                    setPageSize={setPageSizeProp}
                                    pageSize={pageSize}
                                    setPageIndex={setPageIndexProp}
                                    pageIndex={pageIndex}
                                />
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </Stack>
    );
}

export default ReactTable;
