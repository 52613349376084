import Big from "big.js";
import MainWrapper from "../../components/wrappers/MainWrapper";
import UserTransactionsTable from "../tables/UserTransactionsTable";
import { Button, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

const UserTransactionsPage = () => {
    const [userBalance, setUserBalance] = useState(0);
    const [username, setUsername] = useState("");

    const Title = () => (
        <Stack direction="row">
            <Typography
                sx={{
                    fontSize: { sm: "22px", xs: "16px" },
                    fontWeight: 600,
                    color: "#3A3541CC",
                    paddingBottom: { sm: 3, xs: 2 },
                }}
            >
                Transactions of {username} :
            </Typography>
            <Typography
                sx={{
                    fontSize: { sm: "22px", xs: "16px" },
                    fontWeight: 500,
                    color: "#1890FF",
                    paddingBottom: { sm: 3, xs: 2 },
                    ml: 1,
                }}
            >
                {` ${new Big(userBalance).toFixed(2)} USD`}
            </Typography>
        </Stack>
    );

    return (
        <MainWrapper element={<Title />}>
            <Stack sx={{ width: { xs: "100%", sm: "auto" } }}>
                <Link
                    to={`/addBalance/${username}`}
                    style={{
                        display: "flex",
                        textDecoration: "none",
                        width: "100%",
                        justifyContent: "flex-end",
                    }}
                >
                    <Button
                        variant="contained"
                        endIcon={<AddIcon />}
                        sx={{
                            ml: { sm: 1, xs: 0 },
                            height: "40px",
                            whiteSpace: "nowrap",
                            mb: "19px",
                            alignSelf: "flex-end",
                            width: { xs: "100%", sm: "auto" },
                        }}
                    >
                        Add Transaction
                    </Button>
                </Link>
            </Stack>
            <UserTransactionsTable
                setUsername={setUsername}
                setUserBalance={setUserBalance}
            />
        </MainWrapper>
    );
};

export default UserTransactionsPage;
