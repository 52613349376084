import React from "react";
import { Box, Grid, Paper, Typography } from "@mui/material";
import {
    calculateDateDiff,
    formatWhoisDateString,
    getCurrentTime,
} from "../../utils/helpers";

export type Response = {
    createdAt: string | null;
    expiredAt: string | null;
    whoisInfo: object;
};

const DomainInfo = (response: Response) => {
    return (
        <Grid container>
            <Grid item container xs={12} display={"flex"}>
                <Grid item xs={12} sm={6} display={"flex"} mb={2}>
                    <Typography width={"150px"}>Created at:</Typography>
                    <Typography>
                        {formatWhoisDateString(response.createdAt)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} display={"flex"} mb={2}>
                    <Typography width={"150px"}>Age:</Typography>
                    <Typography>
                        {calculateDateDiff(
                            getCurrentTime(),
                            formatWhoisDateString(response.createdAt),
                        )}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item container xs={12} display={"flex"}>
                <Grid item xs={12} sm={6} display={"flex"} mb={2}>
                    <Typography width={"150px"}>Expired at:</Typography>
                    <Typography>
                        {formatWhoisDateString(response.expiredAt)}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={6} display={"flex"} mb={2}>
                    <Typography width={"150px"}>Remain:</Typography>
                    <Typography>
                        {calculateDateDiff(
                            formatWhoisDateString(response.expiredAt),
                            getCurrentTime(),
                        )}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs={12} mb={2}>
                <Box width={"150px"}>
                    <Typography>Whois information:</Typography>
                </Box>
                <Box
                    sx={{
                        mt: 3,
                        mb: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        flexGrow: 1,
                    }}
                >
                    <Paper
                        elevation={0}
                        square={true}
                        sx={{
                            padding: { xs: 2, sm: 5 },
                            backgroundColor: "#F9F9F9",
                        }}
                    >
                        <Typography
                            variant="body1"
                            component="pre"
                            sx={{
                                maxWidth: "100%",
                                overflowX: "auto",
                                width: 950,
                            }}
                        >
                            {Object.entries(response.whoisInfo).map(
                                ([key, value]) => {
                                    if (
                                        (key === "Domain name" &&
                                            value === null) ||
                                        (key === "Domain Name" &&
                                            value === null)
                                    ) {
                                        return null;
                                    }

                                    if (
                                        key === "Expiry date" ||
                                        key === "Expires On"
                                    ) {
                                        return null;
                                    }

                                    if (
                                        (key === "Registered on" &&
                                            value === null) ||
                                        (key === "Creation Date" &&
                                            value === null)
                                    ) {
                                        return null;
                                    }

                                    if (
                                        (key === "Registrar URL" &&
                                            value === null) ||
                                        (key === "URL" && value === null)
                                    ) {
                                        return null;
                                    }

                                    return (
                                        <Box mb={1} display={"flex"} key={key}>
                                            <Typography
                                                variant="body2"
                                                minWidth={{
                                                    xs: "190px",
                                                    sm: "200px",
                                                }}
                                            >
                                                {key} :
                                            </Typography>
                                            <Typography variant="body2">
                                                {value}
                                            </Typography>
                                        </Box>
                                    );
                                },
                            )}
                        </Typography>
                    </Paper>
                </Box>
            </Grid>
        </Grid>
    );
};
export default DomainInfo;
