import { useRoutes } from "react-router-dom";
import LoginPage from "../pages/auth/LoginPage";
import mainRoutes from "./MainRoutes";
import ForgotPassword from "../pages/auth/ForgotPassword";
import ChangePassword from "../pages/auth/ChangePassword";
// import LoginRedirection from "./LoginRedirection";

export default function Routes() {
    return useRoutes([
        {
            path: "/login",
            element: <LoginPage />,
        },
        {
            path: "/forgotPassword",
            element: <ForgotPassword />,
        },
        {
            path: "/changePassword",
            element: <ChangePassword />,
        },
        mainRoutes,
    ]);
}
