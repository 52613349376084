//

const sortArray = (
    array: any[],
    key: string,
    ascending: boolean,
    isNumber: boolean,
) => {
    return array.sort((a, b) => {
        let comparison = 0;

        const aValue = getValue(a, key);
        const bValue = getValue(b, key);

        if (isNumber) {
            const numA = parseFloat(aValue);
            const numB = parseFloat(bValue);
            if (numA < numB) {
                comparison = -1;
            } else if (numA > numB) {
                comparison = 1;
            }
        } else {
            if (aValue < bValue) {
                comparison = -1;
            } else if (aValue > bValue) {
                comparison = 1;
            }
        }

        if (!ascending) {
            comparison *= -1;
        }

        return comparison;
    });
};

function getValue(obj: any, key: string) {
    const keys = key.split(/\.|\[|\]/).filter(Boolean);
    let value = obj;

    for (const k of keys) {
        if (value) {
            value = value[k];
        } else {
            return undefined;
        }
    }
    return value;
}

export default sortArray;
