import React from "react";
import { useParams } from "react-router-dom";
import MainWrapper from "../../components/wrappers/MainWrapper";
import AddTransactionsForm from "../../forms/AddTransactionForm";

const AddBalancePage = () => {
    const { username } = useParams();
    return (
        <MainWrapper title="Add Transaction">
            <AddTransactionsForm username={username} />
        </MainWrapper>
    );
};

export default AddBalancePage;
