import jwtDecode from "jwt-decode";
import React, { createContext, useEffect, useReducer } from "react";
import { useNavigate } from "react-router-dom";
import authReducer, { authStates } from "../reducers/authReducer";
import { AuthContextType, AuthState } from "../types/authTypes";
import api from "../configs/apiConfig";
import {
    setJwtSession,
    setRefreshToken,
    verifyToken,
} from "../utils/jwtFunctions";
import useMessage from "../hooks/useMessage";
import { defaultRoute } from "./CurrentRouteContext";
import { UserRole } from "../enums/Auth";

const initialState: AuthState = {
    user: null,
    isLogged: false,
};

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
    const navigate = useNavigate();
    const alert = useMessage();
    const [state, dispatch] = useReducer(authReducer, initialState);
    // initial function for load the token and the user session while visiting the website
    const initialRun = () => {
        try {
            const authToken = sessionStorage.getItem("authToken");
            if (authToken && verifyToken(authToken)) {
                const decoded: any = jwtDecode(authToken);
                if (decoded) {
                    if (decoded.role !== UserRole.ADMIN)
                        throw new Error("notAdmin");
                    if (decoded.isDisabled) throw new Error("disabled");
                    setJwtSession("SET", authToken);
                    dispatch({
                        type: authStates.LOGIN,
                        payload: {
                            user: {
                                email: decoded.email,
                                username: decoded.username,
                                isDisabled: decoded.isDisabled,
                                role: decoded.role,
                                isTrusted: decoded.isTrusted,
                                isCompleted: decoded.isCompleted,
                            },
                            isLogged: true,
                        },
                    });
                    const path =
                        localStorage.getItem("currentRoute") || defaultRoute;
                    navigate(path);
                }
            } else {
                setJwtSession("REM");
            }
        } catch (error: any) {
            logout();
            switch (error.message) {
                case "disabled":
                    alert.showError(
                        "Your account has been disabled. Please contact " +
                            process.env.REACT_APP_DEFAULT_USER_EMAIL,
                    );
                    break;
                case "notAdmin":
                    alert.showError("You are not authorized");
                    break;
                default:
                    console.log(error.message);
                    break;
            }
        }
    };

    useEffect(() => {
        initialRun();
    }, []);

    async function login(uname: string, password: string) {
        const response = await api.post("/login", {
            username: uname,
            password,
        });
        const { username, email, accessToken, refreshToken } = response.data;
        if (accessToken && verifyToken(accessToken)) {
            const decoded: any = jwtDecode(accessToken);
            // update the users current data with receiving data
            if (decoded) {
                if (decoded.role !== UserRole.ADMIN)
                    throw new Error("notAdmin");
                if (decoded.isDisabled) throw new Error("disabled");
                setJwtSession("SET", accessToken);
                setRefreshToken("SET", refreshToken);
                dispatch({
                    type: authStates.LOGIN,
                    payload: {
                        user: {
                            username,
                            email,
                            isDisabled: decoded.isDisabled,
                            role: decoded.role,
                            isTrusted: decoded.isTrusted,
                            isCompleted: decoded.isCompleted,
                        },
                        isLogged: true,
                    },
                });
                return response;
            }
            throw new Error("Login error");
        }
        throw new Error("Login error");
    }

    async function logout() {
        try {
            await api.post("/logout", {
                username: state.user.username,
                refreshToken: sessionStorage.getItem("refreshToken"),
            });
        } catch (error) {
            console.log("Error while logging out");
        }
        setJwtSession("REM");
        setRefreshToken("REM");
        dispatch({
            type: authStates.LOGOUT,
        });
        navigate("/login");
    }

    return (
        <AuthContext.Provider
            value={{
                user: state.user,
                login,
                logout,
                isLogged: state.isLogged,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
export default AuthContext;
