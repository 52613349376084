import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";

// third-party
import { useTable } from "react-table";

// ==============================|| REACT TABLE BASIC ||============================== //

function BasicTable({ columns, data, striped, height = null }: any) {
    const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
        useTable({
            columns,
            data,
        });

    return (
        <TableContainer sx={{ maxHeight: 350 }}>
            <Table
                stickyHeader
                {...getTableProps()}
                sx={{ height: height ? height : "auto" }}
            >
                <TableHead>
                    {headerGroups.map((headerGroup) => (
                        // eslint-disable-next-line react/jsx-key
                        <TableRow
                            {...headerGroup.getHeaderGroupProps()}
                            sx={{
                                position: "sticky !important",
                            }}
                        >
                            {headerGroup.headers.map((column: any) => (
                                // eslint-disable-next-line react/jsx-key
                                <TableCell
                                    {...column.getHeaderProps([
                                        { className: column.className },
                                    ])}
                                    sx={{
                                        height: "70px",
                                        whiteSpace: "nowrap",
                                        textTransform: "none",
                                        color: "#3A3541CC",
                                        fontWeight: 600,
                                        width: column?.width ?? "auto",
                                        textAlign: column?.align ?? "center",
                                        position: "sticky !important",
                                    }}
                                >
                                    {column.render("Header")}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableHead>
                <TableBody
                    {...getTableBodyProps()}
                    {...(striped && { className: "striped" })}
                >
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <TableRow {...row.getRowProps()} key={i}>
                                {row.cells.map((cell: any) => (
                                    // eslint-disable-next-line react/jsx-key
                                    <TableCell
                                        {...cell.getCellProps([
                                            {
                                                className:
                                                    cell.column.className,
                                            },
                                        ])}
                                    >
                                        {cell.render("Cell")}
                                    </TableCell>
                                ))}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default BasicTable;
