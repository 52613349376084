export type emailTemplateType = {
    templateName: string;
    message: string;
};

export const emailTemplates: emailTemplateType[] = [
    {
        templateName:
            "AutoBackorder: Successfully caught domain notification - Namerider",
        message:
            "You had some backorders with us for today, and we caught this domain for you: \n" +
            "\n" +
            "DOMAINNAME \n" +
            "Registrar: NameRider \n" +
            "\n" +
            "Price:  \n" +
            "You can manage the domain on your NameRider account (login with your AutoBackorder credentials). \n" +
            "\n" +
            "Kind Regards, \n" +
            "AutoBackorder",
    },
];
