import {
    Box,
    Button,
    IconButton,
    Modal,
    Stack,
    Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { IoCloseSharp } from "react-icons/io5";
import InfoChip from "../common/InfoChip";

const AuctionActionResponse = ({
    open,
    setOpen,
    data,
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    data: string[];
}) => {
    function handleClose() {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Box
                sx={{
                    bgcolor: "white",
                    width: { xs: "90%", sm: "30%", md: "25%" },
                    px: 2,
                    py: 1,
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography fontWeight={700}>Failed Operations</Typography>
                    <IconButton color="secondary" onClick={handleClose}>
                        <IoCloseSharp />
                    </IconButton>
                </Stack>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box
                        sx={{
                            overflowY: "auto",
                            maxHeight: "200px",
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            my: 1,
                        }}
                    >
                        {data && data.length > 0 ? (
                            data.map((domain, index) => (
                                <InfoChip hasLink={false} key={index}>
                                    {domain}
                                </InfoChip>
                            ))
                        ) : (
                            <></>
                        )}
                    </Box>
                </Box>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                >
                    <Button
                        variant="contained"
                        onClick={handleClose}
                        sx={{ my: 1 }}
                    >
                        Ok
                    </Button>
                </Stack>
            </Box>
        </Modal>
    );
};

export default AuctionActionResponse;
