import React, { createContext } from "react";
import useAuth from "../hooks/useAuth";
import { DataContextType } from "../types/contextTypes";
import api from "../configs/apiConfig";
import useMessage from "../hooks/useMessage";
import { getRefreshToken } from "../utils/jwtFunctions";
import { RequestEnum } from "../enums/Requests";

const DataContext = createContext<DataContextType>({} as DataContextType);

export const DataProvider = ({ children }: { children: React.ReactNode }) => {
    const auth = useAuth();
    const { showError } = useMessage();
    const handleLogout = (msg?: null | string) => {
        auth.logout();
        if (msg) showError(msg);
    };

    const handleRefreshSession = async (
        method: RequestEnum,
        url: string,
        error: any,
        data?: any,
    ) => {
        if (auth.user?.username) {
            const refreshAuth = await getRefreshToken(auth.user?.username);
            if (refreshAuth) {
                try {
                    let response = null;
                    switch (method) {
                        case RequestEnum.GET:
                            response = await api.get(url);
                            break;
                        case RequestEnum.POST:
                            response = await api.post(url, data);
                            break;
                        case RequestEnum.PATCH:
                            response = await api.patch(url, data);
                            break;
                        case RequestEnum.DELETE:
                            response = await api.delete(url);
                            break;
                    }
                    return response.data;
                } catch (e: any) {
                    if (e?.response?.status == 401) {
                        handleLogout(e?.response?.data.message);
                    } else {
                        throw e;
                    }
                }
            } else {
                handleLogout(error?.response?.data.message);
            }
        } else {
            handleLogout(error?.response?.data.message);
        }
    };

    const fetchData = async (url: string) => {
        try {
            const response = await api.get(url);
            return response.data;
        } catch (error: any) {
            if (error?.response?.status == 401) {
                showError(error?.response?.data.message);
                return await handleRefreshSession(RequestEnum.GET, url, error);
            } else {
                throw error;
            }
        }
    };

    const postData = async (url: string, data: any) => {
        try {
            const response = await api.post(url, data);
            return response.data;
        } catch (error: any) {
            if (error?.response?.status == 401) {
                showError(error?.response?.data.message);
                return await handleRefreshSession(
                    RequestEnum.POST,
                    url,
                    error,
                    data,
                );
            } else {
                throw error;
            }
        }
    };

    const patchData = async (url: string, data: any) => {
        try {
            const response = await api.patch(url, data);
            return response.data;
        } catch (error: any) {
            if (error?.response?.status == 401) {
                showError(error?.response?.data.message);
                return await handleRefreshSession(
                    RequestEnum.PATCH,
                    url,
                    error,
                    data,
                );
            } else {
                throw error;
            }
        }
    };

    const deleteData = async (url: string) => {
        try {
            const response = await api.delete(url);
            return response.data;
        } catch (error: any) {
            if (error?.response?.status == 401) {
                showError(error?.response?.data.message);
                return await handleRefreshSession(
                    RequestEnum.DELETE,
                    url,
                    error,
                );
            } else {
                throw error;
            }
        }
    };

    return (
        <DataContext.Provider
            value={{ fetchData, postData, patchData, deleteData }}
        >
            {children}
        </DataContext.Provider>
    );
};

export default DataContext;
