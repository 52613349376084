import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery, Box } from "@mui/material";

import Drawer from "./Drawer";
import Header from "./Header";
import useConfig from "../../hooks/useConfig";
import { RootStateProps } from "../../types/root";
import { openDrawer } from "../../store/reducers/menu";

const MainLayout = () => {
    const theme = useTheme();
    const matchDownLG = useMediaQuery(theme.breakpoints.down("xl"));

    const { container, miniDrawer } = useConfig();
    const dispatch = useDispatch();

    const menu = useSelector((state: RootStateProps) => state.menu);
    const { drawerOpen } = menu;

    // drawer toggle
    const [open, setOpen] = useState(!miniDrawer || drawerOpen);
    const handleDrawerToggle = () => {
        setOpen(!open);
        dispatch(openDrawer({ drawerOpen: !open }));
    };

    // set media wise responsive drawer
    useEffect(() => {
        if (!miniDrawer) {
            setOpen(!matchDownLG);
            dispatch(openDrawer({ drawerOpen: !matchDownLG }));
        }
    }, [matchDownLG]);

    return (
        <Box sx={{ display: "flex", width: "100%" }}>
            <Header open={open} handleDrawerToggle={handleDrawerToggle} />
            <Drawer open={open} handleDrawerToggle={handleDrawerToggle} />
            <Box
                component="main"
                sx={{
                    width: !open ? "100%" : "calc(100% - 260px)",
                    flexGrow: 1,
                    padding: "24px",
                    marginTop: "60px", // To Balance the Height of the Header
                }}
            >
                {container && (
                    <Box
                        sx={{
                            position: "relative",
                            minHeight: "calc(100vh - 110px)",
                            display: "flex",
                            flexDirection: "column",
                            margin: 0,
                            padding: 0,
                        }}
                    >
                        <Outlet />
                    </Box>
                )}
                {!container && (
                    <Box
                        sx={{
                            position: "relative",
                            minHeight: "calc(100vh - 110px)",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Outlet />
                    </Box>
                )}
            </Box>
        </Box>
    );
};

export default MainLayout;
