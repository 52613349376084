import { Chip } from "@mui/material";
import React from "react";
import { ChipStatus, ChipBgColors, ChipTextColors } from "../../enums/Status";

const chipStyles = {
    px: 0.1,
    py: 0,
    borderRadius: "8px",
};

const StatusChip = ({ status }: { status: ChipStatus }) => {
    switch (status) {
        case ChipStatus.DANGER:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: ChipBgColors.DANGER,
                        color: ChipTextColors.DANGER,
                    }}
                    label="Failure"
                />
            );
        case ChipStatus.SUCCESS:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: ChipBgColors.SUCCESS,
                        color: ChipTextColors.SUCCESS,
                    }}
                    label="Success"
                />
            );
        case ChipStatus.WARN:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: ChipBgColors.WARN,
                        color: ChipTextColors.WARN,
                    }}
                    label="Warning"
                />
            );
        default:
            return (
                <Chip
                    variant="light"
                    sx={{
                        ...chipStyles,
                        bgcolor: ChipBgColors.INFO,
                        color: ChipTextColors.INFO,
                    }}
                    label="Info"
                />
            );
    }
};

export default StatusChip;
