import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import {
    Button,
    Dialog,
    DialogActions,
    FormControl,
    InputLabel,
    Stack,
    Typography,
} from "@mui/material";
import { BsXLg } from "react-icons/bs";
import BlacklistPopupTable from "./BlacklistPopupTable";
import MultipleSelect from "../filters/MulipleSelect";

interface ResponsesType {
    domain: string;
    success: boolean;
    message: string;
}

enum BlacklistResponseStatus {
    SUCCESS = "Success",
    FAILURE = "Failure",
}

interface TableDataType {
    domain: string;
    status: BlacklistResponseStatus;
    message: string;
}

const BlacklistResponsePopup = ({
    open,
    setOpen,
    response,
}: {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
    response: ResponsesType[];
}) => {
    function handleClose() {
        setOpen(false);
    }
    const [status, setStatus] = useState<string[]>([]);
    const [data, setData] = useState<TableDataType[]>([]);

    useEffect(() => {
        const rows: TableDataType[] = response.map((item) => ({
            ...item,
            status: item.success
                ? BlacklistResponseStatus.SUCCESS
                : BlacklistResponseStatus.FAILURE,
        }));
        if (
            status.length === 1 &&
            status.includes(BlacklistResponseStatus.SUCCESS)
        ) {
            setData(
                rows.filter(
                    (item) => item.status === BlacklistResponseStatus.SUCCESS,
                ),
            );
        } else if (
            status.length === 1 &&
            status.includes(BlacklistResponseStatus.FAILURE)
        ) {
            setData(
                rows.filter(
                    (item) => item.status === BlacklistResponseStatus.FAILURE,
                ),
            );
        } else setData(rows);
    }, [response, status]);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                "&& .MuiDialog-paperScrollPaper": {
                    borderRadius: 0,
                    minWidth: { md: "650px" },
                },
            }}
        >
            <Stack
                direction="row"
                sx={{
                    width: "100%",
                    justifyContent: "flex-end",
                    pr: 2,
                    pt: 2,
                }}
            >
                <BsXLg onClick={handleClose} style={{ cursor: "pointer" }} />
            </Stack>
            <Stack sx={{ px: 3 }}>
                <Typography
                    sx={{
                        color: "#3A3541CC",
                        fontSize: "18px",
                        fontWeight: 600,
                    }}
                >
                    Domain Blacklist Info
                </Typography>
                <Stack
                    direction="row"
                    sx={{ justifyContent: "flex-end", width: "100%", my: 1 }}
                >
                    <FormControl
                        sx={{
                            width: { xs: "95%", sm: "200px" },
                            my: { xs: 1, sm: 1 },
                        }}
                    >
                        <InputLabel
                            id="demo-simple-select-label"
                            sx={{ color: "#bbb" }}
                        >
                            Status
                        </InputLabel>
                        <MultipleSelect
                            levels={[
                                BlacklistResponseStatus.SUCCESS,
                                BlacklistResponseStatus.FAILURE,
                            ]}
                            onSelect={setStatus}
                            fieldValue={status}
                        />
                    </FormControl>
                </Stack>
                <BlacklistPopupTable data={data} />
                <DialogActions sx={{ my: 1, mr: 0, pr: 0 }}>
                    <Button
                        variant="contained"
                        autoFocus
                        sx={{ bgcolor: "#1890FF", height: "30px" }}
                        onClick={handleClose}
                    >
                        Done
                    </Button>
                </DialogActions>
            </Stack>
        </Dialog>
    );
};

export default BlacklistResponsePopup;
