import React from "react";
import { useParams } from "react-router-dom";
import MainWrapper from "../../components/wrappers/MainWrapper";
import CreateUserForm from "../../forms/CreateUserForm";
import BalancePreview from "../../components/users/BalancePreview";

const ModifyUserPage = () => {
    const { username } = useParams();
    return (
        <MainWrapper
            title="Modify User"
            element={<BalancePreview username={username} />}
        >
            <CreateUserForm modify username={username} />
        </MainWrapper>
    );
};

export default ModifyUserPage;
