import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import TelegramIcon from "@mui/icons-material/Telegram";
import LockIcon from "@mui/icons-material/Lock";
import PauseIcon from "@mui/icons-material/Pause";
import { LockOpen, PlayArrow } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { AuctionType } from "../../enums/Status";
import { isLockedCheck } from "../../utils/helpers";

type PerAuctionPopUpMenuProps = {
    row: any;
    handleBidAction: (
        data: any,
        action: string,
        nextState: boolean,
    ) => Promise<void>;
    handleBidDeleteClick: () => void;
};

const PerAuctionPopUpMenu = (props: PerAuctionPopUpMenuProps) => {
    const navigate = useNavigate();
    const { handleBidAction, handleBidDeleteClick } = props;
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleBidActionClick = async (action: string, nextState: boolean) => {
        await handleBidAction(props.row, action, nextState);
        setAnchorEl(null);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            sx={{
                mt: "0px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <IconButton
                aria-controls="popup-menu"
                aria-haspopup="true"
                onClick={handleClick}
                color="inherit"
            >
                <MoreVertIcon fontSize="small" />
            </IconButton>
            <Menu
                id="popup-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                sx={{
                    "& .MuiList-root": {
                        py: 0.2,
                    },
                }}
            >
                {
                    <MenuItem
                        onClick={() =>
                            handleBidActionClick(
                                "isPaused",
                                !props.row.isPaused,
                            )
                        }
                        sx={{ borderBottom: "1.5px solid #ccc" }}
                        disabled={
                            props.row.auctionType == AuctionType.POST ||
                            isLockedCheck(props.row.dropTime)
                        }
                    >
                        {" "}
                        {props.row.isPaused ? (
                            <>
                                <PlayArrow
                                    fontSize="small"
                                    sx={{
                                        paddingRight: "2px",
                                        marginRight: "8px",
                                        color: "#1890FF",
                                    }}
                                />
                                <span>Resume</span>
                            </>
                        ) : (
                            <>
                                <PauseIcon
                                    fontSize="small"
                                    sx={{
                                        paddingRight: "2px",
                                        marginRight: "8px",
                                        color: "#1890FF",
                                    }}
                                />
                                <span>Pause</span>
                            </>
                        )}
                    </MenuItem>
                }
                {
                    <MenuItem
                        onClick={() =>
                            handleBidActionClick(
                                "isLocked",
                                !props.row.isLocked,
                            )
                        }
                        sx={{ borderBottom: "1.5px solid #ccc" }}
                        disabled={
                            props.row.auctionType == AuctionType.POST ||
                            isLockedCheck(props.row.dropTime)
                        }
                    >
                        {" "}
                        {props.row.isLocked ? (
                            <>
                                <LockOpen
                                    fontSize="small"
                                    sx={{
                                        paddingRight: "2px",
                                        marginRight: "8px",
                                        color: "#D8DEE8",
                                    }}
                                />
                                <span>Unlock</span>
                            </>
                        ) : (
                            <>
                                <LockIcon
                                    fontSize="small"
                                    sx={{
                                        paddingRight: "2px",
                                        marginRight: "8px",
                                        color: "#D8DEE8",
                                    }}
                                />
                                <span>Lock</span>
                            </>
                        )}
                    </MenuItem>
                }
                <MenuItem
                    onClick={() => {
                        handleBidDeleteClick();
                        setAnchorEl(null);
                    }}
                    sx={{ borderBottom: "1.5px solid #ccc" }}
                    disabled={
                        props.row.auctionType == AuctionType.POST ||
                        isLockedCheck(props.row.dropTime)
                    }
                >
                    {" "}
                    <DeleteIcon
                        fontSize="small"
                        sx={{
                            paddingRight: "2px",
                            marginRight: "8px",
                            color: "#FF4D4F",
                        }}
                    />
                    Delete
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        navigate(
                            `/admin/messages/create?username=${props.row.username}`,
                        )
                    }
                    sx={{ borderBottom: "1.5px solid #ccc" }}
                >
                    {" "}
                    <TelegramIcon
                        fontSize="small"
                        sx={{
                            paddingRight: "2px",
                            marginRight: "8px",
                            color: "#1890FF",
                        }}
                    />
                    Send Message
                </MenuItem>
            </Menu>
        </Box>
    );
};

export default PerAuctionPopUpMenu;
