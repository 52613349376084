import MainWrapper from "../../components/wrappers/MainWrapper";
import AddDomainToBlacklist from "../../forms/AddDomainToBlacklist";

const AddBlacklistDomains = () => {
    return (
        <MainWrapper title="Add Domain to Blacklist">
            <AddDomainToBlacklist />
        </MainWrapper>
    );
};

export default AddBlacklistDomains;
