import React from "react";
import MainWrapper from "../../components/wrappers/MainWrapper";
import CreateRegistryForm from "../../forms/CreateRegistryForm";

const CreateRegistry = () => {
    return (
        <MainWrapper title="Create Registry">
            <CreateRegistryForm />
        </MainWrapper>
    );
};

export default CreateRegistry;
