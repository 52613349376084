import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainWrapper from "../../components/wrappers/MainWrapper";
import CreateEmailForm from "../../forms/CreateEmailForm";

const CreateEmailsWithTemplate = () => {
    const location = useLocation();
    const [emailInfo, setEmailInfo] = useState({ domain: "", user: "" });

    useEffect(() => {
        const storedData = localStorage.getItem("createWithEmailState");
        if (storedData) {
            const { domain, user } = JSON.parse(storedData);
            if (domain && user) {
                setEmailInfo({ domain, user });
            }
        }
    }, []);

    useEffect(() => {
        if (location.state) {
            setEmailInfo((prevState) => ({
                ...prevState,
                domain: location.state.domain || prevState.domain,
                user: location.state.user || prevState.user,
            }));
        }
    }, [location.state]);

    useEffect(() => {
        localStorage.setItem("createWithEmailState", JSON.stringify(emailInfo));
    }, [emailInfo]);

    return (
        <MainWrapper title="Create Email">
            <CreateEmailForm domain={emailInfo.domain} user={emailInfo.user} />
        </MainWrapper>
    );
};

export default CreateEmailsWithTemplate;
