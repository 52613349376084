import React from "react";
import { useParams } from "react-router-dom";
import PerBidView from "./PerBidView";

const PerBidViewPage = () => {
    const { bidId } = useParams();
    return <PerBidView bidId={Number(bidId)} />;
};

export default PerBidViewPage;
