import React, { useState } from "react";
import ExpandLessSharpIcon from "@mui/icons-material/ExpandLessSharp";
import ExpandMoreSharpIcon from "@mui/icons-material/ExpandMoreSharp";

type SeeMoreTextProps = {
    text: string;
    chrLimit: number;
};

const SeeMoreText = (props: SeeMoreTextProps) => {
    const [showMore, setShowMore] = useState(false);

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };

    const limitText = (text: string, limit: number) =>
        text.length <= limit
            ? text
            : text.slice(0, text.lastIndexOf(" ", limit) || limit) + " ...";

    const displayedText = showMore
        ? props.text
        : limitText(props.text, props.chrLimit);

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
                textOverflow: "ellipsis",
            }}
        >
            <p
                style={{ whiteSpace: "pre-wrap", display: "inline-block" }}
                onClick={toggleShowMore}
            >
                {displayedText}
            </p>
            {!showMore && props.text.length > props.chrLimit && (
                <ExpandMoreSharpIcon
                    onClick={toggleShowMore}
                    style={{
                        verticalAlign: "middle",
                        color: "1890ff",
                    }}
                ></ExpandMoreSharpIcon>
            )}
            {showMore && props.text.length > props.chrLimit && (
                <ExpandLessSharpIcon
                    onClick={toggleShowMore}
                    style={{
                        verticalAlign: "middle",
                        color: "1890ff",
                    }}
                ></ExpandLessSharpIcon>
            )}
        </div>
    );
};

export default SeeMoreText;
