import React, { useEffect, useMemo, useState } from "react";

import { Box, Button, Grid } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import ScrollX from "../../components/ScrollX";
import ReactTable from "./react-table/ReactTable";
import { emailTableDataType } from "../../types/tableTypes";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import { Link } from "react-router-dom";
import { cellDateAndTime, cellSeeMore } from "./CellFormatters";

const EmailsTable = () => {
    const { postData } = useData();
    const { showError } = useMessage();
    const [data, setData] = useState<emailTableDataType[]>([]);
    const [pageSize, setPageSize] = useState<number>(10);
    const [pageIndex, setPageIndex] = useState<number>(0);
    const [rowCount, setRowCount] = useState<number>(0);
    const [sortBy, setSortBy] = useState<{
        id: string;
        desc: boolean;
    }>({
        id: "sentAt",
        desc: true,
    });

    // Filters & searches
    const [loading, setLoading] = useState<boolean>(false);

    interface RequestType {
        page: number;
        size: number;
        filterAnd: any[];
        sort: {
            key: string;
            order: "ASC" | "DESC";
        };
    }

    const loadEmails = async (isInit: boolean = false) => {
        setLoading(true);
        const reqBody: RequestType = {
            page: isInit ? 1 : pageIndex + 1,
            size: pageSize,
            filterAnd: [],
            sort: {
                key: sortBy.id,
                order: sortBy.desc ? "DESC" : "ASC",
            },
        };

        try {
            const response: any = await postData("/mail/getAllMails", reqBody);
            if (response?.data) {
                setData(response.data);
            } else {
                setData([]);
            }
            setRowCount(response?.count ? response?.count : 0);
        } catch (error) {
            showError("Data Fetching Error");
        }
        setLoading(false);
    };

    useEffect(() => {
        loadEmails();
    }, [pageIndex, pageSize, sortBy]);

    useEffect(() => {
        setPageIndex(0);
        loadEmails();
    }, [rowCount]);

    const columns = useMemo(
        () => [
            {
                Header: "Email",
                accessor: "receiver",
                disableSortBy: true,
            },
            {
                Header: "Subject",
                accessor: "subject",
                disableSortBy: true,
                Cell: cellSeeMore,
            },
            {
                Header: "Message",
                accessor: "message",
                disableSortBy: true,
                Cell: cellSeeMore,
            },
            {
                Header: "Sent at",
                accessor: "sentAt",
                // eslint-disable-next-line @typescript-eslint/naming-convention
                Cell: cellDateAndTime,
            },
        ],
        [],
    );

    return (
        <Grid container>
            <Box
                sx={{
                    display: "flex",
                    my: 1,
                    flexGrow: 1,
                    justifyContent: { xs: "flex-end", sm: "flex-end" },
                    alignItems: "center",
                }}
            >
                <Link to={"/admin/emails/create"}>
                    <Button
                        variant="contained"
                        endIcon={<AddIcon />}
                        sx={{
                            ml: 1,
                            height: "40px",
                            whiteSpace: "nowrap",
                            mb: "19px",
                        }}
                    >
                        New Email
                    </Button>
                </Link>
            </Box>
            <Grid item xs={12}>
                <ScrollX>
                    <ReactTable
                        pageSize={pageSize}
                        pageIndex={pageIndex}
                        columns={columns}
                        data={data}
                        setPageIndexProp={setPageIndex}
                        setPageSizeProp={setPageSize}
                        setSortByProp={setSortBy}
                        loading={loading}
                        rowCount={rowCount}
                    />
                </ScrollX>
            </Grid>
        </Grid>
    );
};

export default EmailsTable;
