import MainWrapper from "../../components/wrappers/MainWrapper";
import DomainInfo from "./DomainInfo";
import AdminSetup from "./AdminSetup";
import { CircularProgress, Grid } from "@mui/material";
import DomainName from "./DomainName";
import useData from "../../hooks/useApi";
import useMessage from "../../hooks/useMessage";
import { useEffect, useState } from "react";

export type BidViewProps = {
    bidId: number;
};
const PerBidView = (bidViewProps: BidViewProps) => {
    const { postData } = useData();
    const { showError } = useMessage();
    const [loading, setLoading] = useState<boolean>(false);
    const [bidInfo, setBidInfo] = useState({
        username: "",
        domainName: "",
        tld: "",
        dropDate: 0,
        status: "",
        paused: false,
        locked: false,
        createdAt: "",
        expiredAt: "",
        whoisInfo: {},
    });
    const getBidData = async () => {
        try {
            const response: any = await postData("/bid/bidView", {
                bidId: bidViewProps.bidId,
            });
            if (response.success) {
                if (response?.result !== undefined) {
                    setBidInfo(response.result);
                    setLoading(true);
                } else {
                    showError("Server Error");
                }
            } else {
                showError("Server Error");
            }
        } catch (e: any) {
            if (e.response?.status === 400) {
                showError("Error while retrieving data");
            } else {
                showError("Internal Server Error");
            }
        }
    };

    useEffect(() => {
        getBidData();
    }, []);

    return (
        <>
            <Grid
                container
                justifyContent="center"
                px={"50%"}
                py={"20%"}
                display={loading ? "none" : "block"}
            >
                <CircularProgress />
            </Grid>
            <Grid
                container
                direction={"column"}
                spacing={2}
                display={!loading ? "none" : "block"}
            >
                <Grid item>
                    <MainWrapper
                        title="Domain Info"
                        topElement={<DomainName name={bidInfo.domainName} />}
                    >
                        <DomainInfo
                            createdAt={bidInfo.createdAt}
                            expiredAt={bidInfo.expiredAt}
                            whoisInfo={bidInfo.whoisInfo}
                        />
                    </MainWrapper>
                </Grid>
                <Grid item>
                    <MainWrapper title="Admin Setup">
                        <AdminSetup
                            username={bidInfo.username}
                            domainName={bidInfo.domainName}
                            tld={bidInfo.tld}
                            dropDate={bidInfo.dropDate}
                            status={bidInfo.status}
                            isPaused={bidInfo.paused}
                            isLocked={bidInfo.locked}
                        />
                    </MainWrapper>
                </Grid>
            </Grid>
        </>
    );
};

export default PerBidView;
